import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip, Grid } from '@mui/material'
import Breadcrumbs from 'components/common/Breadcrumps'
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell'
import GlobalExportButton from 'components/common/GlobalExportButton'
import { MuiThemedBox, MuiThemedButton } from 'components/common/MuiThemedComponents'
import MultiSelectDropDown from 'components/common/MultiSelectDropDown'
import SearchInputField from 'components/common/SearchInputField'
import SelectDropDown from 'components/common/SelectDropdown'
import TableComponent from 'components/common/TableComponent/TableComponent'
import TableHeader from 'components/common/TableHeader/TableHeader'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel'
import Timer from 'components/component/link/Timer'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from "react-router"
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchAllTickets, fetchStatusReferenceData, fetchTicketingSystems, resetTickets, setTicketDetails } from 'store/tickets'
import PriorityComponent from './PriorityComponent'
import TicketStatusComponent from './TicketStatusComponent'
import { defaultTicketStatusValues, servicenowHeaderCardDetails, ticketCardColors, ticketCardConstants, ticketDetails } from './ticketsConstants'
import ButtonLayout from 'components/common/Button'
import TicketDetail from './TicketDetail'
import { trimSpacesFromStartAndEnd } from 'utils/Utilities'
import ServiceNowConfigurePopup from './ServiceNowConfigurePopup'

export default function TicketsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(state => state?.user);
  const tenants = useSelector((state) => state.devices?.tenants);
  const ticketsSelector = useSelector((state) => state?.tickets);
  const { allTickets, totalTicketCount, ticketStatus } = ticketsSelector;
  const [isLoading, setIsLoading] = useState(true);
  const [filterValue, setFilterValue] = useState([{ key: 'type', value: ['external'] }]);
  const [searchInputValue, setSearchInputValue] = useState();
  const [tenantFilter, setTenantFilter] = useState([]);
  const [ticketStatusFilter, setTicketStatusFilter] = useState([]);
  const [ticketingStatusOptionsList, setTicketingStatusOptionsList] = useState([]);
  const [ticketingSystemOptionsList, setTicketingSystemOptionsList] = useState([]);
  const [showDescriptionPage, setShowDescriptionPage] = useState(false);
  const [ticketingSystemFilter, setTicketingSystemFilter] = useState([]);
  const [ticketingTypeFilter, setTicketingTypeFilter] = useState([{ id: 2, value: 'external', label: 'External' }]);
  const [tenantOptionsList, setTenantOptionsList] = useState([]);
  const [highlightFilter, setHighLightFilter] = useState(false);
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [showTicketCardLoader, setShowTicketCardLoader] = useState(false);
  const [showTicketStatusLoader, setShowTicketStatusLoader] = useState(false);
  const [openServiceNowConfigurePopup, setOpenServiceNowConfigurePopup] = useState(false);
  const [tableRefreshKey, setTableRefreshKey] = useState(null);
  const [paginationState, setPaginationState] = useState({
    offset: 1, limit: 20, sortField: 'updated_at', sortOrder: 'desc'
  }) 
  const [defaultTicketFilter,setDefaultTicketFilter] = useState([])
  const selectedTicketingSystem = useRef(null)


  const setPaginationData = (newState) => {
    setPaginationState(prevState => ({ ...prevState, ...newState }))
  }

  const getCurrentTenant = useCallback((tenant_id) => {
    const currentTenant = tenants && tenants?.length > 0 && tenants.find(tenant => tenant?.id == tenant_id)?.display;
    return currentTenant
  }, [tenants])

  const getTicketingSystems = useCallback(async (dashboardTicketingSystem) => {
    try {
      const tenantId = user?.tenant?.name === "Sangoma" ? "sangoma" : user?.tenant?.id;
      const request = { tenantId };
      const response = await dispatch(fetchTicketingSystems(request))?.unwrap();

      if (Array.isArray(response) && response.length) {
        const options = response.map(item => ({
          id: item?.id,
          label: item?.name,
          value: item?.name
        }));

        setTicketingSystemOptionsList(options);
        if(dashboardTicketingSystem) {
          const redirectedTicketingSystem = options?.find(item => item.value?.toLowerCase() === dashboardTicketingSystem);
          setTicketingSystemFilter([redirectedTicketingSystem]);
          selectedTicketingSystem.current =[redirectedTicketingSystem];
          return redirectedTicketingSystem?.value.toLowerCase();
        } else if (options?.length > 0) {
					const servicenowEnabled = options?.find(item => item.value === "Servicenow");
					if (servicenowEnabled && user?.tenant?.name !== "Sangoma") {
            setTicketingSystemFilter([servicenowEnabled]);
            selectedTicketingSystem.current = [servicenowEnabled];
            return servicenowEnabled?.value.toLowerCase();
					} else {
						const salesforceOption = options.find(item => item.value === "Salesforce")
						if (salesforceOption) {
              setTicketingSystemFilter([salesforceOption]);
              selectedTicketingSystem.current = [salesforceOption];
              return salesforceOption?.value.toLowerCase();

						}
					}

				}

      } else {
        setTicketingSystemOptionsList([]);
        toast.error(response);
        return null;
      }
    } catch (error) {
      console.error("Error fetching ticketing systems:", error);
      toast.error("Failed to fetch ticketing systems.");
      return null;
    }
  }, [user?.tenant?.name, user?.tenant?.id, dispatch]);

  const enableTicketingSystemFilter = useMemo(() => 
    ticketingSystemOptionsList?.length > 1  || user?.tenant?.name === "Sangoma",[ticketingSystemOptionsList?.length, user?.tenant?.name]);

  const getTicketStatusOptionsReferenceData = useCallback(async(ticketingSystem) => {
    let options = await getStatusReferenceData({ ticketing_system: ticketingSystem});
    const updatedFilterValue =  await handleStatusValueForFiltersOnRedirection(null, options, ticketingSystem)
    if (ticketingSystem === "servicenow") {
      setTicketingStatusOptionsList(options)
    } else if (ticketingSystem === "salesforce") {
      setTicketingStatusOptionsList(options)
    }
    return updatedFilterValue
  }, [])


  // Search changes
  const handleSearch = useCallback(async (e) => {
    const searchString = trimSpacesFromStartAndEnd(e?.target?.value)
    setSearchInputValue(searchString);
    setTableRefreshKey(new Date().getTime());  
    await getTicketsData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, searchString, null, ticketingSystemFilter?.[0]?.value);
  }, [paginationState?.sortField, paginationState?.sortOrder, ticketingSystemFilter])



  //Column changes
  
  const prepareTicketDetails = useCallback((row, ticketingSystem, isThirdParty = false) => ({
    ticketId: isThirdParty ? row?.third_party_ticket_id : row?.id,
    ticketType: row?.type,
    caseNumber: isThirdParty ? row?.third_party_ticket_number : row?.case_number,
    tenantName: getCurrentTenant(row?.tenant_id),
    tenantId: row?.tenant_id,
    previousScreen: "Tickets",
    previousScreenURL: "tickets",
    ticketingSystem,
    device_id: row?.device_id,
    service: row?.service,
  }),[getCurrentTenant])
  
  const handleTicketClick = useCallback(
    (row, ticketingSystem = null, isThirdParty = false) => {
      const system = isThirdParty
        ? ticketingSystem
        : selectedTicketingSystem?.current?.[0]?.value?.toLowerCase();  
      dispatch(setTicketDetails(prepareTicketDetails(row, system, isThirdParty)));
      setShowDescriptionPage(true);
    }, [dispatch, prepareTicketDetails, selectedTicketingSystem]);
  
  const thirdPartyTicketColumn = useMemo(()=> {
    const ticketingSystem = ticketingSystemFilter?.[0]?.value?.toLowerCase();
    const thirdPartyColumnDetails =ticketingSystem && ticketCardColors[ticketingSystem]

    return  thirdPartyColumnDetails &&  [
      {
        name:thirdPartyColumnDetails?.title,
        title: thirdPartyColumnDetails?.title,
        isEnabled: true,
        minWidth: 150,
        cell: (row) => {
          if(!(row?.third_party_ticket_id && row?.third_party_ticket_number)) {
            return '--'          
          }
          
          return (

            <Box sx={{ padding: "0px 4px", display: "flex", alignItems: "center", cursor: "pointer" }} >
              <Chip
                className={` ${thirdPartyColumnDetails?.title} third__party__ticket__chip`}
                label={row?.third_party_ticket_number}
                sx={{
                  color: thirdPartyColumnDetails?.brandColor,
                  // backgroundColor: thirdPartyColumnDetails?.bgColor,
                  // color: thirdPartyColumnDetails?.textColor,
                  display: "flex", alignItems: "center", justifyContent: "center",
                  '&:hover': {
                    textDecorationColor:thirdPartyColumnDetails?.bgColor,

                  }
                }}
                onClick={(e) => { e?.stopPropagation(); handleTicketClick(row, thirdPartyColumnDetails?.redirectOption, true) }}
              />
              <Box sx={{ width: "20px", height: "20px" }}>
                {!row?.third_party_is_ticket_viewed &&
                  <FontAwesomeIcon style={{ paddingLeft: "8px", marginBottom: "-4px", color: "#FFAA71", fontSize: "18px" }} icon="fa-solid fa-bell" />
                }
              </Box>
            </Box>
          )
        },
      },
    ]
  },[handleTicketClick, ticketingSystemFilter])


  
  

  const ticketColumns = useMemo(() => {
    const enableSiteColumns = ticketingTypeFilter && ticketingTypeFilter?.length > 0 && ticketingTypeFilter?.find(item => item?.value == "internal");
    
    const tenantColumn = [
      {
        name: "Tenant Name",
        title: "Tenant Name",
        dataType: "String",
        isEnabled: user?.tenant?.name === "Sangoma",
        minWidth: '100px',
        selector: (row) => {
          const tenantValue = getCurrentTenant(row?.tenant_id);
          return (
            <TooltipLabel text={tenantValue ? tenantValue : "-"} />
          )
        },
      },
    ]
    const siteAndTenantColumns = [
      {
        name: "Site Display Name",
        title: "Site Display Name",
        dataType: "String",
        isEnabled: enableSiteColumns,
        minWidth: 150,
        wrap: true,
        cell: (row) => {
          return (
            <CopyTextCell text={row?.site_display_name ? row?.site_display_name : row?.site_name ? row?.site_name : "-"} />
          )
        },
      },
      {
        name: "Site Name",
        title: "Site Name",
        dataType: "String",
        isEnabled: enableSiteColumns,
        minWidth: 150,
        wrap: true,
        cell: (row) => {
          return (
            <CopyTextCell text={row?.site_name ? row?.site_name : "-"} />
          )
        },
      },
    ]


    const defaultColumns = [
      {
        name: "Ticket Number",
        title: "Ticket Number",
        dataType: "String",
        isEnabled: true,
        sortable: false,
        minWidth: 100,
        sortField: 'case_number',
        cell: (row) => {
          return (
            <Box className='tickets__id__cell' onClick={(e) => {
              e?.stopPropagation();
              handleTicketClick(row)
            }}>
              <TooltipLabel text={row?.case_number ? row.case_number : "-"} />
              {!row?.is_ticket_viewed &&
                <Box>
                  <FontAwesomeIcon style={{ paddingLeft: "4px", color: "#FFAA71", fontSize: "16px" }} icon="fa-solid fa-bell" />
                </Box>
              }
            </Box>
          )
        }
      },
      ...((thirdPartyTicketColumn && enableTicketingSystemFilter) ? thirdPartyTicketColumn : []),
      {
        name: "Subject",
        title: "Subject",
        dataType: "String",
        isEnabled: true,
        minWidth: 200,
        wrap: true,
        cell: (row) => {
          const formattedSubject = row?.subject?.split("|")?.slice(1)?.join("|") || row?.subject;
          return (
            <TooltipLabel text={formattedSubject ? formattedSubject : "--"} />
          )
        },
      },
      {
        name: "Priority",
        title: "Priority",
        dataType: "String",
        sortable: true,
        isEnabled: true,
        minWidth: 50,
        wrap: true,
        cell: (row) => { return <PriorityComponent priority={row?.priority} ticketingSystem={ticketingSystemFilter?.[0]?.value?.toLowerCase()} /> }
      },
      {
        name: "Status",
        title: "Status",
        dataType: "String",
        sortable: true,
        isEnabled: true,
        minWidth: 75,
        maxWidth: "250px",
        wrap: true,
        cell: (row) => { return <TicketStatusComponent status={row?.status} /> }

      },
      {
        name: "Created At",
        title: "Created At",
        dataType: "String",
        minWidth: 150,
        isEnabled: true,
        sortable: true,
        sortField: 'created_at',
        selector: (row) => {
          const date = moment(new Date(row?.created_at)).format('LLL');
          return (
            <TooltipLabel text={date ? date : "-"} />
          )
        }
      },
      {
        name: "Since (Updated)",
        title: "Since (Updated)",
        dataType: "String",
        isEnabled: true,
        sortable: true,
        sortField: 'updated_at',
        minWidth: 150,
        selector: (row) => {
          const date = new Date(row?.updated_at).getTime();
          return (
            <Timer targetDate={date} />
          )
        }
      },
    ]
    return [
      ...tenantColumn,
      ...(enableSiteColumns ? siteAndTenantColumns : []),
      ...defaultColumns,
    ];
  }, [ticketingTypeFilter, user?.tenant?.name, thirdPartyTicketColumn, getCurrentTenant, handleTicketClick, ticketingSystemFilter])


  // Filter changes

  const updateFilter = useCallback(async (key, val, existingFilter) => {
    if (location.state) {
      navigate(location.pathname, { state: null })
    }
    let filter = []
    let cloneFilter = [...filterValue];
    if (key === "ticketing_system") {
      setShowTicketCardLoader(true)
      filter = val || []
      setTicketingStatusOptionsList([]);
      setTicketStatusFilter([]);
      // For a moment, while changing the ticketing system there is glitch in ui  for header cards new and inprogress loader is getting displayed so emptying the status values from filter too
      cloneFilter = cloneFilter?.filter(i => i.key !== "status");
      setFilterValue(cloneFilter)
    }
    else {
      filter = val ? [] : [...existingFilter];

    }
    const value = filter.some(obj => obj.value?.toString()?.toLowerCase() === ('all')) ? ['all'] : filter?.map(i => i.value?.toString());
    cloneFilter = cloneFilter?.filter(i => i.key !== key);
    if (value?.length > 0) {
      cloneFilter.push({ value: value, key: key });
    }
    setFilterValue(cloneFilter);
    const filterReq = cloneFilter?.length > 0 ? cloneFilter: [];    
    getTicketsData({ offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder }, null, filterReq, key=== "ticketing_system" ? val?.[0]?.value: null,  key=== "ticketing_system");
  }, [location.state, location.pathname, filterValue, paginationState?.sortField, paginationState?.sortOrder, navigate]);

  const onApplyFilter = useCallback((key, val) => {
			setSearchInputValue('')
      setTableRefreshKey(new Date().getTime())
    switch (key) {
      case 'Ticket Status':
        updateFilter('status', val, ticketStatusFilter);
        break;
      case 'Tenants':
        updateFilter('tenant_id', val, tenantFilter,);
        break;
      case 'Ticketing System':
        updateFilter('ticketing_system', val, ticketingSystemFilter);
        break
      case 'Ticket Type':
        updateFilter('type', null, val);
        break;
      default:
        break
    }
  }, [ticketStatusFilter, updateFilter, tenantFilter, ticketingSystemFilter])

  const onChangeOfStatusFilter = useCallback((val, action) => {
    setTicketStatusFilter(val)
    setHighLightFilter(false)

    if (action === 'clear') {
      // onApplyFilter('Ticket Status', val);
      updateFilter('status', null, val);

    }
  },[ onApplyFilter])

  const onChangeOfTenantFilter = useCallback((val, action) => {
    setTenantFilter(val);
    if (action === 'clear') {
      onApplyFilter('Tenants', val);
    }
  },[onApplyFilter])

  const onChangeOfTicketingSystemFilter = (val) => {
    selectedTicketingSystem.current = [val]
    setTicketingSystemFilter([val]);
    onApplyFilter('Ticketing System', [val]);
  }

  // eslint-disable-next-line no-unused-vars
  const onChangeOfTicketTypeFilter = (val) => {
    setTicketingTypeFilter([val]);
    onApplyFilter('Ticket Type', [val]);
  }
  
  const filterDetails = useMemo(() => {
    return [
      {
        id: 1,
        onChangeValue: onChangeOfStatusFilter,
        options: ticketingStatusOptionsList,
        placeholder: "Ticket Status",
        value: ticketStatusFilter,
        onApplyClick: onApplyFilter,
        isEnabled: true,
        highlightFilter: highlightFilter,
        disabledApply: !isLoading && ticketStatusFilter.length > 0 ? false : true,
        defaultFilter: defaultTicketFilter
      },
      {
        id: 2,
        onChangeValue: onChangeOfTenantFilter,
        options: tenantOptionsList,
        placeholder: "Tenants",
        value: tenantFilter,
        onApplyClick: onApplyFilter,
        isEnabled: user?.tenant?.name === "Sangoma",
        disabledApply: !isLoading && tenantFilter.length > 0 ? false : true
      },

    ]
  }, [onChangeOfStatusFilter, ticketingStatusOptionsList, ticketStatusFilter, onApplyFilter, highlightFilter, isLoading, defaultTicketFilter, onChangeOfTenantFilter, tenantOptionsList, tenantFilter, user?.tenant?.name]);

  const cardDetails = useMemo(() => {
    const ticketingSystem =ticketingSystemFilter && ticketingSystemFilter?.length > 0 && ticketingSystemFilter?.[0]?.value?.toLowerCase();
    const ticketStatusValue = filterValue && filterValue?.length > 0 && filterValue?.find(item => item?.key == 'status')?.value;
    const headerCards = ticketingSystem === 'servicenow' ? servicenowHeaderCardDetails(ticketStatus, ticketStatusValue) :
    ticketDetails(ticketStatus, ticketStatusValue, ticketingStatusOptionsList)
    return  headerCards
  },[ticketingSystemFilter, ticketStatus, filterValue, ticketingStatusOptionsList])

  const cardLength = useMemo(() => {
    const filterStatusValue = filterValue?.length > 0 && filterValue?.find(item => item?.key == 'status')?.value || []
    return ticketingSystemFilter?.[0]?.value?.toLowerCase() === 'servicenow' ? 3 : filterStatusValue?.includes("Closed") || filterStatusValue?.length == 0 ? 2 : 2.4
  },[filterValue, ticketingSystemFilter])


  const onHeaderCardClick = useCallback((e, item) => {
    setSearchInputValue('');
    setTableRefreshKey(new Date().getTime())
    e?.stopPropagation();
    const selectedCardId = item?.id;
    if (selectedCardId && selectedCardId !== "recently_open") {
      setHighLightFilter(true)
      const filters = handleStatusValueForFiltersOnRedirection(selectedCardId, ticketingStatusOptionsList, ticketingSystemFilter?.[0]?.value);
      getTicketsData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, '', filters ? filters : [], ticketingSystemFilter?.value);
    }
  }, [ticketingStatusOptionsList, ticketingSystemFilter, paginationState?.sortField, paginationState?.sortOrder])

  const getStatusReferenceData = useCallback(async (request) => {
    try {
      setShowTicketStatusLoader(true);
      const response = await dispatch(fetchStatusReferenceData(request)).unwrap();
      if (response && response?.length > 0) {
        setTicketingStatusOptionsList(response)
        return response
      }
    } catch (error) {
      console.error(error)
    } finally {
      setShowTicketStatusLoader(false)
    }

  },[dispatch])

  const handleEnableServicenow = useCallback(() =>{
    setOpenServiceNowConfigurePopup(!openServiceNowConfigurePopup)
  },[openServiceNowConfigurePopup])

  const handleRefreshBtn = useCallback(async () => {
    setRefreshLoader(true)
    try {
      setSearchInputValue('');
      setTicketStatusFilter([]);
      setTenantFilter([]);
      const defaultPaginationState = { offset: 1, limit: 20, sortField: 'updated_at', sortOrder: 'desc' }
      setPaginationState(defaultPaginationState);
      setTableRefreshKey(new Date().getTime() + Math.random()); 

      const cloneFilter  =  [...filterValue];
      const updatedFilter = cloneFilter && cloneFilter.length 
      ? cloneFilter.filter(item => item.key !== 'status' && item.key !== 'tenant_id') 
      : [];
      setFilterValue(updatedFilter)    
     await getTicketsData(null, null, null, ticketingSystemFilter?.[0]?.value)
    } catch (error) {
      console.error(error);
      dispatch(resetTickets());
    } finally {
      setRefreshLoader(false)
    }
  }, [filterValue, paginationState?.sortField, paginationState?.sortOrder, ticketingSystemFilter, dispatch])


  // Fetch call changes

  const getFilterRequest = useCallback((pagination, filters, searchText, ticketingSystem) => {
    const paginationRequest = pagination ? { ...pagination } : { ...paginationState };
    const ticketingSystemReq = {
      ticketing_system: ticketingSystem
        ? ticketingSystem?.toLowerCase()
        : ticketingSystemFilter?.[0]?.value?.toLowerCase()
          ? ticketingSystemFilter?.[0]?.value?.toLowerCase()
          : "salesforce"
    }
    const updatedfilterValue = filters ? filters : searchInputValue || searchText ? [] : filterValue ? filterValue : null;
    const filterRequest = {
      filter: updatedfilterValue && updatedfilterValue?.length > 0 ? JSON.stringify(updatedfilterValue?.filter(item => item.key !== "ticketing_system")) : updatedfilterValue
    }
    const searchTextRequest = { searchText: searchText === '' ? searchText : searchText ? searchText : searchInputValue?.length > 0 ? searchInputValue : null }
    const req = {
      params: {
        ...filterRequest,
        ...searchTextRequest,
        ...ticketingSystemReq,
        ...paginationRequest,
      },
      tenantId: user?.tenant?.name === "Sangoma" ? "sangoma" : user?.tenant?.id,
    };
    return req;

  }, [paginationState, filterValue, searchInputValue, ticketingSystemFilter, user?.tenant?.id, user?.tenant?.name])

  const getTicketsData = useCallback(async (pagination, searchText, filters, ticketingSystem,  fetchTicketStatusOptions) => {
      setIsLoading(true);
      let updatedFilterValue = []
      try {
        if (fetchTicketStatusOptions) {
          updatedFilterValue= await getTicketStatusOptionsReferenceData(ticketingSystem?.toLowerCase());
        }
        // Cards should load only when filters are applied and on click of header status cards 
        if((!pagination && !searchText) || filters?.length > 0) {
          setShowTicketCardLoader(true)
        }

        if (pagination) {
          setPaginationData({
            offset: pagination?.offset,
            limit: Number(pagination?.limit),
            sortField: pagination?.sortField,
            sortOrder: pagination?.sortOrder,
          });
        }

        const req = getFilterRequest(pagination, fetchTicketStatusOptions ? updatedFilterValue : filters, searchText, ticketingSystem);
        await dispatch(fetchAllTickets(req));
      } catch (error) {
        console.error('Error fetching ticket data:', error);
      } finally {
        setIsLoading(false);
        setShowTicketCardLoader(false);
      }

    }, [getFilterRequest, dispatch, getTicketStatusOptionsReferenceData]);
  
    const handleStatusValueForFiltersOnRedirection = useCallback((status, options = [], ticketingSystem) => {
      let updatedFilterValue = filterValue?.length ? [...filterValue] : [];
    
      updatedFilterValue = updatedFilterValue.filter(i => i.key !== 'status');
      if (user?.tenant?.name !== 'Sangoma') {
        setTenantFilter([]);
        updatedFilterValue = updatedFilterValue.filter(i => i.key !== 'tenant_id');
      }
    
      if (!status || !options.length) {
        const defaultOptions = defaultTicketStatusValues?.[ticketingSystem?.toLowerCase()] || [];
        const defaultTicketStatusOptions = options.filter(item => defaultOptions.includes(item?.value));
    
        if (defaultTicketStatusOptions.length) {
          setTicketStatusFilter(defaultTicketStatusOptions);
          setDefaultTicketFilter(defaultTicketStatusOptions);
          updatedFilterValue = [...updatedFilterValue, {key:'status', value: defaultOptions}]
        }
    
        setFilterValue(updatedFilterValue);
        return updatedFilterValue;
      }
    
      const miscellaneousStatus = ticketingSystem !== "servicenow" ? [
        ticketCardConstants['OPEN'],
        ticketCardConstants['ESCALATED'],
        ticketCardConstants['NEW'],
        ticketCardConstants['IN_PROGRESS'],
        ticketCardConstants['CLOSED'],

      ] : [];
    
      let selectedOptions = [];
      if (status === ticketCardConstants['OTHER']) {
        selectedOptions = options.filter(i => !miscellaneousStatus.includes(i.value));
      } else {
        selectedOptions = options.filter(i => 
          i.value === status || 
          (status === ticketCardConstants['OPEN'] && i.value === 'Open') ||
          (status === ticketCardConstants['NEW'] && i.value === 'New') ||
          (status === ticketCardConstants['ESCALATED'] && i.value === 'Escalated')
        );
      }
    
      if (selectedOptions.length) {
        setTicketStatusFilter(selectedOptions);
        const statusArray = updatedFilterValue.find(item => item?.key === "status");
    
        if (statusArray) {
          statusArray.value = selectedOptions.map(i => i.value || i);
        } else {
          updatedFilterValue.unshift({
            key: 'status',
            value: selectedOptions.map(i => i.value || i),
          });
        }
      }
    
      setFilterValue(updatedFilterValue);
      return updatedFilterValue;
    }, [filterValue, user?.tenant?.name]);
    
    
  
  const fetchData = useCallback(async () => {
    const { state } = location || {};
    const status = state?.status;
    const dashboardTicketingSystem = state?.dashboardTicketingSystem;

    const ticketingSystem = await getTicketingSystems(dashboardTicketingSystem); 
    let options = await getStatusReferenceData({ ticketing_system: ticketingSystem });
    let filters = await handleStatusValueForFiltersOnRedirection(status, options, ticketingSystem);
    // REMINDER: On change of tenant from header, even after clearing the filters previous filter were retained so passing it as empty for initial fetch
    getTicketsData(null, '', filters ? filters : [], ticketingSystem);
  }, [getStatusReferenceData, getTicketingSystems, getTicketsData, handleStatusValueForFiltersOnRedirection, location])


   // Export changes

   const enableFilteredExportBtn = useMemo(()=>{
    const removedExternalFromFilter = filterValue && filterValue?.length > 0 && filterValue?.filter(item => item?.key !== "type")    
    return searchInputValue?.length > 0 || removedExternalFromFilter?.length > 0
  },[searchInputValue, filterValue])

  const getExportAPIRequest = useMemo(() => {    
    return {
      filetype: 'csv',
      dataOf: 'tickets',
      ticketingSystem:ticketingSystemFilter && ticketingSystemFilter?.length > 0 && ticketingSystemFilter?.[0].value?.toLowerCase(),
      totalCount: totalTicketCount,
      tenantId: user?.tenant?.name === "Sangoma" ? null : user?.tenant?.id,
      ...getFilterRequest({ limit: totalTicketCount, offset: 1,  sortField: paginationState?.sortField || 'updated_at', sortOrder: paginationState?.sortOrder || 'desc' })?.params
    }
  }, [ticketingSystemFilter, totalTicketCount, user?.tenant?.name, user?.tenant?.id, getFilterRequest, paginationState?.sortField, paginationState?.sortOrder])  

  const isServiceNowEnabled = useMemo(() => {
    return user?.tenant?.name !== "Sangoma" &&
           ticketingSystemFilter?.some(item => item?.value === 'Servicenow') || false;
  }, [ticketingSystemFilter, user?.tenant?.name]);
  
  useEffect(() => {
    setShowTicketCardLoader(true);
    setIsLoading(true);
    setFilterValue([])
    selectedTicketingSystem.current = [];
    setTicketingSystemFilter([])
    setTenantFilter([])
    setTicketStatusFilter([])
    setSearchInputValue();
    fetchData();
    if (location.state) {

      navigate(location.pathname, { state: null })
    }
    return () => {
      // dispatch(resetTickets());
      toast.dismiss()
      setHighLightFilter(false);
      
    }
  }, [user?.tenant?.id])

  useEffect(() => {
    if (tenants && tenants?.length > 0) {
      let allTenantOption = {
        label: 'All', value: 'all'
      };
      let tenantOptions = tenants?.map(tenant => {
        return {
          label: tenant?.display,
          value: tenant?.id
        }
      })
      tenantOptions = tenantOptions?.filter((item) => item?.value !== "");
      tenantOptions?.unshift(allTenantOption)
      setTenantOptionsList(tenantOptions)
    }
  }, [tenants]);

  return (
    <>
    {showDescriptionPage ? <TicketDetail back={() =>  setShowDescriptionPage(false)}/> 
    :
    <MuiThemedBox key={user?.tenant?.id} className={"main_inner_wrap tickets__dashboard"}>
     
      <Breadcrumbs text={"tickets"} />
      <Box className="filter_wrap">
        <SearchInputField key={filterValue} debounceProp={ticketingSystemFilter} handleClick={handleSearch} value={searchInputValue} />

        <Box sx={{ display: "flex" }}>
          <Grid spacing={2} container key={filterValue}>
            {enableTicketingSystemFilter &&
              <Grid item>
                <SelectDropDown isDisabled={showTicketCardLoader}  className={"ticketing__system__dropdown"} key={ticketingSystemOptionsList} value={ticketingSystemFilter} options={ticketingSystemOptionsList} placeholder={'Ticketing System'} hideFilterIcon={true} onChange={onChangeOfTicketingSystemFilter} />
              </Grid>
            }
            {/* REMINDER: Commenting this for now as only external tickets will be shown */}
            {/* <Grid item>
              <SelectDropDown className={"ticketing__system__dropdown"} key={ticketingSystemOptionsList} value={ticketingTypeFilter} options={ticketTypeOptions} placeholder={'Ticket Type'} hideFilterIcon={true} onChange={onChangeOfTicketTypeFilter} />
            </Grid> */}
            {filterDetails && filterDetails?.length > 0 && filterDetails?.map((item, idx) => {
              if (item?.isEnabled) {
                return (
                  <Grid item key={idx + item?.id}>
                    <MultiSelectDropDown
                      controlShouldRenderValue={false}
                      onChangeValue={item?.onChangeValue}
                      options={item?.options}
                      placeholder={item?.placeholder}
                      containerWidth={"250px"}
                      value={item?.value}
                      onApplyClick={onApplyFilter}
                      defaultFilter={item?.defaultFilter}
                      highlightFilter={item?.highlightFilter}
                      disableApply={item?.disabledApply}
                      className={"ticketing__system__dropdown"} 
                    />
                  </Grid>)
              }
            })}
          </Grid>

        
            <Box gap={"12px"} sx={{ paddingLeft: '12px', display:"flex"}}>
                {isServiceNowEnabled &&
                  <MuiThemedButton onClick={handleEnableServicenow} className=" btn" variant="contained" styles={{ whiteSpace: "pre", height: "36px" }}>
                    <FontAwesomeIcon icon="fa-solid fa-gear" className="iconPadding" />
                    Setup ServiceNow
                  </MuiThemedButton>
                }

              <ButtonLayout
                disabled={refreshLoader}
                text={`Refresh`}
                buttonStyleType={"outlined"}
                icon={
                  <FontAwesomeIcon
                    icon="fa-solid fa-refresh"
                    className="iconPadding"
                  />
                }
                handleClick={handleRefreshBtn}
              />
            </Box>

          <GlobalExportButton
            enableFilteredDataExportButton={enableFilteredExportBtn}
            heading={'Tickets'}
            text={"Are you sure you want to download tickets list ?"}
            fileName={"tickets"}
            exportApiRequest={getExportAPIRequest}
          />
        </Box>
      </Box>
      <TableHeader
            tableHeaderCardDetails={cardDetails}
            handleCardClick={onHeaderCardClick}
            isLoading={showTicketCardLoader || showTicketStatusLoader}
            md={cardLength}
            lg={cardLength}
      />
      <Box>
        <TableComponent
          className="rdt_table_wrap table_with_header"
          columns={ticketColumns}
          key={thirdPartyTicketColumn+tableRefreshKey}
          rows={allTickets}
          isLoading={isLoading || showTicketStatusLoader}
          defaultSortField={'updated_at'}
          pagination={true}
          totalRows={totalTicketCount}
          onPageChange={getTicketsData}
          defaultSortOrder={"desc"}
          defaultSortAsc = {true}
        />
      </Box>

      {openServiceNowConfigurePopup && 
        <ServiceNowConfigurePopup 
        tenant_id={user?.tenant?.name === "Sangoma" ? null : user?.tenant?.id}
        ticketing_system_id={ticketingSystemFilter && ticketingSystemFilter?.length > 0 && ticketingSystemFilter?.[0]?.id}
        handleClose={handleEnableServicenow} 
        open={openServiceNowConfigurePopup} />
      }
    </MuiThemedBox>}
    </>
  )
}

