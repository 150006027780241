import { Box } from '@mui/material';
import NetopsHighCharts, { highchartColors, highchartDarkColors } from 'components/common/NetopsHighCharts';

import React, { useMemo } from 'react'
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const NetopsMonitoringStatusGraph = ({ linkStatusResponse, handleRedirectToMonitoringStatus, isLoading, keyValue }) => {

    const user = useSelector(state => state.user);

    const getColor = useCallback((label) => {
        const updatedLabel = label?.toLowerCase();
        const themePreference = user?.themePreference;
        return themePreference === "dark" ? highchartDarkColors[updatedLabel] : highchartColors[updatedLabel];
    }, [user?.themePreference])

    const options = useMemo(() => {
        const categories = []
        const data = []

        linkStatusResponse && linkStatusResponse?.length > 0 && linkStatusResponse?.forEach((item,) => {
            data?.push(
                {
                    y: Number(item?.total_count),
                    color: getColor(item[keyValue]?.toLowerCase()),
                    metaData: item
                }
            );
            categories?.push(item?.[keyValue]=== 'N/A' ? 'others' : item?.[keyValue]);
        })
        return {
            chart: {
                type: 'bar',
                height: 250,
            },
            title: {
                text: null,

            },
            subtitle: {
                text: null
            },
            xAxis: {
                categories: categories,
                title: {
                    text: null
                },
                labels: {
                    style: {
                        color: "#343F53",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        letterSpacing: "0.8px",
                        textTransform: "uppercase"
                    },
                },

                lineWidth: 0,
            },
            yAxis: {
                min: 0,
                title: {
                    text: null
                },
                labels: {
                    style: {
                        color: "#93A2B1",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "20px",
                        letterSpacing: "0.25px",
                       
                    },
                },

                lineWidth: 0,
                gridLineWidth: 0,
            },
            tooltip: {
                enabled: true,
                backgroundColor: user?.themePreference === 'dark' ? '#4C5863' : '#001221',
                style:{
                    fontSize:'12px',
                    fontFamily:'Open Sans',
                    fontWeight:'400',
                    color: user?.themePreference === 'dark' ? '#E5E6E8' : '#fff',
                },
               
                formatter: function () {
                    const title = this.point.metaData?.[keyValue]?.charAt(0).toUpperCase() + this.point.metaData?.[keyValue]?.slice(1)
                    return '<b>' + title +
                        '</b>: <b>' + this.y + '</b>' + '<b> (' + this.point.metaData.percentage + '%)</b>';
                }
            },

            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: false
                    },
                    cursor: 'pointer',
                    groupPadding: 0.05, 
                    point: {
                        events: {
                            click: function (event) {
                                const data = event?.point?.metaData?.[keyValue]
                                handleRedirectToMonitoringStatus(event, {id: data})

                            }
                        },
                    },
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: [{
                name: '',
                data: data,
                pointWidth: 15,
                borderWidth: 0,
                // borderRadius: 5,


            }]
        }
    }, [handleRedirectToMonitoringStatus, linkStatusResponse])

    return (

        <Box className="monitoring__status__graph__container">
            <NetopsHighCharts options={options} noData={!linkStatusResponse || (linkStatusResponse && !Object.keys(linkStatusResponse).length) || (Object.keys(linkStatusResponse).every((k) => k?.link_status === 0))} isLoading={isLoading} />
        </Box>


    )
}

export default NetopsMonitoringStatusGraph
