import { Box, Button, CircularProgress, styled, Switch, Typography } from "@mui/material"
import { BlockUI } from "ns-react-block-ui"
import { useSelector } from "react-redux"
import Select, {  components }  from "react-select";
import { ReactComponent as FilledArrowDropdown } from "../../netopsStyles/assets/arrow-drop-down.svg";
import { ReactComponent as ArrowDropdown } from "../../netopsStyles/assets/keyboard-arrow.svg";

export const MuiThemedBox = ({ children, className, styles }) => {
  return (
    <Box className={className} sx={{
      backgroundColor: (theme) => theme.palette.mode === "dark"
        ? "#001221  !important"
        : "",
        ...styles
    }}>
      {children}
    </Box>
  )
}


export const MuiTypography = ({ children, className, styles }) => {
  return (
    <Typography className={className} sx={{
      color: (theme) => theme.palette.mode === "dark"
        ? "#fff !important"
        : "",
        ...styles
    }}>
      {children}
    </Typography>
  )
}

export const MuiThemedButton = ({ children,showLoader, className, variant, color, disable,blockUIStyle,styles,  ...rest }) => {
  const user = useSelector(state => state?.user)
  return (
    <BlockUI  blocking={showLoader} loader={<MuiThemedCircularProgress />}  style={{ display:"flex", ...(blockUIStyle ? blockUIStyle : {})} }>
    <Button
      className={className}
      disabled = {disable}
      variant={variant}
      sx={{
        '&:hover': {
          backgroundColor: (theme) => {
            if (variant === 'contained') {
              if (color === 'success') {
                return '#2e7d32 !important'
              } else if (color === 'error') {
                return '#d32f2f !important'
              } else {
                return theme.palette.mode === 'dark' ? 'rgb(66, 165, 245)  !important ' : '#244C91 !important';
              }

            } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
              return 'rgba(144, 202, 249, 0.08)  !important';
            }

          },

          color: (theme) => {
            if (variant === 'contained') {
              if (color === 'success' || color ==='error') {
                return '#fff !important'

              } else {
                return theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.87)' : '';
              }
            } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
              return 'rgb(144, 202, 249) !important'
            }
          },
        },
        backgroundColor: (theme) => {
          if (disable &&  user?.themePreference === 'light') return '#f0f0f0 !important';
          if (variant === 'contained') {
            if (color === 'success') {
              return '#2e7d32 !important'
            } else if (color === 'error') {
              return '#d32f2f !important'
            } else {
              return theme.palette.mode === 'dark' ? 'rgb(144, 202, 249)  !important ' : '#244C91';
            }

          } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
            return 'transparent  !important';
          }

        },
        color: (theme) => {
          if (disable && user?.themePreference === 'light') return '#a0a0a0 !important';
          if (variant === 'contained') {
            if (color === 'success' || color ==='error') {
              return '#fff !important'

            } else {
              return theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.87)' : '';
            }
          } else if (variant === 'outlined' && theme.palette.mode === 'dark') {
            return 'rgb(144, 202, 249) !important'
          }

        },
        border:(theme) =>{
          if (variant === 'outlined' && theme.palette.mode === 'dark') {
            return '1px solid rgb(144, 202, 249) !important'
          }

        },
        ...styles
      }}
      {...rest}
    >
      {children}
    </Button>
    </BlockUI>

  )
}

export const HeaderDropdownIndicator =  props => {
  return (
    <components.DropdownIndicator {...props}>
      <FilledArrowDropdown className="react__select__arrow" alt ="select-arrow" />
    </components.DropdownIndicator>
  );
};


export const CustomDropdownIndicator =  props => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropdown className="react__select__single__line__arrow" alt ="select-arrow" />
    </components.DropdownIndicator>
  );
};
export const ThemedReactSelect = ({ isRequired, ...props }) => {

  const user = useSelector(state => state?.user);
  const selectStyle = user?.themePreference === 'dark' ? {...darkThemeReactSelectStyles, ...props?.customStyles} : props?.customStyles;

  return (
    <Select
      classNamePrefix={`${props.classNamePrefix || ''} ${isRequired ? ' react__select__required   ' : ''} `}
      styles={{...selectStyle}}
      isLoading={props?.isLoading}
      {...props}
    />
  )
}

export const darkThemeReactSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #caced6',
    color: state.isSelected ? '#262a30' : '#fff',
    backgroundColor: state.isSelected ? '#999eaa' : '',
    boxShadow:'0 0 0 1px hsl(0deg 2.98% 58.56% / 10%), 0 4px 11px hsl(0deg 6.08% 86.38% / 10%)',
    '&:active': {
      backgroundColor: '#dee1e7db',
      color: ' #393030'
    },
    '&:hover': {
      backgroundColor: '#dee1e7db',
      color: ' #393030'
    }
  }),
  control: (provided) => ({
    ...provided,
    boxShadow:'0 0 0 1px #54606d',
    backgroundColor: 'transparent !important',
    borderColor:'#383d43 !important',

  }),
  input:(provided) => ({
    ...provided,
    color: '#fff !important'
  }),
  singleValue: (provided) =>({
    ...provided,
    color: '#fff'
  }),
  menu: (provided) =>({
    ...provided,
    backgroundColor: '#31363F !important',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color:'#000',
  }),

}

export const ReactDataTableStyles = {
  text: {
    primary: '#fff !important',

  },
  background: {
    default: '#31363F',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}

export const MuiThemedCircularProgress = () => {
  const user = useSelector(state => state?.user);
  const themePreference = user?.themePreference;
  return(
    
    <CircularProgress  style={{
      color: themePreference==="dark" ?"#F2F2F3":"#244C91"
    }}/>
  )
}


export const ThemedSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#244C91' : '#CCCFD2', // Dynamic based on theme
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.action.disabledOpacity,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#fff',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    backgroundColor: theme.palette.mode === 'dark' ? '#6b6b6b' : '#CCCFD2', // Dynamic track color
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
