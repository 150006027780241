
import React, { useState, useEffect } from 'react'
import FormPopup from "components/common/modal/FormPopup";
import Header from 'components/common/modal/Header';
import {Box} from "@mui/material";
import ProgressLoading from 'components/common/ProgressLoading';
import Footer from "components/common/modal/Footer";
import FormLayout from "components/common/FormLayout";
import { addFirmwareTag, updateFirmwareTag } from 'store/gateway';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';

export default function AddTagPopup({onClose, open, isLoading, tenantList, data}) {
  const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
  const [formValues, setFormValues] = useState({});
  const [nameError, setNameError] = useState([]);
	const [isError, setIsError] = useState(false);
  const [markError, setMarkError] = useState(false);

useEffect(() => {
    setFormValues(data);
}, [data])

  const validateInput = async (e, id, regex, errorMessage) => {
    if (regex.test(e?.target?.value) || e.target?.value === '') {
        setNameError("");
        setIsError(false);
        await setFormValues((prevValue) => ({
            ...prevValue,
            [id]: e.target.value,
        }));
    } else {
        setNameError((prevValue) => ({
            ...prevValue,
            msg: errorMessage,
            id: id,
        }));
        setIsError(true);
        await setFormValues((prevValue) => ({
            ...prevValue,
            [id]: e.target.value,
        }));
    }
};


  const handleInputChange = async (data, id) => {
    if (id === 'tenants') {
      setFormValues((prevValues) => ({
        ...prevValues,
        [id]: data,
      }));
    }
    else if (id === 'url') {
      const urlRegex = /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i;
      const urlErrorMessage = "Invalid URL. Please enter a valid URL starting with http:// or https://.";
      await validateInput(data, id, urlRegex, urlErrorMessage);
    } else if (id === 'tag') {

      const maxLength = 20;
      const tagRegex = /^[a-zA-Z0-9-_ ]*$/;
      const tagErrorMessage = "Only alphanumeric characters, hyphen, underscore, and spaces are allowed.";
      const lengthErrorMessage = `Tag Name must not exceed ${maxLength} characters.`;

      const tagValue = data.target.value;

      if (tagValue?.length > maxLength) {
        setNameError((prevValue) => ({
          ...prevValue,
          msg: lengthErrorMessage,
          id,
        }));
        setIsError(true);
      } else if (!tagRegex.test(tagValue)) {

        setNameError((prevValue) => ({
          ...prevValue,
          msg: tagErrorMessage,
          id,
        }));
        setIsError(true);
      } else {
        setFormValues((prevValue) => ({
          ...prevValue,
          [id]: data?.target?.value,
        }));
      }
    }
    else {
      setFormValues((prevValue) => ({
        ...prevValue,
        [id]: data?.target?.value,
      }));
    }

  }

  const forms = [
    {
      title: "Tenants",
      identifier: "tenants",
      parameterType: "Autocomplete",
      defaultValue: "",
      validationRules: ["required"],
      optionList: tenantList,
      widthMd: 12,
      widthsm: 12,
      widthxs: 12,
      isEnabled: !user?.tenant?.id,
  },
    {
      title: "CFG Group Name",
      identifier: "tag",
      parameterType: "InputField",
      defaultValue: "",
      validationRules: ['required'],
      widthMd: 12,
      widthsm: 12,
      widthxs: 12,
      isEnabled: true,
  },
  {
    title: "CFG Link",
    identifier: "url",
    parameterType: "InputField",
    defaultValue: "",
    validationRules: ['required'],
    widthMd: 12,
    widthsm: 12,
    widthxs: 12,
    isEnabled: true,
  },
  {
    title: "Description",
    identifier: "description",
    parameterType: "InputField",
    defaultValue: "",
    validationRules: [],
    widthMd: 12,
    widthsm: 12,
    widthxs: 12,
    isEnabled: true,
  }, {
    title: "Firmware Version",
    identifier: "version",
    parameterType: "InputField",
    defaultValue: "",
    validationRules: [],
    widthMd: 12,
    widthsm: 12,
    widthxs: 12,
    isEnabled: true,
  }
  ]



  const onSave = async() => {
    if((user?.tenant?.id || formValues?.tenants?.value) && formValues?.url && formValues?.tag){
    let req = {
      tenantId: user?.tenant?.id ? user?.tenant?.id : formValues?.tenants?.value, 
      tag: formValues?.tag, description: formValues?.description,
      url: formValues?.url, version: formValues?.version
    }
    try{
      if(formValues?.id){
        await dispatch(updateFirmwareTag({id: formValues?.id, ...req}))?.unwrap();
        toast.success("CFG Group updated Successfully")
      }
      else{
      await dispatch(addFirmwareTag(req))?.unwrap();
      toast.success("CFG Group added Successfully")
      }
      onClose(null, true);
    }
    catch(error){
      console.error(error)
    }
    }
    else{
      toast.error('Please Fill required field');
      setMarkError(true);
            setTimeout(() => {
                setMarkError(false);
            }, 5000);
    }
  }
  
  return (
    <FormPopup open={open} size={"deviceModal"} className={"stepper__modal"}>
          <div className="create__site__popup__wrap">
            <Header text={`${data?.tag ? "Edit CFG Group" : "Add CFG Group"}`} close={onClose}/>
            <Box className="formWrapper site_formWrap">
                           {isLoading ? <ProgressLoading/> : 
                          <FormLayout
                          markError={markError}
                           parameters={forms.filter(i => i.isEnabled)}
                           formValues={formValues}
                           handleSelectChange = {handleInputChange}
                           handleInputChange={handleInputChange}
                           handleOnfocus={handleInputChange}
                           nameErrorMsg ={nameError}
                           isError={isError}/>}
                          
            </Box>
            <Footer submit={onSave} isLoading={isLoading} text={formValues?.id ? "update" : "Save"} 
            disabled={isLoading}/>
          </div>
          </FormPopup>
  )
}


