import { Badge, styled } from "@mui/material"
import { colorConstants } from "components/common/NetopsStatusCards";

export const defaultTicketStatusValues = {
    salesforce: ["Open", "New", "In Progress", "Escalated", "Needs Followup"],
    servicenow: [ "New", "In Progress", "Closed", "On Hold"],
}

export const ticketTypeOptions = [
    {
      id: 1,
      value: 'internal',
      label: 'Internal'
    },
    {
      id: 2,
      value: 'external',
      label: 'External'
    }
  ]


export const ticketCardColors = {
    servicenow:{
        textColor:"#fff",
        bgColor:"#00A1E0",
        title: "Salesforce",
        redirectOption:"salesforce",
        commentSearchTerm:"**SALESFORCE**",
        commentSearchSystem: "Salesforce",
        commentSystem: "Servicenow",
        commentBorderColor:"#82b6a2",
        brandColor:"#00A1E0"

    },
    salesforce:{
        textColor:"#243c3e",
        bgColor:"#82b6a2",
        title: "Servicenow",
        redirectOption:"servicenow",
        commentSearchTerm:"**SERVICENOW**",
        commentSearchSystem: "Servicenow",
        commentSystem:"Salesforce",
        commentBorderColor:"#00A1E0",
        brandColor:"#243c3e"


    },
    netops: {
        textColor:"#fff",
        commentBorderColor: "#244C91"
    }
}

export const ticketSalesForceSideBarDetails = [
    {
        id: 1,
        valueKey: "priority",
        title: "Priority",
        type: "Priority",
        isEnabled: true,
    },
    {
        id: 3,
        valueKey: "status",
        title: "Status",
        type: "Status",
        isEnabled: true,
    },
    {
        id: 14,
        valueKey: "created_date",
        title: "Created Date",
        type: "Date",
        isEnabled: true,
    },
    {
        id: 9,
        // Case owner will be the assignee of the ticket
        valueKey: "owner_name",
        title: "Assigned To",
        isEnabled: true,
    },
    {
        id: 8,
        valueKey: "created_by_name",
        title: "Created By",
        isEnabled: true,

    },
    {
        id: 20,
        valueKey: "account_name",
        title: "Account Name",
        isEnabled: true,
    },

    {
        id: 2,
        valueKey: "severity",
        title: "Severity",
        isEnabled: true,
    },
    {
        id: 7,
        valueKey: "last_modified_by_name",
        title: "Modified By",
        isEnabled: true,
    },
    {
        id: 12,
        valueKey: "last_modified_time",
        title: "Last Modified Time",
        type: "Date",
        isEnabled: true,
    },
    {
        id: 5,
        valueKey: "contact_email",
        title: "Contact Email",
        isEnabled: true,
    },


    {
        id: 11,
        valueKey: "closed_date",
        title: "Closed Date",
        type: "Date",
        isEnabled: true,
    },
]


export const ticketServiceNowSideBarDetails = [
    {
        id: 2,
        valueKey: "priority",
        title: "Priority",
        type: "Priority",
        isEnabled: true
    },
    {
        id: 2,
        valueKey: "state",
        title: "Status",
        type: "Status",
        isEnabled: true
    },
    {
        id: 12,
        valueKey: "sys_created_on",
        title: "Created Date",
        type: "Date",
        isEnabled: true
    },
    {
        id: 13,
        valueKey: "assigned_to.display_value",
        title: "Assigned To",
        isEnabled: true
    },
    {
        id: 20,
        // Case 1:
        // The affected user themselves are creating the incident, in which case Caller is the current user creating the Incident. There is another field Opened by, which in case will be same as the Caller.
        // Case 2:
        // A user or Service Desk agent is creating the incident on behalf of another user. In this case, Caller is the actual user affected by the issue. Opened by is the user who is actually creating the Incident.
        valueKey: "sys_created_by",
        title: "Created By",
        isEnabled: true
    },
    {
        id: 16,
        valueKey: "opened_by.display_value",
        title: "Opened By",
        isEnabled: true
    },
    {
        id: 16,
        valueKey: "severity",
        title: "Severity",
        isEnabled: true,
        type:"CleanString"
    },
    {
        id: 1,
        valueKey: "category",
        title: "Category",
        isEnabled: true
    },

    {
        id: 17,
        valueKey: "impact",
        title: "Impact",
        type: "CleanString",
        isEnabled: true
    },

    {
        id: 17,
        valueKey: "urgency",
        title: "Urgency",
        isEnabled: true,
        type:"CleanString"
    },

    {
        id: 13,
        valueKey: "escalation",
        title: "Escalation",
        isEnabled: true
    },

    {
        id: 4,
        valueKey: "opened_at",
        title: "Opened At",
        type: "Date",
        isEnabled: true
    },
    {
        id: 11,
        valueKey: "due_date",
        title: "Due Date",
        type: "Date",
        isEnabled: true
    },
    {
        id: 9,
        valueKey: "closed_at",
        title: "Closed Time",
        type: "Date",
        isEnabled: true
    },
    {
        id: 10,
        valueKey: "closed_by.display_value",
        title: "Closed By",
        isEnabled: true
    },
]

// REMINDER:
// comments public work_notes private
// type as 1 for public and 2 for private comments
export const typeMapper = {
    work_notes: 2,
    comments: 1
}

export const getPriorityStyles = (priority) => {
    switch (priority) {
        case 'critical':
        case 'p1':
            // Red
            return {
                textColor: '#fff',
                bgColor: '#B8001F',
            };
        case 'high':
        case 'p2':
            // orange
            return {
                textColor: '#fff',
                bgColor: '#FF8225',
            };
        case 'medium':
        case 'p3':
            // yellow
            return {
                textColor: '#fff',
                bgColor: '#fbbd00',
            };
        case 'low':
        case 'p4':
            // Green
            return {
                textColor: '#fff',
                bgColor: '#008000',
            };
        default:
            return {
                textColor: '#1E201E', // Default text color
                bgColor: '#B5C0D0', // Default background color
            };
    }
};


export const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
        case 'new':
            return '#d53b3b';
        // Gray
        case 'closed':
        case 'solved':
        case 'completed':
            return '#117554';
        // Blue
        case 'in progress':
            return '#244C91';
        // red
        case 'on hold':
            return '#d1d1d1'
        case 'on hold - sangoma':
            return '#B8001F';
        // Orange
        case 'resolved':
            return '#FB773C';
        case 'canceled':
            return '#FEFDED';
        case 'feedback':
        case 'researching':
        case 'referred to contact sales form':
        case 'waiting on customer':
        case 'rma':
        case 'waiting on carrier':
        case 'dispatch':
        case 'monitor':
            return '#dddddd';
        // Green
        case 'open':
            return '#00a134';
        case 'escalated':
            return '#931A25';
        default:
            return '#B5C0D0'; // Default color
    }
}

export const getStatusTextColor = (status) => {
    switch (status.toLowerCase()) {
        case 'new':
        case 'closed':
        case 'solved':
        case 'open':
        case 'escalated':
        case 'completed':
        case 'in progress':
        case 'on hold - sangoma':
            return '#fff';   
        case 'on hold':
        case 'resolved':
        case 'canceled':
        case 'feedback':
        case 'researching':
        case 'referred to contact sales form':
        case 'waiting on customer':
        case 'rma':
        case 'waiting on carrier':
        case 'dispatch':
        case 'monitor':
            return '#000';
        default:
            return '#1E201E'; // Default text color
    }
}

export const ticketSystemBrandColorMapping = {
    salesforceTickets: {
        bgColor: "#00A1E0",
        textColor: "#fff"
    },
    serviceNowTickets: {
        bgColor: "#81B5A1",
        textColor: "#293E40"
    }
}

export const StyledBadge = styled(Badge)(() => ({

    '& .MuiBadge-badge': {
        right: 6,
        top: 6,
        height: 10,
        width: 10,
        padding: '0 4px',
        background: '#F4CE14'
    },
}));

export const cleanString = (str) => {
    if (!str) return '';
    return str.toString().replace(/[0-9\s\W]/g, '');
};

export const ticketCardConstants = {
    OPEN: 'Open',
    NEW: 'New',
    RECENTLY_OPEN: 'recently_open',
    CLOSED: 'Closed',
    ON_HOLD: 'On Hold',
    ESCALATED: 'Escalated',
    IN_PROGRESS: 'In Progress',
    OTHER: 'Other',
}

export const ticketDetails = (ticketStatusResponse, filterValue, options) => {

    const enableCards = (value) => {
        let enableFilter = true;
    
        if (filterValue && filterValue.length > 0) {
            // if (value === ticketCardConstants['RECENTLY_OPEN'] || value === ticketCardConstants['OPEN']) {
            //     enableFilter = filterValue.includes(ticketCardConstants['NEW']) || 
            //                    filterValue.includes(ticketCardConstants['OPEN']);
            // } else 
            if (value === ticketCardConstants['OTHER']) {
                const dontIncludeData = [
                    ticketCardConstants['OPEN'],
                    ticketCardConstants['ESCALATED'],
                    ticketCardConstants['NEW'],
                    ticketCardConstants['IN_PROGRESS'],
                    ticketCardConstants['CLOSED']
                ];
    
                // Filter options excluding dontIncludeData
                let otherOptions = options.filter(option => !dontIncludeData.includes(option.value));
                
                // Check if all other options are in the filterValue array
                enableFilter = otherOptions.some(option => filterValue.includes(option.value));
    
            } else {
                enableFilter = filterValue.includes(value);
            }
        }
    
        return enableFilter;
    };
    
    return [
        {
            id: ticketCardConstants['OPEN'],
            text: 'Open',
            count: Number(ticketStatusResponse?.['open']?.total_count || 0),
            percentage: Number(ticketStatusResponse?.['open']?.percentage || 0),
            className: 'ticket__card__open',
            isEnabled: enableCards(ticketCardConstants['OPEN']),
            tooltipText:'This category includes tickets that are currently open.',
            color: colorConstants["GREEN"]
        },
        {
            id: ticketCardConstants['NEW'],
            text: 'New',
            count: ticketStatusResponse?.['new']?.total_count || 0,
            percentage: ticketStatusResponse?.['new']?.percentage || 0,
            className: 'link__monitor__card__recently_open',
            isEnabled: enableCards(ticketCardConstants['NEW']),
            tooltipText:'This category includes tickets that have been newly created.',
            color: colorConstants["RED"]
        },
        {
            id: ticketCardConstants['IN_PROGRESS'],
            text: 'In Progress',
            count: ticketStatusResponse?.['in progress']?.total_count || 0,
            percentage: ticketStatusResponse?.['in progress']?.percentage || 0,
            className: 'link__monitor__card__in_progress',
            isEnabled: enableCards(ticketCardConstants['IN_PROGRESS']),
            tooltipText:'Tickets in this category are actively being worked on and have the status "In Progress."',
            color: colorConstants["BLUE"]

        },
        {
            id: ticketCardConstants['ESCALATED'],
            text: 'Escalated',
            count: ticketStatusResponse?.['escalated']?.total_count || 0,
            percentage: ticketStatusResponse?.['escalated']?.percentage || 0,
            className: 'link__monitor__card__escalated',
            isEnabled: enableCards(ticketCardConstants['ESCALATED']),
            tooltipText: 'This category includes tickets that require additional attention or priority handling.',
            color: colorConstants["RED"]

        },
        {
            id: ticketCardConstants['CLOSED'],
            text: 'Closed',
            count: ticketStatusResponse?.['closed']?.total_count || 0,
            percentage: ticketStatusResponse?.['closed']?.percentage || 0,
            className: 'link__monitor__card__closed',
            isEnabled: enableCards(ticketCardConstants['CLOSED']),
            tooltipText: 'This category includes tickets that have been closed.',
            color: colorConstants["GREEN"]

        },
        {
            id: ticketCardConstants['OTHER'],
            text: 'Misc State',
            count: ticketStatusResponse?.['other']?.total_count || 0,
            percentage: ticketStatusResponse?.['other']?.percentage || 0,
            className: 'link__monitor__card__na',
            isEnabled: enableCards(ticketCardConstants['OTHER']),
            tooltipText:'This category captures tickets with various statuses that do not fall under open, new, in-progress, escalated and closed.',
            color: colorConstants["GREY"]

        },

    ]
}


export const servicenowHeaderCardDetails = (ticketStatusResponse, filterValue) => {

    const enableCards = (value) => {
        let enableFilter = true;
    
        if (filterValue && filterValue.length > 0) {
            enableFilter = filterValue.includes(value);
        }
    
        return enableFilter;
    };
    
    return [
        {
            id: ticketCardConstants['NEW'],
            text: 'Total New',
            count: ticketStatusResponse?.['new']?.total_count || 0,
            percentage: ticketStatusResponse?.['new']?.percentage || 0,
            className: 'link__monitor__card__recently_open',
            isEnabled: enableCards(ticketCardConstants['NEW']),
            tooltipText:'This category includes tickets that have been newly created.',
            color: colorConstants["RED"]
        },
        {
            id: ticketCardConstants['IN_PROGRESS'],
            text: 'Total In Progress',
            count: ticketStatusResponse?.['in progress']?.total_count || 0,
            percentage: ticketStatusResponse?.['in progress']?.percentage || 0,
            className: 'link__monitor__card__in_progress',
            isEnabled: enableCards(ticketCardConstants['IN_PROGRESS']),
            tooltipText:'Tickets in this category are actively being worked on and have the status "In Progress."',
            color: colorConstants["BLUE"]
        },

        {
            id: ticketCardConstants['ON_HOLD'],
            text: 'Total On Hold',
            count: ticketStatusResponse?.['on hold']?.total_count || 0,
            percentage: ticketStatusResponse?.['on hold']?.percentage || 0,
            className: 'link__monitor__card__na',
            isEnabled: enableCards(ticketCardConstants['ON_HOLD']),
            tooltipText:'Tickets in this category are actively being worked on and have the status "On Hold."',
            color: colorConstants["GREY"]

        },
        {
            id: ticketCardConstants['CLOSED'],
            text: 'Total Closed',
            count: ticketStatusResponse?.['closed']?.total_count || 0,
            percentage: ticketStatusResponse?.['closed']?.percentage || 0,
            className: 'ticket__card__open',
            isEnabled: enableCards(ticketCardConstants['CLOSED']),
            tooltipText: 'This category includes tickets that have been closed.',
            color: colorConstants["GREEN"]

        },
    ]
}



