import { Box } from '@mui/material'
import { getPriorityStyles } from './ticketsConstants'

const PriorityComponent = ({ priority }) => {
  if (priority) {
    return (
      <Box sx={{ display: "flex" }}>
        <Box className='priority' sx={{ backgroundColor: getPriorityStyles(priority?.toLowerCase())?.bgColor, color: getPriorityStyles(priority?.toLowerCase())?.textColor }}>
          {priority}
        </Box>
      </Box>
    )
  } else {
    return '--'
  }

}

export default PriorityComponent
