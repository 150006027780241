import React, { useState, useEffect, useMemo, useCallback, Suspense } from 'react';
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Breadcrumbs from "components/common/Breadcrumps";
import { MuiThemedBox } from "components/common/MuiThemedComponents";
import SearchInputField from "components/common/SearchInputField";
import { useSelector, useDispatch } from "react-redux";
import TableComponent from 'components/common/TableComponent/TableComponent';
import {setDeviceDetails} from 'store/devices';
import { fetchPhoneData } from 'store/phones';
import { toast } from "react-toastify";
import { statusTooltipText } from '../link/LinkMonitoring';
import TableHeader from "components/common/TableHeader/TableHeader";
import FilterStatusAndService from "components/common/FilterStatusAndService";
import { filterStatusList, StatusFilterList } from '../devices/Main';
import { useLocation } from 'react-router-dom';
import ColumnHeaderWithTooltip from 'components/common/TableComponent/ColumnHeaderWithTooltip';
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell';
import Timer from 'components/component/link/Timer';
import PhoneFlappingHistoryPopup from './PhoneFlappingHistoryPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useInterval from 'components/common/useInterval';

export default function Phones() {
    const user = useSelector((state) => state.user);
		// const navigate = useNavigate();
	const location = useLocation();
	const tenants = useSelector((state) => state.devices?.tenants)?.filter(i => (i.name !== 'Sangoma' && i.custom_fields?.endpoint_monitoring_enabled));
	const dispatch = useDispatch();
	const [tableColumns, setTableColumns] = useState([]);
	const [shouldHeaderLoader, setShouldHeaderLoader] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [filter, setFilter] = useState([]);
	const [totalCount, setTotalCount] = useState(0)
    const [phoneStatus, setPhoneStatus] = useState({});
    const [searchTextState, setSearchTextState] = useState(null);
	const [highlightFilter, setHighLightFilter] = useState(false);
	const [statusFilter, setstatusFilter] = useState([]);
	const [tenantFilter, setTenantFilter] = useState([]);
	const [filterText, setFilterText] = useState(null);
	const [rows, setRows] = useState([]);
	const [flappingHistoryPopupData, setFlappingHistoryPopupData] = useState({
		openPopup: false, 
		data: null
	});

	const [deviceStatusFilterValue, setdeviceStatusFilterValue]=useState([]);
	let filterStatusListData = user?.tenant?.name !== "Sangoma"? filterStatusList?.filter(data => data?.value !== 'decommissioning'): filterStatusList;
	const [paginationState, setPaginationState] = useState({
		offset: 1, limit: 20, sortField: 'event_time', sortOrder: 'desc'
	});
	const [tableRefreshKey, setTableRefreshKey] = useState(null);

	const setPaginationData = (newState) => {
		setPaginationState(prevState => ({ ...prevState, ...newState }))
	}

	const getFilterRequest = useCallback((fltr, pagination, searchText) => {
		let filterReq = 
		fltr ? fltr : searchTextState  ? [] : filter?.length > 0 ? { filter: searchText && filter[0]?.value[0].includes("active") ? '' : JSON.stringify(filter) } : null;
		let searchTextValue = searchText == '' ? searchText : searchText ? searchText.trim() : searchTextState?.trim() || '';
		let req = pagination ? { ...pagination, 
			searchText: searchTextValue, ...filterReq } :
			{ ...paginationState, searchText: searchTextValue, ...filterReq}

		let request = { tenantId: user?.tenant?.id, tenantName: user.tenant?.name, ...req }

		return request || {}
	},[filter, paginationState, searchTextState, user.tenant?.id, user.tenant?.name])


	
	const getDevicesData = async (pagination, searchText, fltr, pageChange) => {
		setShouldHeaderLoader(!pageChange);
		setIsLoading(true);
		if (pagination) {
			setPaginationData({
				offset: pagination?.offset, limit: pagination?.limit, sortField: pagination?.sortField,
				sortOrder: pagination?.sortOrder
			})
		}
		let request = getFilterRequest(fltr, pagination, searchText)

		let deviceData = [];
		let data = [];
		try {
			if(location?.state?.deviceData == undefined){
				deviceData = await dispatch(fetchPhoneData(request)).unwrap();
				data = await deviceData?.data;
				setRows(data);

			setTotalCount(deviceData?.count);
			!pageChange && setPhoneStatus(deviceData.headerData || {});
			}
			if(location?.state?.deviceData !== undefined){
				setRows(location?.state?.deviceData);
				setTotalCount(location?.state?.deviceData.length);
				!pageChange && setPhoneStatus(location?.state?.headerData || {});
			}
		} catch (error) {
			toast.error("Oops! Something went wrong");
			console.error("Error fetching tenants data:", error);
		} finally {
			setIsLoading(false);
		}
	}


	const columns= [
		{
			cell: (row) => { return <>{row.tenant_name} </> },
			name: "Tenant",
			title: "Tenant",
			sortable: true,
			grow:"0 !important",
			minWidth: 170,
			isEnabled: true,
			sortField: "tenant_name"
		},
		{
			name: "Site Display Name",
			title: "Site Display Name",
			wrap: true,
			weight: 2,
			minWidth: 200,
			sortable: false,
			sortField: 'site.display_name',
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.site_display_name || '--'}/>,
			isEnabled:true,
		},

		{
			name: "Site Name",
			title: "Site Name",
			wrap: true,
			weight: 2,
			minWidth: 200,
			sortable: false,
			sortField: 'site.name',
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.site_name || '--'}/>,
			isEnabled:false,
		},
		{
			sortField: "name",
			name: "Extension Display",
			title: "Extension Display",
			dataType: "String",
			sortable: true,
			minWidth: 200,
			weight: 2,
			description: "",
			cell: (row) => 
				<CopyTextCell showWithTooltip={true} text = {row?.name}/>,
			isEnabled:true,
		},
		{
			name: "Extension",
			title: "Extension",
			wrap: true,
			weight: 1,
			sortField: 'serial',
            minWidth: 200,
			cell: (row) => <CopyTextCell text = {row.extension_number}/>,		
			isEnabled:true,
		},
		{
						name: "Call Quality",
						title: "Call Quality",
						sortable: true,
						wrap: true,
						width: 130,
						cell: (row) => {
							const color = row?.score >= 4 ? 'green' : row?.score < 4 && row?.score >= 3.1 ? 'orange' : 'red';
							const text =  row?.score >= 4 ? 'Good' : row?.score < 4 && row?.score >= 3.1 ? 'Fair' : 'Bad';
							return (
								<>
								{row?.score ? <div style={{alignItems: 'center', display: 'flex'}}>
									<FontAwesomeIcon icon="fa-solid fa-check" color ={color} style={{marginRight: '7px', height: '20px'}}/> 
									<TooltipLabel text={text}/>
								</div> : '--'}
								</>
							)
						},
						isEnabled: process.env.REACT_APP_VOICE_SCORE_ENABLED
		
		},
		{
			name: "Phone Brand",
			title: "Phone Brand",
			wrap: true,
			minWidth: 150,
			weight: 1,
			sortField: 'serial',
			cell: (row) => {
			return <TooltipLabel text= {row?.manufacturer|| '--'}/>},		
			isEnabled:true,
		},
		{
			name: "Phone Model",
			title: "Phone Model",
			wrap: true,
			minWidth: 150,
			weight: 1,
			sortField: 'serial',
			cell: (row) => {
			return <TooltipLabel text= {row?.model|| '--'}/>},		
			isEnabled:true,
		},
		{
			name: "Firmware",
			title: "Firmware",
			wrap: true,
			weight: 1,
            minWidth: 200,
			sortField: 'serial',
			cell: (row) => <TooltipLabel text= {row?.mac || '--'}/>,		
			isEnabled:true,
		},
		{
			selector: 'status',
			cell: row => <div className={
				row?.inv_status?.toLowerCase() === "active" ||
					row?.inv_status?.toLowerCase() === "up"
					? "status status_active"
					: row?.inv_status?.toLowerCase() === "offline" ||
						row?.inv_status?.toLowerCase() === "decommissioning"
						? "status status_down"
						: "status status_inactive"
			}><TooltipLabel text = {row.inv_status}/></div>,
			name: 'state',
			title: 'state',
			sortField: 'inv_status',
			wrap: true,
			isEnabled:true,
			sortable: true,
		},
		{
			selector: (row) => row?.phone_status ? row?.phone_status : "--",
			cell: row => 
				<div data-tag="allowRowEvents" className="flex">
					<span data-tag={"allowRowEvents"} className={
						row?.phone_status?.toLowerCase() === "up"
							? "status status_active"
							: row?.phone_status?.toLowerCase() === "down"
								? "status status_down"
								: row?.phone_status?.toLowerCase() === "degraded"
									? "status status_degraded" : row?.phone_status?.toLowerCase() === "maint" ? "status status_inactive" : row?.connection_status?.toLowerCase() === "n/a" ? "status status_na" : ""
					} >{row?.phone_status ? row?.phone_status : "--"}</span>

				</div>,
			name: <ColumnHeaderWithTooltip tooltipText={'Indicates the current operational condition of a device within a network.'} columnName={'Status'} />,
			title: 'Status',
			sortable: false,
			wrap: true,
			sortField: 'phone_status',
			minWidth: 150,
			isEnabled:true,
		},
        {
			cell: (row) => {
				return row?.event_time ? <Timer targetDate = {row?.event_time}/> : '--';
			},
			name: "Since",
			title: "Since",
			sortable: true,
			grow:"0 !important",
			// minWidth: 200,
			isEnabled: true,
			sortField: "event_time"
		}
	]

	useEffect(() => {
		if (!user.tenant) {
			return;
		} else {
			dispatch(setDeviceDetails({ deviceName: undefined, serviceName: undefined, deviceData: undefined }));
		}
		setFilter([]);
		setTenantFilter([]);
		setdeviceStatusFilterValue([]);
		setstatusFilter([]);
		setSearchTextState('')
		setFilterText(null);
		let filterCols = columns?.filter((obj) => user?.tenant?.name !== "Sangoma" ? obj?.name !== "Tenant" : obj);
		setTableRefreshKey(new Date().getTime())
		setTableColumns(filterCols);
		const { state } = location || {};
		if (state && Object?.keys(state)?.length) {
			const  {status, stateValue } = state;
			let locationFilters = [];

			if (status) {
				let statusValue = status==="n/a" ||  status==="N/A" ? "N/A": status?.toLowerCase()
					setdeviceStatusFilterValue([{ value: statusValue, label: status==="n/a" ||  status==="N/A" ? "others" :status }]);
					locationFilters = [
						...locationFilters,
						{ key: 'phone_status', value: [statusValue] },
					];
			}

			if(stateValue){
					setstatusFilter([{ value: stateValue?.toLowerCase(), label: stateValue }]);
					locationFilters = [
						...locationFilters,
						{ key: 'inv_status', value: [stateValue?.toLowerCase()] },
					];
			}

			if (locationFilters && locationFilters?.length > 0) {

				setFilter((val) => {
					let filterValue = [...val, ...locationFilters]
					return filterValue
				})
			}
			getDevicesData(null, null, { filter: JSON.stringify(locationFilters) })
		} else {
			getDevicesData()
		}

	}, [user?.tenant, location]);

	

	const phoneDetails = useMemo(() => {
		const getdetail = (id) => phoneStatus && (phoneStatus?.length > 0 && phoneStatus?.find(item => item?.phone_status === id)) || phoneStatus[id];
		const getPercentage = (id) => {return  phoneStatus[id] ? (phoneStatus[id]*100/totalCount).toFixed(2) : 0}
		const isEnabled = (val) => {
			return deviceStatusFilterValue?.length === 0 || deviceStatusFilterValue?.length >= 0 && deviceStatusFilterValue?.filter(i => i.value === val).length > 0 || deviceStatusFilterValue?.value === val
		}
		
		return [
		{
			id: 'up',
			text: 'Up',
			count: Array.isArray(phoneStatus)? Number(getdetail('up')?.total_count) : getdetail('up'),
			percentage: getdetail('up')?.percentage || getPercentage('up'),
			className: 'link__monitor__card__up',
			isEnabled: isEnabled('up'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['up']
		},
		{
			id: 'down',
			text: 'Down',
			count: Array.isArray(phoneStatus) ? Number(getdetail('down')?.total_count) : getdetail('down'),
			percentage:getdetail('down')?.percentage || getPercentage('down'),
			className: 'link__monitor__card__down',
			isEnabled: isEnabled('down'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['down']
	
		},
		
		{
			id: 'maint',
			text: 'Maint',
			count:  Array.isArray(phoneStatus) ? Number(getdetail('maint')?.total_count) : getdetail('maint'),
			percentage:  getdetail('maint')?.percentage || 0,
			className: 'link__monitor__card__maintenance',
			isEnabled: user?.tenant?.name === 'Sangoma' &&  isEnabled('maint') ? true: false && isEnabled('maint'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['maint']
	
		},
		{
			id: 'others',
			text: 'Others',
			count: Array.isArray(phoneStatus) ?  Number(getdetail('others')?.total_count) : getdetail('others'),
			percentage: getdetail('others')?.percentage || getPercentage('others')|| 0,
			className: 'link__monitor__card__na',
			isEnabled: isEnabled('others'),
			disableClick : location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
			tooltipText:statusTooltipText['N/A']
		},
		]
		}, [user?.tenant, phoneStatus]);

	const onPageChange = (pagination) => {
			getDevicesData(pagination, null, null, true);
	}

	const handleSearch = async (e) => {
		setTableRefreshKey(new Date().getTime())
		if (e.target.value) {
			setSearchTextState(e.target.value);
			await getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder} , e.target.value, null, true);
		}
		else{
			setSearchTextState(null)
			await getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder} , null, null, true)
		}
	};

	// function replaceHistory() {
	// 	navigate(location.pathname, { state: null })
	// }

	const onApplyFilter = (val, fltrValue) => {
		if(searchTextState) {		
			setSearchTextState(null)
		}
		// if (location.state) {
		// 	replaceHistory()
		// }
		setTableRefreshKey(new Date().getTime())
		let cloneFilter = [...filter]

		switch(val){		
			case 'Tenant...':{
				let tenantDatafilter = fltrValue ? fltrValue : tenantFilter?.map(i => { return i.value });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'tenant_id');
				tenantDatafilter?.length > 0 && cloneFilter.push({ value: tenantDatafilter, key: 'tenant_id' });
				break;
			}
			case 'State...': {
				let stfilter = fltrValue ? fltrValue : statusFilter?.map(i => { return i.value.toLowerCase() });

				cloneFilter = cloneFilter?.filter(i => i.key !== 'inv_status');		
				stfilter?.length > 0 && cloneFilter.push({ value: stfilter, key: 'inv_status' }); break;
			}

			case 'Endpoint Status...': {
				let phonefilter = fltrValue ? fltrValue : deviceStatusFilterValue?.map(i => { return i.value.toLowerCase() });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'phone_status');
				phonefilter?.length > 0 && cloneFilter.push({ value: phonefilter, key: 'phone_status' }); break;
			}
			default:
				break;
		
	}
		setFilter(cloneFilter);
		let filterReq = cloneFilter?.length > 0 ? {filter: JSON.stringify(cloneFilter)} :  {filter: null}
		getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, '', filterReq);
	}

	const handleTenantFilter = async (e, action) => {
		setTenantFilter(e);
		if(action === 'clear'){
			onApplyFilter('Tenant...', e);
		}
	};

	const handleStatusFilter = async (e, action) => {
		setstatusFilter(e);
		if(action === 'clear'){
			onApplyFilter('State...', e);
		}
	};


	
	const handleDeviceStatusFilter = async (e, action) => {
		setHighLightFilter(false)
		setdeviceStatusFilterValue(e);
		if(action === 'clear'){
			onApplyFilter('Endpoint Status...', e);
		}
	};

	const handleCardClick = (e,item) => {
		if(searchTextState) {
			setSearchTextState(null)
		}
		setHighLightFilter(true)
		setTableRefreshKey(new Date().getTime())
		setdeviceStatusFilterValue([{ value: item?.id, label: item?.text }]);
					let filterVar = [...filter];
					filterVar = filterVar?.filter(i => i.key !== 'phone_status');
					let locationFilters = [
						...filterVar,
						{ key: 'phone_status', value: [item?.id] }
					];
					setFilter(locationFilters)

					getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, '',{ filter : locationFilters ? JSON.stringify(locationFilters) : null});
	}

	const filterConfigurations = useMemo(() => [
		{
			key: "statusFilter",
			isEnabled: true,
			placeholder: "State...",
			options:filterStatusListData,
			value: statusFilter,
			handler: handleStatusFilter,
			disableApply: (location?.state == null || location?.state?.serviceName == '' || location?.state?.serviceName == 'phone_status' || location?.state?.stateValue) && !isLoading && statusFilter.length > 0 ? false : true,
		},
		{
			key: "tenantFilter",
			isEnabled: user?.tenant?.name === "Sangoma",
			placeholder: "Tenant...",
			options: tenants?.map(i => {return {value: i.id, label: i.name}}),
			value: tenantFilter,
			handler: handleTenantFilter,
			disableApply: (location?.state == null || location?.state?.serviceName == '' || location?.state?.serviceName == 'phone_status' || location?.state?.stateValue) && !isLoading && tenantFilter.length > 0 ? false : true,
		},
		{
			key: "deviceStatusFilter",
			isEnabled: true,
			placeholder: "Endpoint Status...",
			options: user?.tenant?.name !== "Sangoma"
			? StatusFilterList?.filter((item) => item.value !== "maint")
			: StatusFilterList,
			value: deviceStatusFilterValue,
			highlightFilter : highlightFilter,
			handler: handleDeviceStatusFilter,
			disableApply: (location?.state == null || location?.state?.serviceName == '' || location?.state?.serviceName == 'phone_status' || location?.state?.stateValue) && !isLoading && deviceStatusFilterValue.length > 0 ? false : true,
		},
		
	], [filterStatusListData, statusFilter, location?.state, isLoading, user?.tenant?.name, tenants, tenantFilter, deviceStatusFilterValue]);

	const handleRowClicked = (row) => {
		setFlappingHistoryPopupData({ data: row, openPopup: true });
	};
	useInterval(getDevicesData, 60000);
  return (
	<MuiThemedBox className="main_inner_wrap">
		<Box className="justify-between">
			<Breadcrumbs text={`Endpoints`} filterValue={filterText} onRemoveFilter={() => setFilterText(null)} />
		</Box>

		<Grid container className="flex_wrap justify-between">
			<Grid item md={3} className="filter_wrap">
			<SearchInputField debounceProp={user?.tenant?.id} handleClick={handleSearch} userKey={'specialChar'} value={searchTextState} />
			</Grid>
			<Grid item md={9} className="flex flex_Wrap" gap={"9px"} >
				<Box sx={{ display: 'flex', width: "100%", alignItems: "center", justifyContent: 'flex-end' }}>

					<FilterStatusAndService
						style={{ width: "86%", justifyContent: "right", marginRight: "16px" }}
						filterConfigurations={filterConfigurations}
						onApplyClick={onApplyFilter}
						totalFilterCount={user?.tenant?.name === "Sangoma" ? 4 : 3}
						// highlightFilter={highlightFilter}
						// fromDeviceScreen={true}
					/>
				</Box>
			</Grid>

		</Grid>
		<TableHeader tableHeaderCardDetails={phoneDetails} isLoading={isLoading && shouldHeaderLoader} md={user?.tenant?.name === 'Sangoma' ? 3 : 4}
			lg={user?.tenant?.name === 'Sangoma' ? 3 : 4} handleCardClick={handleCardClick} />

		<Box>
			<TableComponent className={"device_table rdt_table_wrap hover_table table_with_header"}
				columns={tableColumns} rows={rows} key={tableRefreshKey}
				customStyles={customStyles}
				isLoading={isLoading}
				onPageChange={onPageChange}
				totalRows={totalCount}
				onRowClicked={handleRowClicked}
				defaultSortOrder={'desc'}
				defaultSortField={'event_time'}/>
		</Box>

		<Suspense fallback={<div></div>}>
			{flappingHistoryPopupData?.openPopup ? (
				<PhoneFlappingHistoryPopup
					openPopup={flappingHistoryPopupData?.openPopup}
					data={flappingHistoryPopupData?.data}
					handleClosePopup={() => {
						setFlappingHistoryPopupData({ data: null, openPopup: false })
					}}
				/>
			) : null}
		</Suspense>


	</MuiThemedBox>
  )
}


const customStyles = {
	tableWrapper: {
		height: "389px",
		color: "#000",
	},
};