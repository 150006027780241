import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;

export const fetchGatewayMonitorData = createAsyncThunk(
	"nfstatus/fetchGatewayMonitorData",
	async (data) => {
		const { tenantId, tenantName, params } = data;
		let url = ``;
		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/gateway/gateway-monitoring/${tenantId}`;
		} else {
			url = `${base_url}/gateway`;
		}
		const response = await axios.get(url, {params: params });
		return response.data;
	}
);

export const fetchGatewayDetails = createAsyncThunk(
	"nfstatus/fetchGatewayDetails",
	async (id) => {
			let url = `${base_url}/gateway/gatewayDetails/${id}`;
		
		const response = await axios.get(url, {gatewayId: id});
		return response.data;
	}
);


export const fetchUnMappedGatewayDetails = createAsyncThunk(
	"nfstatus/fetchUnMappedGatewayDetails",
	async (data) => {
		
		let url = `${base_url}/gateway/gatewayUnmappedDetails`;
		const response = await axios.get(url, {params: data});
		return response.data;
	}
);

export const addFirmwareTag = createAsyncThunk(
	"nfstatus/addFirmwareTag",
	async (data) => {
		let url = `${base_url}/gateway/add-firmware-tag`;
		const response = await axios.post(url, data);
		return response.data;
	}
);

export const updateFirmwareTag = createAsyncThunk(
	"nfstatus/updateFirmwareTag",
	async (data) => {
		let url = `${base_url}/gateway/update-firmware-tag`;
		const response = await axios.put(url, data);
		return response.data;
	}
);

export const getFirmwareTag = createAsyncThunk(
	"nfstatus/getFirmwareTag",
	async (data) => {
		let url = `${base_url}/gateway/get-firware-tag`;
		const response = await axios.get(url, {params: data});
		return response.data;
	}
);

export const deleteFirmwareTag = createAsyncThunk(
	"nfstatus/deleteFirmwareTag",
	async (data) => {
		let url = `${base_url}/gateway/delete-firmware-tag`;
		const response = await axios.delete(url, {params: data});
		return response.data;
	}
);

export const assignFirmwareTag = createAsyncThunk(
	"nfstatus/assignFirmwareTag",
	async (data) => {
		let url = `${base_url}/gateway/assign-firmware-tag`;
		const response = await axios.post(url, data);
		return response.data;
	}
);

export const fetchGatewaySipFilteredDetails = createAsyncThunk(
	"nfstatus/fetchGatewayDetails",
	async (serialId) => {
		let url = `${base_url}/gateway/sip/filteredDetails/${serialId}`;

		const response = await axios.get(url);
		return response.data;
	}
);

export const fetchGatewayPhoneNumbers = createAsyncThunk(
	"nfstatus/fetchGatewayPhoneNumbers",
	async (data, { rejectWithValue }) => {
		let url = `${base_url}/gateway/phoneNumbers`	
		try {
			const response = await axios.get(url, { params: data });
			return response.data
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);