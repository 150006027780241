import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";

function SearchInputField({ handleClick, value, id, userKey, ...props }) {
	const [inputValue, setInputValue] = useState(value || '');

	useEffect(() => {
		setInputValue(value || '');
	}, [value]);

	const handleChange = (e, key) => {
		// eslint-disable-next-line no-useless-escape
		if (e?.target?.value && (key == 'specialChar'?!e?.target?.value?.match(/^[0-9a-zA-Z\s#,.@_:+\-]+$/):!e?.target?.value?.match(/^[0-9a-zA-Z\s#,_-]+$/))) return;
        setInputValue(e.target.value);
		if (handleClick) {
			debouncedHandleSearch(e);
		}
		
	};

	const debouncedHandleSearch = useCallback(
		debounce(handleClick, 1000), [props?.debounceProp]
	);


	return (
		<FormControl className="search_wrap" variant="outlined">
			<OutlinedInput
				startAdornment={
					<InputAdornment position="start">
						<FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
					</InputAdornment>
				}
	
				onChange={(e) => handleChange(e, userKey, props?.key)}
				placeholder="Search..."
				className="search_input"
				value={inputValue}
				id={id}
				inputProps={{
					maxlength: 20,
				}}
				disabled = {props.disable}
			/>
		</FormControl>
	);
}

export default SearchInputField;
