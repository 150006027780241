const encryptPassword = async (password) => {
   try {
    const secretKey = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY; 
    if(!secretKey) {
        return
    }
    const encoder = new TextEncoder();
    const data = encoder.encode(password);
    const hashedKey = await window.crypto.subtle.digest("SHA-256", encoder.encode(secretKey));
    const key = await window.crypto.subtle.importKey(
        "raw",
        hashedKey,
        { name: "AES-GCM" },
        false,
        ["encrypt", "decrypt"]
    );
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encryptedBuffer = await window.crypto.subtle.encrypt(
        { name: "AES-GCM", iv },
        key,
        data
    );

    const encryptedArray = new Uint8Array(encryptedBuffer);
    const ciphertext = encryptedArray.slice(0, encryptedArray.length - 16); 
    const authTag = encryptedArray.slice(encryptedArray.length - 16); // Last 16 bytes are the authTag

    return {
        iv: Array.from(iv), 
        ciphertext: Array.from(ciphertext), 
        authTag: Array.from(authTag), 
    };
   } catch (error) {
    console.error("Something ernt wrong while encrypting password:", error);
   }
};

const decryptPassword = async (encryptedPassword) => {
    try {
        const secretKey = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;    
        if(!secretKey) {
            return
        }
        const encoder = new TextEncoder();
        const hashedKey = await window.crypto.subtle.digest("SHA-256", encoder.encode(secretKey));
        const key = await window.crypto.subtle.importKey(
            "raw",
            hashedKey,
            { name: "AES-GCM" },
            false,
            ["decrypt"]
        );

        const encryptedData = JSON.parse(encryptedPassword);
        const ivArray = new Uint8Array(encryptedData.iv); 
        const ciphertextArray = new Uint8Array(encryptedData.ciphertext); 
        const authTagArray = new Uint8Array(encryptedData.authTag); 

        if (ivArray.length === 0 || ciphertextArray.length === 0 || authTagArray.length === 0) {
            throw new Error("Invalid encrypted data: Missing IV, ciphertext, or authTag.");
        }

        const combinedArray = new Uint8Array(ciphertextArray.length + authTagArray.length);
        combinedArray.set(ciphertextArray, 0); 
        combinedArray.set(authTagArray, ciphertextArray.length); 

        const decryptedBuffer = await window.crypto.subtle.decrypt(
            { 
                name: "AES-GCM", 
                iv: ivArray, 
                tagLength: 128 
            },
            key,
            combinedArray 
        );

        const decoder = new TextDecoder();
        return decoder.decode(decryptedBuffer);

    } catch (error) {
        console.error("Decryption failed:", error);
    }
};

export { encryptPassword, decryptPassword }


