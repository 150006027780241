import { Box } from '@mui/material'
import { MuiTypography } from 'components/common/MuiThemedComponents'
import React from 'react'

const NetopsDashboardTitle = ({ title }) => {
    return (
        <Box className="wrapper__header">
            <MuiTypography className="dashboard__heading">{title}</MuiTypography>
        </Box>
    )
}

export default NetopsDashboardTitle
