/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect, Suspense } from 'react'
import { Box } from '@mui/material';
import TableComponent from 'components/common/TableComponent/TableComponent';
import { useLocation  } from "react-router";
import { MuiThemedBox } from 'components/common/MuiThemedComponents';
import Breadcrumbs from 'components/common/Breadcrumps';
import { columns, ExpandedComponent} from './LinkMonitoring';
import { useDispatch, useSelector } from "react-redux";
import {fetchLinkMonitorData} from "store/devices";
import MinusIcon from "assets/icons/minus.svg";
import PlusIcon from "assets/icons/plus.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServicePopup from 'components/component/createSite/ServicePopup';

export default function LinkMapping() {
    const [isLoading, setIsLoading] = useState(false)
    const user = useSelector((state) => state.user);
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const [tableColumns, setTableColumns] = useState([]);
	const [openServicePopup, setOpenServicePopup] = useState(false);
	const [selectedRowData, setselectedRowData] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [nestedColumns, setNestedColumns] = useState([]);

	const getNestedColumns = (columns) =>{
		let formatedCol = [...columns];
	
		formatedCol.unshift({
		selector: () => <FontAwesomeIcon icon="fa-solid fa-chevron-right" />,
		name: "arrow",
		sortable: false,
		wrap: true,
		isEnabled: true,
			style: {
				minWidth: "38px !important",
				maxWidth: "38px !important",
				marginLeft: '10px'
			},
		})

		return formatedCol

	}

	const onColumnChange= (col) =>{
		let updateColumns = col?.filter(i => i?.isEnabled);
		const columns = getNestedColumns(updateColumns)	
		setNestedColumns(columns)
	}

	const handleServicePopup = (e, data, open) =>{
		e?.stopPropagation();
		setselectedRowData(data); 
		setOpenServicePopup(open);
		setAnchorEl(e?.currentTarget)
	}

	// const navigate = useNavigate();
    const { state } = location || {};

    const fetchLinkData = async() => {
        setIsLoading(true);
        const response = await dispatch(
			fetchLinkMonitorData({
				tenantName: 'Sangoma',
				params: { filter: JSON.stringify([{key: 'netbox_id', value: [state.netboxId]}, {key: 'circuit_id', value: [state.circuitId]}, 
                    {key: 'monitor_device_data.service', value: ["velocloud","meraki","cradlepoint","fortinet"]}])},

			}));
            setRows(response?.payload?.data);
            setIsLoading(false);
    }

    useEffect(() => {
        if (user?.tenant?.name !== 'Sangoma') {
			
			let filteredCol = columns(handleServicePopup)?.filter(data => {
				return data.name !== 'Tenant'
			});
			setTableColumns(filteredCol);
			const nestedColumns = getNestedColumns(filteredCol);
			setNestedColumns(nestedColumns)
        } else {
			const nestedColumns = getNestedColumns(columns(handleServicePopup));
			setNestedColumns(nestedColumns)
			setTableColumns(columns(handleServicePopup))
		}
        fetchLinkData();

		return() =>{
			handleServicePopup(null, null, false)
		}
    },[])	

  return (
    <MuiThemedBox className="main_inner_wrap link__monitoring__dashboard">
    <Breadcrumbs style={{padding: '10px 5px 15px 5px'}}
        secondaryText={"LINK Details"}
        primaryTextPath={"link-monitoring"}
        text={'LINK'}
    />
    <Box >
        <TableComponent
            className="rdt_table_wrap hover_table"
            columns={tableColumns}
            rows={rows}
            isLoading={isLoading}
			onColumnChange={onColumnChange}
        pagination={false}
            expandableRowExpanded={() => {return true}}
            expandableRowsComponent={ExpandedComponent}
            expandableRowsComponentProps={{tableColumns: nestedColumns}}
            expandableRows={columns(handleServicePopup).find((o) => o.name === "Device Name")}
            expandableIcon={{
                collapsed: (
                    <img src={PlusIcon} alt="open" className="expand__icon" />
                ),
                expanded: (
                    <img src={MinusIcon} alt="close" className="expand__icon" />
                ),
            }}
			columnPreferenceStyles={
				{
					position: "sticky",
					float: "right",
					marginLeft: "auto"
				}
			}
			disbaleColumnPreferenceMaxWidth={true}
        />
    </Box>
		<Suspense fallback={<div></div>}>
			{openServicePopup &&
				<ServicePopup
					callingFrom={'link-monitor'}
					rowData={{
						id: selectedRowData?.site_id,
						site_display_name: selectedRowData?.site_display_name,
						name: selectedRowData?.site_name,
						tenant: {
							id: selectedRowData?.tenant_id
						}
					}}
					handleClosePopup={() => handleServicePopup(null, null, false)}
					open={openServicePopup}
					renderPopper={true}
					anchorEl={anchorEl}
				/>
			}
		</Suspense>
</MuiThemedBox>
  )
}
