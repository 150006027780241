import { Box, Typography } from '@mui/material'
import { MuiThemedCircularProgress } from 'components/common/MuiThemedComponents'
import React from 'react'

const NetopsDashboardCountCard = ({ detail, isLoading }) => {
    return (
        <Box className="card">
            <Box className="card__content">
                <Typography variant='p' className="card__title">
                    {detail?.cardTitle}
                </Typography>
                {isLoading ?
                    <MuiThemedCircularProgress />
                    :
                    <Typography variant='p' className="card__count">{detail?.count}</Typography>
                }
            </Box>
        </Box>
    )
}

export default NetopsDashboardCountCard
