import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, FormGroup, IconButton, InputAdornment, InputLabel, Tooltip } from '@mui/material';
import ButtonLayout from 'components/common/Button';
import InputGroup from 'components/common/InputGroupWithLabel';
import FormPopup from 'components/common/modal/FormPopup';
import Header from 'components/common/modal/Header';
import { MuiThemedButton, MuiThemedCircularProgress, ThemedReactSelect } from 'components/common/MuiThemedComponents';
import PasswordField from 'components/common/PasswordField';
import { decryptPassword, encryptPassword } from 'components/common/passwordUtil';
import { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addServiceNowConfigurationDetails, fetchServiceNowAssignmentGroups, fetchServiceNowConfigurationDetails, updateServiceNowConfigurationDetails } from 'store/tickets';
import help from '../../../assets/icons/help.png';
import helpDarkTheme from '../../../assets/icons/help_dark_theme.png'

const formValuesInitialState = {
    username: "",
    instance_url: "",
    password: "",
    assignment_group: null
}

const nameMapper = {
    username: "User Name",
    instance_url: "Instance URL",
    password: "New Password",
    assignment_group: "Assignment Group"
}

const validateUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
}

const ServiceNowConfigurePopup = ({ open, handleClose, tenant_id, ticketing_system_id }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [markError, setMarkError] = useState(false);
    const [isAddingConfigurationDetails, setIsAddingConfigurationDetails] = useState(false);
    const [isFetchingDetails, setIsFetchingDetails] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [enableCredentialsButton, setEnableCredentialsButton] = useState(false);
    const [assignmentGroupData, setAssignmentGroupData] = useState({
        options: [],
        isFetchingData: false,
        isAddingData: false

    });
    const [formValues, setFormValues] = useState({
        username: "",
        instance_url: "",
        password: "",
        assignment_group: null
    });

    const handleClickShowPassword = () => {
        if (!showPassword) {
            setEnableCredentialsButton(true)
            setShowPassword((show) => !show);
            setFormValues((prevFormValues) => ({ ...prevFormValues, password: "", assignment_group: null }));
            setAssignmentGroupData({
                options: [],
                isFetchingData: false,
                isAddingData: false
            })
        }
    };

    const checkIfFormValuesChanged = useCallback((obj1, obj2) => {
        if (!formValues?.assignment_group || !formValues?.instance_url || !formValues?.username || !formValues?.password) {
            return false
        }
        return JSON.stringify(obj1) !== JSON.stringify(obj2);

    }, [formValues])

    const handleFormValues = useCallback((id, value) => {
        if (id === "password" || id === "instance_url" || id === "username") {
            setEnableCredentialsButton(true)
            setFormValues(prevFormValues => ({ ...prevFormValues, assignment_group: null, [id]: value }));
            setAssignmentGroupData({
                options: [],
                isFetchingData: false,
                isAddingData: false
            })

        } else {
            setFormValues(prevFormValues => ({ ...prevFormValues, [id]: value }));

        }

    }, [])

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };


    const fetchAssignmentGroups = useCallback(async (assignmentGroupId, values, displayError = false) => {
        setAssignmentGroupData((prev) => ({ ...prev, isFetchingData: true }))
        try {
            const updatedValue = assignmentGroupId ? values : formValues;
            if (!updatedValue?.instance_url || !updatedValue?.username || !updatedValue?.password) {
                if(displayError) {
                   await  handleValidation()
                }
                return
            }
            if (!tenant_id) {
                toast.error("Something went wrong");
                return
            }
            const { password, instance_url, username } = updatedValue;
            let encryptedPassword = null;
            if (updatedValue?.password) {
                encryptedPassword = await encryptPassword(password);
            }

            const response = await dispatch(fetchServiceNowAssignmentGroups({ tenant_id, username: username, instance_url: instance_url, password: JSON.stringify(encryptedPassword) })).unwrap();

            if (response && response?.length) {
                const options = response?.map((item) => ({ value: item?.sys_id, label: item?.name }));

                if (assignmentGroupId && options?.length) {
                    const preSelectedAssignmnentGroup = options?.find(item => item?.value === assignmentGroupId);
                    setFormValues((prev) => ({ ...prev, assignment_group: preSelectedAssignmnentGroup }))
                }
                setAssignmentGroupData((prev) => ({ ...prev, options }))
            } else {
                setAssignmentGroupData((prev) => ({ ...prev, options: [] }))
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setAssignmentGroupData((prev) => ({ ...prev, isFetchingData: false }))
            setEnableCredentialsButton(false)
        }
    }, [dispatch, formValues, tenant_id])

    const handleValidation = useCallback(() => {
        let hasErrorOccurred = false
        const requiredFields = ['username', 'instance_url', "password"];

        for (const field of requiredFields) {
            const value = formValues[field];
            if (!value) {
                toast.error(`Please enter ${nameMapper[field]}`);
                setMarkError(true);
                hasErrorOccurred = true;
                return hasErrorOccurred

            }

            if (field === "instance_url") {
                const isValidUrl = validateUrl(value);
                if (!isValidUrl) {
                    toast.error("Please enter a valid URL");
                    hasErrorOccurred = true;
                    return hasErrorOccurred

                }
            } else if (field === "password" && value.length < 8) {
                toast.error("Password must be at least 8 characters long");
                hasErrorOccurred = true;
                return hasErrorOccurred
            }
        }

        if (markError) {
            setTimeout(() => {
                setMarkError(false);
            }, 5000);
        }

        return hasErrorOccurred
    }, [formValues, isEditing, markError])


    const handleSubmitConfigurationDetails = useCallback(async (issaveAndAssignBtnClicked) => {
        let encryptedPassword = null;
        let hasErrorOccurred = false;
        setIsAddingConfigurationDetails(true);

        try {
            if (!tenant_id || !ticketing_system_id) {
                toast.error("Something went wrong");
                hasErrorOccurred = true;
                return;
            }

            hasErrorOccurred = await handleValidation();
            if (hasErrorOccurred) {
                return
            }
            const { password, assignment_group, ...restFormValues } = formValues;

            if (formValues?.password) {
                encryptedPassword = await encryptPassword(password);
            }

            const request = {
                tenant_id: tenant_id?.toString(),
                ticketing_system_id,
                password: JSON.stringify(encryptedPassword),
                assignment_group: assignment_group?.value,
                ...restFormValues,
            };

            const response = await dispatch(
                isEditing
                    ? updateServiceNowConfigurationDetails(request)
                    : addServiceNowConfigurationDetails(request)
            ).unwrap();

            if (response?.success) {
                hasErrorOccurred = false
                toast.success(response?.message);
                if (!issaveAndAssignBtnClicked) {
                    handleClose();
                }
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("Something went wrong");
            hasErrorOccurred = true;
        } finally {
            if (!hasErrorOccurred && issaveAndAssignBtnClicked) {
                await fetchAssignmentGroups();
            }
            setIsAddingConfigurationDetails(false);
        }
    }, [dispatch, fetchAssignmentGroups, formValues, handleClose, handleValidation, isEditing, tenant_id, ticketing_system_id]);



    const fetchDetails = useCallback(async () => {
        setIsFetchingDetails(true);
        try {
            if (!tenant_id || !ticketing_system_id) {
                toast.error("Something went wrong");
                return
            }
            const response = await dispatch(fetchServiceNowConfigurationDetails({ tenant_id, ticketing_system_id })).unwrap();
            if (response?.length == 0) {
                setIsEditing(false);

            } else {
                setIsEditing(true)
                if (response && response?.length) {
                    const { username, instance_url, password, assignment_group } = response[0];
                    const decryptedPassword = await decryptPassword(password);
                    const updatedObj = { username, instance_url, assignment_group, password: decryptedPassword }
                    setFormValues(updatedObj);
                    if (assignment_group) {
                        await fetchAssignmentGroups(assignment_group, updatedObj);
                    }
                }
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsFetchingDetails(false)

        }
    }, [dispatch, fetchAssignmentGroups, tenant_id, ticketing_system_id])

    const buttonsList = useMemo(() => {
        return [
            {
                name: "Clear",
                onClick: () => {
                    setShowPassword(true)
                    setFormValues(formValuesInitialState)
                    setAssignmentGroupData({
                        options: [],
                        isFetchingData: false,
                        isAddingData: false
                    })
                },
                variant: 'outlined',
                disabled: false,
                isLoading: false,
                isEnabled: true
            },
            {
                name: isEditing ? 'Update' : 'Save',
                onClick: () => {
                    handleSubmitConfigurationDetails()
                },
                variant: 'filled',
                disabled: !checkIfFormValuesChanged(formValuesInitialState, formValues),
                isLoading: isAddingConfigurationDetails,
                isEnabled: !isFetchingDetails

            }
        ]
    }, [checkIfFormValuesChanged, formValues, isEditing, isFetchingDetails, isAddingConfigurationDetails, handleSubmitConfigurationDetails])


    useEffect(() => {
        fetchDetails();
    }, [])

    return (
        <FormPopup open={open} className={"servicenow__configuration__popup"}>
            <Header text={"Setup ServiceNow"} close={handleClose} />
            <Box sx={{ display: "flex", height: "100%", flexDirection: "column", alignItems: "flex-start", width: "100%", padding: "16px 32px", overflow: "auto",  }} >
                {isFetchingDetails ?
                    <Box sx={{ width: "100%", display: "flex", minHeight: "200px", alignItems: "center", justifyContent: "center" }}>
                        <MuiThemedCircularProgress />
                    </Box>
                    :
                    <Box sx={{ paddingBottom: "16px", width: "100%", display: "flex", flexDirection: "column" }}>

                        <Alert variant="outlined" severity="info" style={{ margin: "15px 0px" }}>
                            Please follow the step-by-step guide in our  <a style={{color: user?.themePreference === "dark" ? "#fff":""}} href="https://sangoma.atlassian.net/wiki/x/NYDN_w" target="_blank" rel="noopener noreferrer">wiki link</a> to set up the required business rules and other configurations
                        </Alert>

                        <FormGroup sx={{ width: "100%", padding: "8px 0px" }} className="form_group_wrap">
                            <InputGroup
                                classname={`input_field ${(formValues.instance_url?.length === 0 || !formValues.instance_url) && markError
                                    ? 'input__required'
                                    : ''
                                    }`}
                                infoHelperText={"Enter the URL of your ServiceNow instance. Example: https://your-instance.service-now.com."}
                                label={nameMapper["instance_url"]}
                                id="instance_url"
                                type="text"
                                value={formValues.instance_url}
                                disable={false}
                                isRequired={true}
                                placeholder="Enter Instance URL"
                                setValues={(e) => {
                                    handleFormValues("instance_url", e?.target?.value, "text");
                                }}
                                handleOnBlur={() => { }}
                            />
                        </FormGroup>

                        <FormGroup sx={{ width: "100%", padding: "8px 0px" }} className="form_group_wrap">
                            <InputGroup
                                classname={`input_field ${(formValues.username?.length === 0 || !formValues.username) && markError
                                    ? 'input__required'
                                    : ''
                                    }`}
                                label={nameMapper["username"]}
                                id="username"
                                type="text"
                                value={formValues.username}
                                infoHelperText={"Enter your ServiceNow username. This is the username you use to log in to your ServiceNow instance."}

                                disable={false}
                                isRequired={true}
                                placeholder="Enter User Name"
                                setValues={(e) => {
                                    handleFormValues("username", e?.target?.value, "text");
                                }}
                                handleOnBlur={() => { }}
                            />
                        </FormGroup>



                        <FormGroup sx={{ width: "100%", padding: "8px 0px" }} className="form_group_wrap">
                            <PasswordField
                                showPassword={showPassword}
                                inputType="password"
                                classname={`input_field ${(formValues.password?.length === 0 || !formValues.password) && markError ? ' input__required' : ''}`}
                                label={(isEditing && showPassword)?  "New Password" :"Password"}
                                id="password"
                                infoHelperText={(isEditing && showPassword) ?"Note: If you don't want to update password and keep the old password. Please close this form and open again if you want to change anything else." :"Enter your ServiceNow password. Ensure your password is correct to authenticate your account. This field is encrypted and will not be visible."}

                                value={formValues.password}
                                disable={(!showPassword && isEditing)}
                                isRequired={true}
                                endAdornment={
                                    (isEditing && !showPassword) ?
                                        (
                                            <Tooltip 
                                            classes={{ popper: `servicenow__configure__popup__tooltip  ${user?.themePreference === "dark" ? "dark__mode" : ""}` }}  
                                           placement="top" title={"Click on this icon only if you have to change the old password"} >
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        onMouseUp={handleMouseUpPassword}
                                                        edge="end"
                                                    >
                                                        <FontAwesomeIcon fontSize="14px" icon="fa-solid fa-pen-to-square" />

                                                    </IconButton>
                                                </InputAdornment>
                                            </Tooltip>
                                        ) : null
                                }
                                setValues={(e) => {
                                    handleFormValues("password", e?.target?.value, "password");
                                }}
                                placeholder="Enter Password"
                            />
                        </FormGroup>


                        <FormGroup sx={{ width: "100%", padding: "8px 0px", }} className="form_group_wrap">

                            <MuiThemedButton
                                variant="outlined"
                                blockUIStyle={{ width: "100%" , alignItems: "center", justifyContent:"flex-end" }}
                                className={user?.themePreference === 'light' ? "button-outlined" : ''}
                                disabled={!enableCredentialsButton}
                                onClick={() => {   
                                    if (!assignmentGroupData?.options?.length) {
                                    fetchAssignmentGroups(null, null, true);
                                }}}
                                sx={{ mr: 1 }}
                                showLoader={assignmentGroupData?.isFetchingData}
                            >
                                Verify Credentials
                            </MuiThemedButton>
                        </FormGroup>


                        <FormGroup sx={{ width: "100%", padding: "8px 0px" }} className="form_group_wrap">
                            <InputLabel sx={{ display: "flex", alignItems: "center" }} className="input_label">
                                {nameMapper["assignment_group"]}
                                 <span className="astrick">*</span> 
                                <StyledTooltip placement="top" title={"Provide the name of the assignment group to which you want tasks or incidents to be assigned. Ensure it matches exactly as configured in your ServiceNow instance."} width="230px">
                                    <img src={user?.themePreference === 'dark' ? helpDarkTheme : help} alt='help' style={{ width: '20px', height: '20px', margin: "0px 4px" }} />
                                </StyledTooltip>

                            </InputLabel>
                            <ThemedReactSelect
                                id="assignment_group"
                                isClearable
                                name="assignment_group"
                                onChange={(val) => {
                                    handleFormValues("assignment_group", val, "dropdown")
                                }}
                                isRequired={true}
                                isDisabled={!(assignmentGroupData?.options?.length > 0)}
                                options={assignmentGroupData?.options}
                                placeholder="Select Assignment Group"
                                value={formValues.assignment_group}
                                classNamePrefix={`${user?.themePreference === 'dark' ? '' : 'react-select '} ${(formValues.assignment_group?.length === 0) && markError ? ' react__select__required' : ''}`}
                                className="react-select-container"
                            />
                        </FormGroup>
                    </Box>

                }
            </Box>


            <Box className="buttonWrap" gap="15px" sx={{ justifyContent: "space-between" }}>
                {buttonsList.map((buttonDetail, index) => {
                    if (buttonDetail?.isEnabled) {
                        return (
                            <ButtonLayout
                                key={`btn-${index}`}
                                handleClick={buttonDetail.onClick}
                                type=""
                                buttonStyleType={buttonDetail.variant}
                                text={buttonDetail.name}
                                isLoading={buttonDetail.isLoading}
                                disabled={buttonDetail.disabled}
                            />
                        );
                    }
                })}
            </Box>
        {/* <AssignmentGroupPopup  /> */}
        </FormPopup>
    )
}



export default ServiceNowConfigurePopup
