/* eslint-disable no-unsafe-optional-chaining */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "components/common/Breadcrumps";
import CopyTextCell from "components/common/CopyTextCell/CopyTextCell";
import FilterStatusAndService from "components/common/FilterStatusAndService";
import GlobalExportButton from "components/common/GlobalExportButton";
import SearchInputField from "components/common/SearchInputField";
import TableComponent from "components/common/TableComponent/TableComponent";
import TableHeader from "components/common/TableHeader/TableHeader";
import TooltipLabel from "components/common/TooltipLabel/TooltipLabel";
import Timer from "components/component/link/Timer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { fetchGatewayMonitorData } from "store/gateway";
import { DeviceCell, StatusFilterList, statusTooltipText } from "../link/LinkMonitoring";


export default function GatewayMonitoring() {
	const user = useSelector((state) => state.user);
	const devices = useSelector((state) => state.devices)
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
    const [tableColumns, setTableColumns] = useState([]);
	const [portMonitoringData, setPortMonitoringData] = useState([]);
	const [tenantOptionsList, setTenantOptionsList] = useState([]);
	const [statusFilter, setstatusFilter] = useState([]);
	const [tenantFilter, setTenantFilter] = useState([]);
    const [serachText, setSearchText] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [filter, setFilter] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [statusData, setStatusData] = useState({});
	const [shouldHeaderLoader, setShouldHeaderLoader] = useState(true)
	const [tableRefreshKey, setTableRefreshKey] = useState(null);
	const [statusOptionsList, setStatusOptionsList] = useState([]);
	const [highlightFilter, setHighLightFilter] = useState(false);
	const [paginationState, setPaginationState] = useState({
		offset: 1, limit: 20, sortField: 'event_time', sortOrder: 'desc'
		})


	const columns = [
		{
			cell: (row) => { return <>{row.tenant_name} &nbsp; {row.is_vip_tenant ? <FontAwesomeIcon className='vip__icon' icon="fa-solid fa-crown" /> : ""}</> },
			name: "Tenant",
			title: "Tenant",
			sortable: true,
			minWidth: 170,
			isEnabled: true,
			sortField: "ndd.tenant_name",

		},
		{
			cell: (row) => 
				<CopyTextCell showWithTooltip={true} text = {row?.site_display_name ? row?.site_display_name : row?.site_name ? row?.site_name : '--'}/>,
			name: "Site Display Name",
			title: "Site Display Name",
			id: "site_display_name",
			sortable: false,
			isEnabled: true,
			minWidth: 200,

		},
		{
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row?.site_name || '--'}/>,
			name: "Site Name",
			title: "Site Name",
			id: "site_name",
			sortable: false,
			isEnabled: false,
			minWidth: 200,

		},
	
		{
			cell: (row) => <DeviceCell row = {row}/>,
			name: "Gateway Name",
			title: "Gateway Name",
			sortable: true,
			sortField: 'ndd.name',
			isEnabled: true,
			minWidth: 220,

		},
		{
			cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.port_number || '--'}/>,
			name: "Port Number",
			title: "Port Number",
			sortField:'mgd.port_number',
			sortable: true,
			isEnabled: true,
			minWidth: 150,


		},
		{
			cell: (row) => <TooltipLabel text = {row.port_type || '--'}/>,
			name: "Port Type",
			title: "Port Type",
			sortField:'mgd.port_type',
			sortable: true,
			minWidth: 170,
			isEnabled: true,
		},
		{
			name: "Status",
			title: "Status",		
			wrap: true,
			isEnabled: true,
			minWidth: 180,
			sortable: false,
			sortField:"mgd.port_state",
			cell: (row) => <span data-tag="allowRowEvents"
			className={
					(row.status?.toLowerCase() || row.port_state) === "up"
					? "status status_active"
					: (row.status?.toLowerCase() || row.port_state) === "down"
						? "status status_down"
						: (row.status?.toLowerCase() || row.port_state) === "n/a" ? 'status status_na' :
							(row.status?.toLowerCase() || row.port_state) === "degraded" ? 'status status_degraded' : row.link_status === null
								? "status status_null"
								: "status status_inactive"
			}
		>
			{row?.status || row.port_state}
		</span>
		},
		{
			cell: (row) => {
				return row.event_time && row.event_time?.toLowerCase() !== "n/a" ? <Timer targetDate={row.event_time || row.event_time} /> : "--";
			},
			name: "Since",
			title: "Since",
			sortable: true,

			minWidth: 200,
			isEnabled: true,
			sortField: "event_time"
		}
	];


	const setPaginationData = (newState) => {
		setPaginationState(prevState => ({ ...prevState, ...newState }))
	}

	useEffect(() => {
		if (user?.tenant?.name !== 'Sangoma') {
			let filteredCol = columns?.filter(data => {
				return data.name !== 'Tenant'
			});
			setTableColumns(filteredCol);
			setStatusOptionsList(StatusFilterList?.filter(item => item.value !== 'maint'))
		} else {
			setTableColumns(columns);
			setStatusOptionsList(StatusFilterList)
		}
		// On change of tenant view, still tenant column is visible
		setTableRefreshKey(new Date().getTime() + Math.random())
	}, [user]);

	useEffect(() => {
		setSearchText(null)
		setstatusFilter([]);
		setTenantFilter([]);
		setFilter([])
		let locationFilters = []
		if (!user.tenant) {
			return;
		}

		const { state } = location || {};
		if (!(location?.state?.callingFrom === 'sitePopup')) {
			// If filters are coming from redirection
			if (state) {
				const { status, inv_status } = state;
				if (status) {
					let statusValue = status === "n/a" || status === "N/A" ? "N/A" : status?.toLowerCase()
					setstatusFilter([{ value: statusValue, label: status === "n/a" || status === "N/A" ? "others" : status }]);
					locationFilters = [
						...locationFilters,
						{ key: 'mgd.port_state', value: [statusValue] },
						{ key: 'mgd.port_state', value: [statusValue] },
					];
				}

				if (inv_status) {
					locationFilters = [
						...locationFilters,
						{ key: 'ndd.inv_status', value: [inv_status] }
					];
				}

			} else {
				// Setting default filters for status
				const statusFilterValue = StatusFilterList?.filter(item => {
					const isNotMaint = item.value !== 'maint';
					const isNotAllForSangoma = user?.tenant?.name !== "Sangoma" || item.value !== 'all';
					return isNotMaint && isNotAllForSangoma;
				});
				setstatusFilter(statusFilterValue);
				locationFilters = [
					...locationFilters,
					{ key: 'mgd.port_state', value: ["up", "down", "degraded", "N/A"] },
				];
			}
			if (locationFilters && locationFilters?.length > 0) {
				setFilter((val) => [...val, ...locationFilters])
			}
		}

		getPortData(null, '', locationFilters ? JSON.stringify(locationFilters) : null);
		
	}, [user.tenant]);

	useEffect(() => {
		let tenantsList = []
		devices?.tenants?.forEach((tenant) => {
			if(tenant?.custom_fields?.gateway_enabled) {
				tenantsList?.push({
					value: tenant.id,
					label: tenant.name,
				})
			}
		})
		tenantsList = tenantsList?.filter(item => item?.label?.toLowerCase() !== 'sangoma')
		let allTenantOption = {
			label: 'All', value: 'all'
		};
		if (tenantsList && tenantsList?.length > 0) {
			const updatedTenantArray = [allTenantOption, ...tenantsList?.map(item => { return { label: item?.label, value: item?.value } })];
			setTenantOptionsList(updatedTenantArray);
		}

	}, [devices])


	function replaceHistory() {
		navigate(location.pathname, { state: null })
	}

	const getFilterRequest = useCallback((pagination, searchText, filters) =>{
		// debugger
		const {state} = location || {};
		
		const searchReq = {
			searchText: searchText ===''? searchText : searchText ? searchText : serachText?.length > 0 ? serachText : null, 
		}
		const paginationReq = pagination ? { ...pagination } : { ...paginationState };
		const filterReq = {
			filter: filters ? filters : serachText || searchText? [] :filter ? JSON.stringify(filter) : null
		}
		const siteIdReq = {
			siteId: state?.callingFrom === "sitePopup"? state?.siteId : null,
			siteTenantId:state?.callingFrom === "sitePopup" ? state?.tenantId : null
		}
		
		const request = {
			tenantId: user?.tenant?.id,
			tenantName: user?.tenant?.name,
			params: { ...searchReq, ...paginationReq, ...filterReq, ...siteIdReq,  },
			

		}
		return request || {}

	},[filter, location, paginationState, serachText, user?.tenant?.id, user?.tenant?.name])


	const getPortData =  useCallback(async (pagination, searchText, filters, pageChange) => {
		if (pagination) {
			setPaginationData({
				offset: pagination?.offset, limit: pagination?.limit, sortField: pagination?.sortField,
				sortOrder: pagination?.sortOrder
			})
		}
		setShouldHeaderLoader(!pageChange)
		setIsLoading(true);
		const req = await getFilterRequest(pagination, searchText, filters);
		try {
			const response = await dispatch(fetchGatewayMonitorData(req)).unwrap()
			if (response) {
				!pageChange && setStatusData(response?.headerData)
				const { data, count, headerData } = response;
				setTotalCount(count || 0);
				setPortMonitoringData(data || []);
				setStatusData(headerData)

			}
		} catch (error) {
			console.error("Error fetching tenants data:", error);
		} finally {
			setIsLoading(false);
			setShouldHeaderLoader(false) 
		}
	},[dispatch, getFilterRequest])

	const handleSearch = async (e) => {
		setSearchText(e.target.value);
		await getPortData(null, e.target.value, null,  true);
		await getPortData(null, e.target.value, null,  true);
	}

	// Filter functions
	const handleStatusFilter = async (e, action) => {
		setstatusFilter(e);
		setHighLightFilter(false)
		if (action === 'clear') {
			onApplyClick('Status...', e);
		}
	};

	const handleTenantFilter = async (e, action) => {
		setTenantFilter(e);``
		if (action === 'clear') {
			onApplyClick('Tenant...', e);
		}
	};


	const updateFilter = (key, val, filterType, addValue) => {
		// TODO: Clear the filters in location state that are applied on redirection when the filters are changed

		if (location.state) {
			replaceHistory()
		}
		const filterValue = val ? addValue ? val:[] : [...filterType];
		let value = filterValue.some(obj => obj.value?.toString()?.toLowerCase() === ('all')) ? filterValue?.filter(i => i.value !== 'all')?.map(i => i.value) : filterValue?.map(i => i.value);
		if(key ==="mgd.port_state" && user?.tenant?.name !== 'Sangoma') {
			value = value?.filter(i => i.value !== 'maint')
		}
		let cloneFilter = [...filter];

		cloneFilter = cloneFilter?.filter(i => i.key !== key);
		if (value?.length > 0) {
			cloneFilter.push({ value: value, key: key });
		}

		setFilter(cloneFilter);

		const filterReq = cloneFilter?.length > 0 ? JSON.stringify(cloneFilter) : [];
		getPortData(null, null, filterReq);
	};

	const onApplyClick = (key, val) => {
		switch (key) {
			case 'Status...':
				updateFilter('mgd.port_state', val, statusFilter);
				break;
			case 'Tenant...':
				updateFilter('ndd.tenant_id', val, tenantFilter);
				break;
			default:
				break
		}
	};


	const handleCardClick = (e, item) => {
		const selectedItem = [{ value: item?.id, label: item?.text }]
		setHighLightFilter(true)
		setstatusFilter(selectedItem);
		updateFilter('mgd.port_state', selectedItem, statusFilter, true);
	}

	const onPageChange = (pagination) => {
		getPortData(pagination, null, null, true);
	}

	const portDetails = useMemo(() => {
		const statusValueFromFilter = filter?.find(item => item?.key === 'mgd.port_state');
		const updatedStatusFilter = statusValueFromFilter ? statusValueFromFilter?.value : [];
		
		const getdetail = (id) => statusData && statusData?.length > 0 && statusData?.find(item => item?.status === id);
		const isEnabled = (val) => {return  updatedStatusFilter?.length === 0 || updatedStatusFilter?.includes(val)};
		

		return [
			{
				id: 'up',
				text: 'Up',
				count: getdetail('up')?.total_count || 0,
				percentage: getdetail('up')?.percentage || 0,
				className: 'link__monitor__card__up',
				isEnabled: isEnabled('up'),
				tooltipText:statusTooltipText['up']
			},
			{
				id: 'down',
				text: 'Down',
				count: getdetail('down')?.total_count || 0,
				percentage: getdetail('down')?.percentage || 0,
				className: 'link__monitor__card__down',
				isEnabled:isEnabled('down'),
				tooltipText:statusTooltipText['down']
			}, 
			{
				id: 'degraded',
				text: 'Degraded',
				count: getdetail('degraded')?.total_count || 0,
				percentage: getdetail('degraded')?.percentage || 0,
				className: 'link__monitor__card__degraded',
				isEnabled:isEnabled('degraded'),
				tooltipText:statusTooltipText['degraded']
				
			},
			{
				id: 'maint',
				text: 'Maint',
				count: getdetail('maint')?.total_count || 0,
				percentage: getdetail('maint')?.percentage || 0,
				className: 'link__monitor__card__maintenance',
				isEnabled: user?.tenant?.name === 'Sangoma' && isEnabled('maint'),
				tooltipText:statusTooltipText['maint']
			},
			{
				id: 'N/A',
				text: 'Others',
				count: getdetail('N/A')?.total_count || 0,
				percentage: getdetail('N/A')?.percentage || 0,
				className: 'link__monitor__card__na',
				isEnabled:isEnabled('N/A'),
				tooltipText:statusTooltipText['N/A']	
			},
		]
	}, [filter, user?.tenant?.name, statusData]);

	const gridLengthForHeaderCards = useMemo(() => {
		const portCardLength = portDetails && portDetails?.length > 0 ? (12 / portDetails?.filter(item => item?.isEnabled)?.length) : 0;		
		return portCardLength > 3 || portCardLength === 0 ? 3: portCardLength
		
	},[portDetails])

	const enableFilteredExportBtn = useMemo(() => {
		const removedExternalFromFilter = (filter && filter?.length > 0) || serachText?.length > 0 || location?.state?.callingFrom === 'sitePopup'
		return removedExternalFromFilter
	}, [filter, location?.state?.callingFrom, serachText?.length])

	const getExportApiRequest = useMemo(()=>{
		return	{
			tenantId: user.tenant?.name !== "Sangoma" ? user.tenant?.id : null,
			filetype: 'csv',
			dataOf: 'port_monitoring',
			siteId: location?.state?.callingFrom === 'sitePopup' ? location?.state?.siteId : null,
			tenantName: location?.state?.callingFrom === 'sitePopup' ? user?.tenant?.name : null,
			...getFilterRequest({
				offset: 1,
				limit: totalCount,
				sortField: paginationState?.sortField || 'event_time',
				sortOrder: paginationState?.sortOrder || 'desc'
			})
		}
	},[getFilterRequest, location?.state?.callingFrom, location?.state?.siteId, paginationState?.sortField, paginationState?.sortOrder, totalCount, user.tenant?.id, user.tenant?.name])

	return (
		<Box className="main_inner_wrap port__monitoring__dashboard">
			<Breadcrumbs text={`Ports Monitoring`} />
			<Grid container className="flex_wrap justify-between">
				<Grid item md={3} className="filter_wrap">
					<SearchInputField debounceProp={filter} key={filter + user?.tenant?.id + statusFilter?.length} handleClick={handleSearch} value={serachText} />

				</Grid>
				<Grid item md={9} className="flex flex_Wrap">
					<FilterStatusAndService
						highlightFilter={highlightFilter}
						style={{ width: "94%", justifyContent: "right" }}
						StatusFilterList={StatusFilterList}
						handleStatusFilter={handleStatusFilter}
						handleTenantFilter={handleTenantFilter}
						statusList={statusOptionsList}
						statusName="Status..."
						isStatusFiltrer={true}
						isTenantFilter={user?.tenant?.name == 'Sangoma' ? true : false}
						tenantNameList={tenantOptionsList}
						tenantFilterValue={tenantFilter}
						statusFilterValue={statusFilter}
						onApplyClick={onApplyClick}
						disableStatusApply={!(!isLoading && statusFilter.length > 0)}
						disableApply={!(!isLoading && tenantFilter.length > 0)}
						totalFilterCount={2}
					/>
					<GlobalExportButton
						enableFilteredDataExportButton={enableFilteredExportBtn}
						exportApiRequest={getExportApiRequest}
						heading={`Port Monitoring`}
						text={"Are you sure you want to download port monitoring list ?"}
						fileName={"port_monitoring"}
					/>
				</Grid>
			</Grid>
			<Grid>
				<TableHeader
					tableHeaderCardDetails={portDetails}
					isLoading={isLoading && shouldHeaderLoader}
					md={gridLengthForHeaderCards}
					lg={gridLengthForHeaderCards}
					handleCardClick={handleCardClick} />
			</Grid>
			<Box>
				<TableComponent
					className="rdt_table_wrap ticket_table_wrap link_monitoring_table"
					columns={tableColumns}
					data={portMonitoringData}
					defaultSortOrder={'desc'}
					defaultSortField={'event_time'}
					onPageChange={onPageChange}
					isLoading={isLoading}
					totalRows={totalCount}
					key={tableRefreshKey}
				/>
			</Box>
		</Box>
	);

	
}

