import React, {useEffect, useRef, useState} from 'react'
import DataTable, { createTheme } from "react-data-table-component";
import ProgressLoading from "components/common/ProgressLoading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './TableComponent.css';
import Popover from '@mui/material/Popover';
import { ClickAwayListener } from '@mui/base';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TooltipLabel from '../TooltipLabel/TooltipLabel';
import { useSelector } from 'react-redux';
import { ReactDataTableStyles } from '../MuiThemedComponents';


const TableComponent = ({columns, rows, customStyles, isLoading, onRowClicked, className,defaultSortOrder, ...props}) => {
  const user = useSelector((state) => state.user);
    const [currentLimit, setCurrentLimit] = useState(20)
    // eslint-disable-next-line no-unused-vars
    const [currentOffset, setCurrentOffset] = useState(props?.offset || 1);
    const [currentSort, setCurrentSort] = useState({
      sortField: props?.defaultSortField ? props?.defaultSortField : 'name',
      sortOrder: defaultSortOrder ? defaultSortOrder :'asc'
    })
    const [columnState, setColumnState] = useState(columns);   
    const [openColumn, setOpenColumn] = useState(false); 
    const [columnWidths, setColumnWidths] = useState({});
    const [resizing, setresizing] = useState(false);
    const [sortClicked, setSortClicked] =useState(false);
    const [isDragging, setIsDragging] = useState(false); 

    // useEffect(() => {
    //     const element = document.getElementsByClassName('mapped_table');
    //     element[0].addEventListener("scroll", function(e){
    //       const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    //       if (bottom && rows?.length <= props?.totalRows) {
    //         props?.onPageChange({offset: currentOffset + 1, limit: 20});
    //       }
    //   });
    // },[])
    const [refreshKey, setRefreshKey] = useState(Math.random() + new Date())

    useEffect(() => {
      setColumnState(columns);
      columResizer(columns);
    }, [props.colPreferenceKey])

    
    const updateColState = (col) => {
        setColumnState(col);
        setRefreshKey(Math.random() + new Date());
        setOpenColumn(true)
        props?.onColumnChange && props?.onColumnChange(col)
    }

    useEffect(() => {
      if(!sortClicked){
      setColumnWidths(columns.reduce((acc, column) => {
        
        acc[column.title] = column.minWidth ?  column.minWidth : 100; // Default width
        return acc;
      }, {}));
    }
    }, [columns]);


    useEffect(() => {
      columResizer();
    },[columnWidths])


    const columResizer = (columsParams) => {
      columsParams = columsParams ? columsParams : columnState;
      const columnsWithResizer = (columsParams.length > 0 ? columsParams : columns).map((column) => {
        return ({
          ...column,
          minWidth:`${columnWidths[column.title]}px !important`,
          isEnabled: columsParams.find(i => i.title === column.title)?.isEnabled || column?.isEnabled,
          name: (
            <div className="resizable-header" onClick={()=>{setSortClicked(true)} } >
              {column.name}
              <div className="resizer" onMouseDown={(e) => {handleMouseDown(e, column)} } 
              onMouseLeave={(e) => { 
                handleMouseLeave(e)}} onMouseUp={() => {setresizing(false);   setIsDragging(false);}} />
            </div>
          )
        }
          )
      }
    )
    setColumnState(columnsWithResizer);
    props?.onColumnChange && props?.onColumnChange(columnsWithResizer);
    }


  useEffect(() => {
    const handleDivClick = (event) => {
      const targetDiv = event.target.closest('div[role="columnheader"][data-column-id]');
      if (targetDiv) {
        setSortClicked(true)
      }
    };

    document.addEventListener('click', handleDivClick);
    return () => {
      document.removeEventListener('click', handleDivClick);
    };
  }, []);

    const handleMouseDown = (e, column) => {
      e.preventDefault();
      const startX = e.clientX;
      const startWidth = columnWidths[column.title];
      const onMouseMove = (e) => {
        const newWidth = startWidth + e.clientX - startX;
        setColumnWidths((prevWidths) => ({
          ...prevWidths,
          [column.title]: newWidth > 100 ? newWidth : 100, // Minimum width of 100px
        }));
      };
      const onMouseUp = () => {
        e.stopPropagation();
        setresizing(true);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        setTimeout(() => setresizing(false), 50);
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
      e.stopPropagation(); 
      setresizing(true); 
      setIsDragging(true);
    };

    const handleMouseLeave = (e) =>{
      if (isDragging) {
        return; 
      }
      if (resizing) {
        setresizing(false);
        setIsDragging(false);  
        e.stopPropagation();
    }
    }
  
    const customHeaderStyles = {
      headCells: {
        style: (column) => ({
          minWidth: columnWidths[column.name],
          maxWidth: columnWidths[column.name],
          position: 'relative',
          pointerEvents: resizing ? "none" : "auto",
        }),
      },
      cells: {
        style: (column) => ({
          minWidth: columnWidths[column.name],
          maxWidth: columnWidths[column.name],
        }),
      },
    };


    const setCurrentSortState = (newState) => {
      setCurrentSort((prevState) => ({...prevState, ...newState}))
    }
  const onChangePage = (offset) => {
    if (sortClicked && offset == 1) {
      setSortClicked(false)
      return
    }

    setCurrentOffset(offset)

    props?.onPageChange && props?.onPageChange({ offset: offset, limit: currentLimit, ...currentSort })
  }
  const onChangeRowsPerPage = (limit, offset) => {
    setCurrentLimit(limit)
    setTimeout(() => {
      props?.onPageChange && props?.onPageChange({ offset: offset, limit: limit, ...currentSort })
      setSortClicked(false)
    }, 10);

  }
    const onSortChange = (column, direction) => {
      if(!resizing){
        if(props?.customSortFunction) {
          props?.customSortFunction(column, direction, rows)
        }
       setCurrentSortState({sortField: column?.sortField ? column.sortField : column.title?.toLowerCase(), sortOrder: direction})
       let pagination = {offset: 1, limit: 20, sortField:  column?.sortField ? column.sortField : column.title?.toLowerCase(), sortOrder: direction}
       props?.onPageChange && props?.onPageChange(pagination)}
       setSortClicked(false)

    }

  let prefrenceCell = props?.removePreferenceCell ? [] : [
    {
      cell: () => null,
      name: <ColumnPrefrerenceComponent columns={columnState} updateColState={updateColState} openColumn={openColumn} setOpenColumn={setOpenColumn}  />,
      center : true,
      isEnabled: true,
      minWidth: '50px !important',
      right: true,
      maxWidth: props?.disbaleColumnPreferenceMaxWidth ? '':'50px !important',
      style:{
        ...props?.columnPreferenceStyles
      },
      id: "col_prefrence",
    }]


  createTheme('table__dark__theme',
    ReactDataTableStyles
  );
   
  return (
    <>
    
    <DataTable 
        className={`rdt_table_wrap hover_table ${user?.themePreference === 'dark' ? 'rdt__table__darkMode' : ''} ${className}`}
        columns={[...columnState.filter(i => i.isEnabled), ...prefrenceCell]}
        data={rows}
        pagination = {props?.pagination || true}
        fixedHeader
        customStyles={{...customStyles,...customHeaderStyles}}
        progressPending={isLoading ? true : false}
        progressComponent={<ProgressLoading />}
        paginationRowsPerPageOptions={[20, 50, 100, 200, 300, 500]}
        onRowClicked={onRowClicked}
        paginationPerPage={currentLimit}
        onChangePage = {onChangePage}
        onSort={onSortChange}
        paginationServer = {props?.onPageChange ? true : false}
        key = {refreshKey || props.keyId}
        paginationTotalRows = {props?.totalRows}
        onChangeRowsPerPage = {onChangeRowsPerPage}
        {...props}
        sortServer = {props?.onPageChange ? true : false}
        defaultSortAsc={props?.defaultSortAsc || false}
        theme={user?.themePreference === 'dark' ?"table__dark__theme": null}
        
    />
    </>
  )
}

const ColumnPrefrerenceComponent = ({columns, updateColState, setOpenColumn, openColumn}) => {
  const [columnOption, setColumnOption] = useState([]);
  const columnPreferenceRef = useRef(null);

  useEffect(() => {
    setColumnOption(columns);
  }, [columns])

  const handleColumnPopup = (open) => {
    setOpenColumn(open);
  }

  const handleColumnClick = (e, col) => {
    e.stopPropagation();
    e.preventDefault();
    columns = columns.map(x => (x.title === col?.title ? { ...x, isEnabled: e.target.checked} : x));
      updateColState(columns)
  }


  return(


    <>
      <ClickAwayListener mouseEvent='onClick' onClickAway={(e) => {
        e?.stopPropagation();
        handleColumnPopup(false);
      }} >

        <div ref={columnPreferenceRef} className='columnIconWrap' onClick={(e) => {
          handleColumnPopup(true, e)
        }}>
          <FontAwesomeIcon icon="fa-solid fa-columns fa-lg" className='column__icon' />
        </div>
      </ClickAwayListener>


      <Popover open={openColumn} anchorEl={columnPreferenceRef?.current} className={"column__preference__popover"}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <div className='column__wrap' onClick={(e) => e.stopPropagation()}  >
          {columnOption?.map((col) => {
            return (
              <FormControlLabel key={col?.title}
                control={<Checkbox disabled ={col.title == "Ticket Number"}  checked={col?.isEnabled} onChange={e => {
                  e?.stopPropagation()
                  handleColumnClick(e, col)
                }} />}
                label={<TooltipLabel text={col?.title} />} />
            )
          })}
        </div>
      </Popover>

    </>

  )
}


export default TableComponent