import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import ActionMenu from "components/common/ActionMenu";
import Breadcrumbs from "components/common/Breadcrumps";
import ButtonLayout from "components/common/Button";
import { TableTypes } from "constant/TableColTypes";
import { Suspense, lazy, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delteWebMonitorData, fetchWebMonitorData} from "store/devices";
import TableComponent from "components/common/TableComponent/TableComponent";
import { MuiThemedBox, } from "components/common/MuiThemedComponents";
import SearchInputField from "components/common/SearchInputField";
import { Alert, Grid } from "@mui/material";
import { toast } from "react-toastify";
import DeleteConfirmPopup from "components/component/userManagement/DeleteConfirmPopup";
import axios from "axios";
import TooltipLabel from "components/common/TooltipLabel/TooltipLabel";
import MultiSelectDropDown from "components/common/MultiSelectDropDown";
import ActionComponent from "components/common/ActionComponent";
import { useCanAccess } from "services/canACLAccess";

const FormPopup = lazy(() => import('components/common/modal/FormPopup'));
const CreateWebMonitor = lazy(() => import('components/component/CreateWebMonitor/CreateWebMonitor'));

const customStyles = {
	tableWrapper: {
		overflowX: "unset",
		overflowY: "unset",
	},
};

const WebMonitor = () => {
	const [tableColumns, setTableColumns] = useState([]);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
    const tenantsData = useSelector((state) => state.devices);
	const [formParams, setformParams] = useState([]);
	const [webMonitor, setWebMonitor] = useState([]);
	const [openWebmonitor, setopenWebmonitor] = useState(false);
	const [editData, seteditData] = useState(undefined);
	const [openMenuList, setopenMenuList] = useState(null);
    const [openDeleteConfimation, setOpenDeleteConfimation] = useState(false);
	const [tenantOptionsList, setTenantOptionsList] = useState([]);
	const [tenantFilter, setTenantFilter] = useState([]);
	const [searchTextState, setSearchTextState] = useState(null);
	const [refreshKey, setRefreshKey] = useState(null);
	const [filter, setFilter] = useState([]);
	const [paginationState, setPaginationState] = useState({
		offset: 1, limit: 20, sortField: 'name', sortOrder: 'asc'
	})
	const tenantValue = useRef([]);
	const [tableRefreshKey, setTableRefreshKey] = useState(null);
	const open = Boolean(openMenuList);

	const [isLoading, setIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [corsChecker, setCorsChecker] = useState(true);
	const [chromeCheck, setChromeCheck] = useState(false);
	const [checkLastUrlStatus, setCheckLastUrlStatus] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const searchAbort = useRef(true);
	const abortController = new AbortController();
	const { signal } = abortController;
	const canAccess = useCanAccess();

	const setPaginationData = (newState) => {
		setPaginationState(prevState => ({ ...prevState, ...newState }))
	}

	const checkCors = async () => {
		try {
			await axios?.get('https://sangoma.com');
			await axios?.get('https://google.com');
			setCorsChecker(true);
			return true;
		} catch (error) {
			setCorsChecker(false);
			setCheckLastUrlStatus(false)
			return false
		}
	};

	const fetchData = async () => {
		let tenants = tenantsData?.tenants?.filter(data => data?.name !== "Sangoma");
		tenantValue.current = tenants; 
		getWebMonitorData();
		
	};

	const isChrome = () => {
		const userAgent = navigator?.userAgent?.toLowerCase();
		return userAgent?.includes('chrome') && !userAgent?.includes('edg');
	};

	useEffect(() => {
		setChromeCheck(isChrome());
		// setCheckLastUrlStatus(true);
		setTenantFilter([]);
		setFilter([]);
		if (!user.tenant) {
			return;
		}
		// fetchData();
		let tenantsList = (tenantsData?.tenants || [])?.map((tenant) => {
			return {
				value: tenant.id,
				label: tenant.name,
			}
		})
		tenantsList = (tenantsList || [])?.filter(item => item?.label?.toLowerCase() !== 'sangoma')
		let allTenantOption = {
			label: 'All', value: 'all'
		};
		if (tenantsList && tenantsList?.length > 0) {
			const updatedTenantArray = [allTenantOption, ...tenantsList.map(item => { return { label: item?.label, value: item?.value } })];
			setTenantOptionsList(updatedTenantArray);
		}
	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.tenant?.id, dispatch, tenantsData, searchTextState]);

	useLayoutEffect(()=>{
		if (!user.tenant) {
			return;
		}
		fetchData();
		return () => {	
			if (searchAbort.current) {	
				abortController.abort();	
			}else{
				searchAbort.current = true;
			}
		}
	}, [user?.tenant?.id, searchTextState])

	const handleActionMenuClose = () => {
		setopenMenuList(null);
	};

	const handleClick = () => {
		setopenWebmonitor(true);
		seteditData(undefined);
	};

	const handleRefreshUrl = () =>{
		getWebMonitorData(null, null, null, true);
	}

	const handleClose = () => {
		setopenWebmonitor(false);
		seteditData(undefined);
	};

	const handleActionMenuOpen = (e, row) => {
		setopenMenuList(e.currentTarget);
		seteditData(row);
	}
	

	const getUrlStatusCheck = async (url) => {
		try {
			const resp = await axios.get(url, { timeout: 5000 ,  signal });
			if(resp.status === 200) return 'Up'
			else return 'Down'
		} catch (err) {
				return 'Down';
		}

		
	}

	const getWebMonitorData = async (pagination, searchText, fltr, refresh = false) => {
		try {
			if (refresh) {
				setShowLoader(true)
			}
			setIsLoading(true);
			if (pagination) {
				setPaginationData({
					offset: pagination?.offset, limit: pagination?.limit, sortField: pagination?.sortField,
					sortOrder: pagination?.sortOrder
				})
			}
			let filterReq = fltr ? fltr : filter?.length > 0 ? { filter: JSON.stringify(filter) } : null;

			let req = pagination ? { ...pagination, searchText: searchText ? searchText : searchTextState, ...filterReq } : { ...paginationState, searchText: searchText ? searchText : searchTextState, ...filterReq };
			let request = { tenantId: user?.tenant?.id, tenantName: user?.tenant?.name, ...req }
			const isCorsEnabled = await checkCors();
			const webMonitorData = await dispatch(fetchWebMonitorData(request)).unwrap();
			setWebMonitor(await formatData(webMonitorData?.data, isCorsEnabled));
			setTotalCount(webMonitorData?.count);
			setIsLoading(false);
			if (isCorsEnabled) {
				setCheckLastUrlStatus(true)
				setWebMonitor(await getRows(webMonitorData?.data));
				setRefreshKey(Math.random() + new Date)
			}
		} catch (error) {
			console.error(error);
			toast.error("Oops! Something went wrong");
		} finally {
			setIsLoading(false);
			setShowLoader(false)
		}		
	};

	const handleSearch = async (e) => {
		searchAbort.current = false;
		setSearchTextState(e.target.value)
		await getWebMonitorData(null, e.target.value);
	};

	const getTenantList = async (id) => {
		if (Array.isArray(tenantValue.current)) {
			let tenant = tenantValue.current?.filter(data => data?.id == id)
			return tenant[0]?.name
		}
	};

	
	const onChangeTenantFilter = async (e, action) => {
		setTenantFilter(e);
		if(action === 'clear'){
			onApplyClick('tenant_id', e);
		}
	};

	const onApplyClick = (key, val) => {
		const filterValue =  val ? [] : [...tenantFilter];
		const value = filterValue.some(obj => obj.value?.toString()?.toLowerCase() ===('all')) ? ['all'] : filterValue?.map(i => i.value);
		let cloneFilter = [...filter];
		cloneFilter = cloneFilter?.filter(i => i.key !== 'tenant_id');
		if (value?.length > 0) {
			cloneFilter.push({ value: value, key: 'tenant_id' });
		}

		setFilter(cloneFilter);
		let filterReq = cloneFilter?.length > 0 ? {filter: JSON.stringify(cloneFilter)} :  {filter: null}
		getWebMonitorData(null, null, filterReq);
	};

	const getRows =async (dataList) =>{
        let rows = [];
        for (let i = 0; i < dataList?.length; i++) {
            const row = dataList[i];
            let data = {
                tenant: await getTenantList(row.tenant_id),
                name: row.name,
                description: row.description,
                url: row.url,
                status: <div className="loader"></div>,
            };
            data.action = <ActionComponent disableEdit={!canAccess( 'update', 'web-monitor')} disabledropDown={!canAccess( 'delete', 'web-monitor')} onDropdownClick={handleActionMenuOpen} row={row} onEditClick = {openEditForm}/>
            rows.push(data);
        }
    
        setWebMonitor(rows);

		for (let i = 0; i < dataList?.length; i++) {
            const row = dataList[i];
            let status = <div className="loader"></div>;
		
            try {
                const result = await getUrlStatusCheck(row.url);
                status = (
                    <div className={
                        result.toLowerCase() === "up"
                            ? "status status_active"
                            : result.toLowerCase() === "down"
                                ? "status status_down"
                                : "status status_inactive"
                    }>{result}</div>
                );
            } catch (error) {
                status = "Failed to fetch";
            } finally {
				if(i === (dataList.length-1)) {
					setCheckLastUrlStatus(false)
				}
			}
    
            rows[i].status = status;
            setWebMonitor([...rows]);
        }
		
    
        return rows;
    };
    

	const formatData = useCallback (async (dataList, isCorsEnabled) => {
		let rows = await Promise.all(dataList?.map(async (row) => {
			let data = {
				tenant: await getTenantList(row?.tenant_id),
				name: row?.name,
				description: row?.description,
				url: row?.url,
				status: !isCorsEnabled ? <div className= {"status status_down"} ><TooltipLabel text = "CORS Plugin Not Present"></TooltipLabel></div> : <div className="loader"></div>
			};
			data.action = <ActionComponent disableEdit={!canAccess( 'update', 'web-monitor')} disabledropDown={!canAccess( 'delete', 'web-monitor')} onDropdownClick={handleActionMenuOpen} row={row} onEditClick = {openEditForm}/>
			return data;
		}));

		return rows;
	},[user?.tenant]);


	useEffect(() => {
		if (TableTypes?.length) {
			let tableCols = TableTypes?.find((obj) => obj?.category === "webmonitor");
			let filterCols = tableCols?.columns?.filter((obj) => user?.tenant?.name !== "Sangoma" ? obj?.name !== "tenant" : obj);
			setTableRefreshKey(new Date().getTime())
			setTableColumns(filterCols);
			setformParams(tableCols?.parameters);
		}
		if (user?.isLoading) {
			handleActionMenuClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.isLoading, user?.tenant]);

	const openEditForm = (row) => {
		setopenWebmonitor(true);
		seteditData(row)
	};

    const handleDeleteConfirm = () => {
		setOpenDeleteConfimation(!openDeleteConfimation ? true : false)
	}

    const handleDelete = async () => {
		try{
			let res= await dispatch(delteWebMonitorData({id:editData?.id}))
			if(res?.payload){
				toast.success("Url Deleted Successfully")
				getWebMonitorData();
			}
		} catch(err){
			toast.error(err)
		}
		handleDeleteConfirm();
	}

	return (
		<MuiThemedBox className={user?.themePreference === 'dark' ? "main_inner_wrap tenants__list__dashboard__darkMode" : "main_inner_wrap"} >
			<Breadcrumbs text={"web monitor"} />
			{!chromeCheck ? <Alert variant="outlined" severity="error" style={{ marginBottom: '15px', marginTop: '15px' }}>
				This functionality is only supported in Google Chrome
			</Alert> : !corsChecker ?
				<Alert variant="outlined" severity="error" style={{ marginBottom: '15px', marginTop: '15px' }}>
					To use this functionality, please download and enable the &quot;ALLOW CORS&quot; extension in Google Chrome. You can find and install the extension from the following link: - <a href="https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?hl=en-US&utm_source=ext_sidebar" target="_blank" rel="noopener noreferrer">https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?hl=en-US&amp;utm_source=ext_sidebar</a>
				</Alert> : null
			}

			<Box className="filter_wrap" >
				<SearchInputField debounceProp={user?.tenant?.id} handleClick={handleSearch} disable = {checkLastUrlStatus} />
				
				<Grid md= {7}  container className="flex_Wrap" spacing={2} sx={{justifyContent:'flex-end'}}>
					<Grid item>
					{user?.tenant?.name == 'Sangoma'?
					<MultiSelectDropDown controlShouldRenderValue = {false}
						onChangeValue ={onChangeTenantFilter}
						value={tenantFilter}
						options={tenantOptionsList}
						placeholder="Tenant..."
						styles={{ width: "94%"}}
						containerWidth={"200px"}
						onApplyClick = {onApplyClick}
						disableApply={checkLastUrlStatus}
					/>:null}
					</Grid>
					<Grid item>
					<ButtonLayout
							isLoading={showLoader}
							text={`Refresh`}
							buttonStyleType={"outlined"}
							icon={
								<FontAwesomeIcon
									icon="fa-solid fa-refresh"
									className="iconPadding"
								/>
							}
							handleClick={handleRefreshUrl}
							disabled = {checkLastUrlStatus}
						/>
					</Grid>
					<Grid item>
					{formParams?.length && canAccess('create', 'web-monitor') ? (
						<ButtonLayout
							text={`url`}
							buttonStyleType={"outlined"}
							icon={
								<FontAwesomeIcon
									icon="fa-solid fa-plus"
									className="iconPadding"
								/>
							}
							handleClick={handleClick}
						/>
					) : null}
				
				</Grid>
				</Grid>
				
			</Box>
			<Box>
				<TableComponent
					className="rdt_table_wrap"
					columns={(tableColumns)}
					rows={webMonitor}
					customStyles={customStyles}
					isLoading={isLoading}
					onPageChange={getWebMonitorData}
					totalRows={totalCount}
					key={tableRefreshKey}
				/>
			</Box>
			<Suspense fallback={<div></div>}>
				{openWebmonitor ?
					<FormPopup open={openWebmonitor} className={"stepper__modal"}>
						<CreateWebMonitor handleClose={handleClose} isUpdateList={true} editData={editData} updateData={getWebMonitorData} />
					</FormPopup> : null}
			</Suspense>

            <Suspense fallback={<div></div>}>
				{openDeleteConfimation && canAccess( 'delete', 'web-monitor') &&
					<DeleteConfirmPopup
						open={openDeleteConfimation}
						close={handleDeleteConfirm}
						deleteUser={handleDelete}
						user={editData}						
						heading={`Url : ${editData?.name} ?`}
						text={"Are you sure you want to delete this Url ?"}
					/>
				}
			</Suspense>

			<ActionMenu
				openMenuList={openMenuList}
				open={open}
				keyId = {refreshKey}
                canDelete={canAccess( 'delete', 'web-monitor')}
				handleActionMenuClose={handleActionMenuClose}
				editData={editData}
				openEditForm={openEditForm}
				navURL='/nf-status/tenants/changelog'
				type={"tenants"}
				primaryText={"tenants"}
                canChnageLog = {false}
                handleDelete={handleDeleteConfirm}
			/>
		</MuiThemedBox>
	);
};

export default WebMonitor;
