import Box from "@mui/material/Box";
import { useState } from "react";
import styled from "@emotion/styled";
import RouteChangeTracker from "routes/RouteChangeTracker";
import { useSelector } from "react-redux";
import NetopsSidebar from "./NetopsSidebar";

const Wrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ open }) => ({
	flexGrow: 1,
	// backgroundColor: '#f2f2f3',
	// marginTop: '48px',
	minHeight: '100%',
	height: 'calc(100vh - 88px)',
	...(open && {
		width: 'calc(100% - 321px)',
		marginLeft: '321px',
	}),
	...(!open && {
		width: 'calc(100% - 48px)',
		marginLeft: '48px',

	}),
}));

export default function MainLayout({ children }) {
	const [open, setOpen] = useState(false);
	const user = useSelector((state) => state.user);
	return (
		<>
		<RouteChangeTracker/>
			<NetopsSidebar setOpen={setOpen} open={open} />
			<Wrapper   open={open} className={`netops__container ${user?.themePreference === 'dark' ? " dark__mode ": "" }`}>
				<Box className=  {`main_wrapper  netops__wrapper ${user?.themePreference === 'dark' ? "main__dashboard__darkMode" : ""}`}>{children}</Box>
			</Wrapper>
			<footer
				className={`footer__container ${user?.themePreference === 'dark' ? 'dark__mode' : ''}`}
			>
				<p>{process.env.REACT_APP_CURRENT_VERSION} | &#169; 2024 Sangoma Technologies Inc. | <a target="_blank" rel="noreferrer" href="https://www.sangoma.com/privacy-policy/">Privacy Policy</a></p>
			</footer>
		</>
	);
}
