import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { MuiThemedCircularProgress } from 'components/common/MuiThemedComponents';
import { useSelector } from 'react-redux';
import { ReactComponent as StatusHelpIcon } from "../../netopsStyles/assets/status_help.svg";
import { StyledTooltip } from './TooltipLabel/TooltipLabel';

const statusCardColors = {
    red: { textColor: "#D74F59", bgColor: "#FDF4F4" },
    green: { textColor: "#099F24", bgColor: "#D1FAE5" },
    grey: { textColor: "#7F888F", bgColor: "#F2F2F3" },
    blue: { textColor: "#3B82F6", bgColor: "#BFDBFE" },
    yellow: { textColor: "#EAB308", bgColor: "#FCFFC1" },
};

const statusCardDarkColors = {
    red: { textColor: "#C70816", bgColor: "#342121" },
    green: { textColor: "#099F24", bgColor: "#1E2D20" },
    grey: { textColor: "#A8A8AA", bgColor: "#192936" },
    blue: { textColor: "#158FCF", bgColor: "rgba(62, 121, 247, 0.10)" },
    yellow: { textColor: "#FBBD00", bgColor: "rgba(255, 190, 24, 0.10)" },
};

export const colorConstants = {
    GREEN: "green",
    RED: "red",
    GREY: "grey",
    BLUE: "blue",
    YELLOW: "yellow"
}

const NetopsStatusCards = ({ tableHeaderCardDetails, isLoading, handleCardClick, customCardWidth }) => {
    const user = useSelector((state) => state.user);
    const containerRef= useRef();
    const cardRef = useRef();
    const [cardsPerRow, setCardsPerRow] = React.useState(0);

    const enabledHeaderCards = useMemo(
        () => tableHeaderCardDetails?.filter((card) => card.isEnabled),
        [tableHeaderCardDetails]
    );

    const removeTrailingZeros = (num) => {
        const val = num?.toString().replace(/\.00$/, '');
        return Number(val)?.toFixed(2);
    };

    const calculateCardsPerRow =useCallback(() => {  
        const width = cardRef?.current?.offsetWidth;
        const cards = Math.round(containerRef?.current?.offsetWidth / width);
        setCardsPerRow(cards);
    },[containerRef?.current?.offsetWidth, cardRef?.current?.offsetWidth])


    useEffect(() => {
        calculateCardsPerRow();
        window.addEventListener('resize', calculateCardsPerRow);
    
        return () => {
            window.removeEventListener('resize', calculateCardsPerRow);
        };
    }, [user?.isSidebarOpen, containerRef?.current?.offsetWidth]);


    return (
        <Box
            ref={containerRef}
            className={`status__cards__wrapper ${user?.themePreference === 'dark' ? 'dark__mode' : ''}`}
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fit, minmax(${customCardWidth ? customCardWidth+'px' : '200px'}, 1fr))`,
            }}
        >
            {enabledHeaderCards?.map((item, index) => {
                const cardColors = user?.themePreference === "dark" ? statusCardDarkColors : statusCardColors
                return(
                    <Box
                    ref={cardRef}
                    key={item?.id || index}
                    onClick={(e) =>
                        handleCardClick &&  Number(item?.count) !== 0 && !item?.disableClick && handleCardClick(e, item)
                    }
                    className={`status__card ${item?.className}`}
                    sx={{
                        cursor:  Number(item?.count) === 0 || item?.disableClick ? 'default' : 'pointer',
                        borderRight: (theme) =>
                            `1px solid ${user?.themePreference === "dark" ? "#112547" :theme.palette.divider}`,
                        ...(index + 1) % cardsPerRow === 0 && {
                            borderRight: 'none', 
                        },
                        '&:last-child': {
                            borderRight: 'none', 
                        },
                    }}
                >
                    {isLoading ? (
                        <Box sx={{ width:"100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <MuiThemedCircularProgress />
                    </Box>
                    ) : (
                        <>
                            <Box className="title__wrapper">
                                {item?.tooltipText ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography sx={{ margin: 0 }} component="p" className="title">
                                            {item?.text}
                                        </Typography>
                                        <StyledTooltip placement="top" title={item?.tooltipText}>
                                            <StatusHelpIcon className="icon" />
                                        </StyledTooltip>
                                    </Box>
                                ) : (
                                    <Typography component="p" sx={{ margin: 0 }} className="title">
                                        {item?.text}
                                    </Typography>
                                )}
                                <Typography component="p" sx={{ margin: 0 }} className="count">
                                    {Number(item?.count) || 0}
                                </Typography>
                            </Box>
                            <Box
                                className="percentage__wrapper"
                                sx={{
                                    color: cardColors[item?.color]?.textColor,
                                    backgroundColor: cardColors[item?.color]?.bgColor,
                                    borderRadius: '4px',
                                    padding: '4px 8px',
                                    marginRight: '16px',
                                    ...(cardsPerRow > 0 && (index + 1) % cardsPerRow === 0 && {
                                        marginRight: '0px',
                                    }),
                           
                                }}
                            >
                                <Typography component="p" sx={{ margin: 0 }} className="percentage">
                                    {removeTrailingZeros(item?.percentage) || 0}%
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
                )
            }
                
            )}
        </Box>
    );
};

export default NetopsStatusCards;
