import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from 'components/common/Breadcrumps';
import { MuiThemedBox, MuiThemedCircularProgress } from 'components/common/MuiThemedComponents';
import { fetchChangeLogsDetailsById } from 'store/changelogs';
import { toast } from 'react-toastify';

const ChangelogDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const changelogData = useSelector((state) => state.changelogs);

    const getChangelogDetails = async () => {
        try {
            setIsLoading(true);
            const changelogDetailData = await dispatch(fetchChangeLogsDetailsById(changelogData?.id));
            setContacts(changelogDetailData?.payload);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            toast.error("Oops! Something went wrong");
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        getChangelogDetails();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const compareObjects = (preData, postData) => {

        const differences = {};

        const compareArrays = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return true;
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return true;
            }
            return false;
        };

        Object.keys(preData).forEach(key => {          
            if (Array.isArray(preData[key]) && Array.isArray(postData[key])) {
                if (compareArrays(preData[key], postData[key])) {
                    differences[key] = true;
                }
            } else if (preData[key] !== postData[key]) {
                differences[key] = true;
            }
        });

        Object.keys(postData).forEach(key => {
            if (!(key in preData)) {
                differences[key] = true;
            }
        });

        return differences;
    };


    const renderData = (data = {}, title, differences = {}) => (
        <Card variant="outlined" sx={{ width: '100%' }}>
            {isLoading ?
                <div className='progress'>
                    <MuiThemedCircularProgress />
                    Please wait...
                </div> :
                <CardContent>
                <Typography variant="h6" gutterBottom>{title}</Typography>
                {Object.values(differences || {}).some(value => value !== undefined && value !== null) &&
                    Object.values(data || {}).some(value => value !== undefined && value !== null)? (
                  <Box component="pre" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    {Object.keys(differences || {}).map(key => (
                      data[key] !== undefined && ( 
                        <Box
                          key={key}
                          component="span"
                          sx={{
                            display: 'block',
                            backgroundColor: differences[key]
                              ? (title === 'Pre-Change Data' ? '#ffdddd' : '#ddffdd')
                              : 'transparent',
                            color: user?.themePreference === "dark"
                              ? differences[key] ? "black" : "inherit"
                              : "",
                            padding: '4px',
                            borderRadius: '4px'
                          }}
                        >
                          {key}: {data[key]}
                        </Box>
                      )
                    ))}
                  </Box>
                ) : (
                  <Box component="pre" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                    No Data
                  </Box>
                )}
              </CardContent>
              
            }
        </Card>
    );

    let prechangeData = contacts?.length > 0 && contacts[0]?.prechange_data || {};
    let postchangeData = contacts?.length > 0 && contacts[0]?.postchange_data || {};
    if(contacts?.length > 0 && contacts[0]?.changed_object_type == "dcim.site"){ 
        prechangeData = {
            name : prechangeData?.name,
            displayName: prechangeData?.custom_fields?.display_name !== undefined ? prechangeData?.custom_fields?.display_name : null,
            description: prechangeData?.description,
            PhysicalAddress: prechangeData?.physical_address,
            shippingAddress: prechangeData?.shipping_address,
            timeZone: prechangeData?.time_zone,
            state: prechangeData?.status,
            latitude: prechangeData?.latitude,
            longitude: prechangeData?.longitude
        }
        postchangeData = {
            name : postchangeData?.name,
            displayName: postchangeData?.custom_fields?.display_name || "",
            description: postchangeData?.description,
            PhysicalAddress: postchangeData?.physical_address,
            shippingAddress: postchangeData?.shipping_address,
            timeZone: postchangeData?.time_zone,
            state: postchangeData?.status,
            latitude: postchangeData?.latitude,
            longitude: postchangeData?.longitude
        }

    }else if(contacts?.length > 0 && contacts[0]?.changed_object_type == "tenancy.tenant"){
        prechangeData = {
            name : prechangeData?.name,
            description: prechangeData?.description,
            SDWAN: prechangeData?.custom_fields?.vco_enterprise_id  ? "Yes": contacts[0]?.action?.value !== "update" ? undefined : postchangeData?.custom_fields?.vco_enterprise_id ? "NO" : undefined,
            WiFi: prechangeData?.custom_fields?.meraki_org_id ? "Yes": contacts[0]?.action?.value !== "update" ? undefined : postchangeData?.custom_fields?.meraki_org_id? "NO" : undefined,
            circuits: prechangeData?.custom_fields?.wug_device_group_id ? "Yes": contacts[0]?.action?.value !== "update" ? undefined : postchangeData?.custom_fields?.wug_device_group_id? "NO" : undefined,
            Cradlepoint: prechangeData?.custom_fields?.cradlepoint_id ? "Yes": contacts[0]?.action?.value !== "update" ? undefined : postchangeData?.custom_fields?.cradlepoint_id? "NO" : undefined,
            Fortinet: prechangeData?.custom_fields?.fortinet_adom_oid ? "Yes": contacts[0]?.action?.value !== "update" ? undefined : postchangeData?.custom_fields?.fortinet_adom_oid? "NO" : undefined,
        }
        
        postchangeData = {
            name : postchangeData?.name,
            description: postchangeData?.description,
            SDWAN: postchangeData?.custom_fields?.vco_enterprise_id ? "Yes": prechangeData?.SDWAN ? "NO": contacts[0]?.action?.value !== "update"? "NO" : undefined,
            WiFi: postchangeData?.custom_fields?.meraki_org_id ? "Yes": prechangeData?.WiFi ? "NO": contacts[0]?.action?.value !== "update"? "NO" : undefined,
            circuits: postchangeData?.custom_fields?.wug_device_group_id ? "Yes": prechangeData?.circuits ? "NO": contacts[0]?.action?.value !== "update"? "NO" : undefined,
            Cradlepoint: postchangeData?.custom_fields?.cradlepoint_id ? "Yes": prechangeData?.Cradlepoint ? "NO": contacts[0]?.action?.value !== "update"? "NO" : undefined,
            Fortinet: postchangeData?.custom_fields?.fortinet_adom_oid ? "Yes": prechangeData?.Fortinet ? "NO": contacts[0]?.action?.value !== "update"? "NO" : undefined,
            
        }
    }else{
        prechangeData = {
            DeviceName : prechangeData?.name || prechangeData?.custom_fields?.circuit_name,
            serialNo: prechangeData?.serial,
            status: prechangeData?.status,
            ipAddress: prechangeData?.custom_fields?.switch_ip_address || prechangeData?.custom_fields?.fortinet_public_ip || prechangeData?.custom_fields?.ip_address
        }
        postchangeData = {
            DeviceName : postchangeData?.name || postchangeData?.custom_fields?.circuit_name,
            serialNo: postchangeData?.serial,
            status: postchangeData?.status,
            ipAddress: postchangeData?.custom_fields?.switch_ip_address || postchangeData?.custom_fields?.fortinet_public_ip ||  postchangeData?.custom_fields?.ip_address
        }
    }

    const differences = compareObjects(prechangeData, postchangeData);


    return (
        <MuiThemedBox className="main_inner_wrap chnagelog__wrap">
            <Breadcrumbs
                secondaryText={"ChangeLogs Details"}
                primaryTextPath={changelogData?.type}
                text={changelogData?.primaryText}
            />
            <Box marginTop={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {renderData(prechangeData, "Pre-Change Data", differences)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {renderData(postchangeData, "Post-Change Data", differences)}
                    </Grid>
                </Grid>
            </Box>

        </MuiThemedBox>
    )
}
export default ChangelogDetails

