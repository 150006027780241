import { Box, FilledInput, FormHelperText } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import help from '../../assets/icons/help.png';
import helpDarkTheme from '../../assets/icons/help_dark_theme.png'
import { StyledTooltip } from "./TooltipLabel/TooltipLabel";

function PasswordField({
	label,
	id,
	value,
	setValues,
	classname,
	isRequired,
	placeholder,
	disable,
	maxChar,
	styles,
	error,
	helperText,
	showPassword,
	endAdornment,
	infoHelperText
}) {

	const inputRef = useRef(null);
	const user = useSelector((state) => state.user);


	useEffect(() => {
		let resizeObserverEntries = []

		const observer = new ResizeObserver((entries) => {
			resizeObserverEntries = entries
		})

		if (inputRef.current) observer.observe(inputRef.current)

		return () => {
			resizeObserverEntries.forEach((entry) => entry.target.remove())
			observer.disconnect()
		}
	}, [])

	
	return (
		<FormGroup className="form_group_wrap" style={styles}>
			<InputLabel sx={{display: "flex", alignItems:"center"}} className="input_label">
				{label}
				{isRequired ? <span className="astrick">*</span> : null}
				{infoHelperText &&
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<StyledTooltip placement="top" title={infoHelperText} width="230px">
							<img src={user?.themePreference === 'dark' ? helpDarkTheme : help} alt='help' style={{ width: '20px', height: '20px' }} />
						</StyledTooltip>
					</Box>
				}
			</InputLabel>
			<FilledInput
				id={id+"-search"}
				key={id+"-search"}
                type={showPassword ? 'text' : 'password'}
				ref={inputRef}
				className={`${classname} ${disable ? user.themePreference == 'dark' ? "grayTextField_dark" :'grayTextField' : ''} password__input__field`}
				value={value}
				variant="outlined"
				disabled={disable}
				onChange={(e) => setValues(e, id)}
				placeholder={placeholder ? placeholder : ""}
				autoComplete="off"    
				endAdornment={endAdornment}
                sx={{
                    backgroundColor: "inherit",
                    '&:hover': {
                        backgroundColor: "inherit",
                    },
                }}        

                  slotProps={{
                    input: {
                        disableUnderline: true, 

                    }
                  }}
				inputProps={{
					maxlength: maxChar,
				}}
			/>
			{helperText && <FormHelperText error={error}>{helperText}</FormHelperText>} 
		</FormGroup>
	);
}

PasswordField.propTypes = {
	label: PropTypes.string,
	key: PropTypes.number,
	id: PropTypes.string,
	value: PropTypes.string,
	setValues: PropTypes.func,
	classname: PropTypes.string,
	placeholder: PropTypes.string,
};

export default PasswordField;
