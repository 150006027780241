import React from 'react'
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel';

const HeaderDropdown = ({ dropdownoptions, anchorEl, handleClose }) => {
    const open = Boolean(anchorEl);
    const user = useSelector(state => state.user);

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            transitionDuration={0}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            PaperProps={{
                sx: {
                    padding: 0,
                    backgroundImage:'none',
                    '& .MuiList-root': {
                        padding: '0px',
                        paddingTop:'4px',
                        minWidth:'250px',

                       
                    }
                }

            }}
        >
            {dropdownoptions && dropdownoptions?.length > 0 && dropdownoptions?.map(item => {
                return (
                    <>
                        <MenuItem sx={{ display: 'flex', alignItems: 'center', cursor: !item?.onClick ? 'default' : 'pointer', pointerEvents: !item?.onClick ? 'none' : '' , paddingTop: '6px' , paddingBottom:'6px',  '&:hover': {
                            backgroundColor: user?.themePreference === 'dark' ? '#0B182E' : '#E0E0E0',
                        }
                       }} key={item?.id} onClick={item?.onClick}>
                            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ paddingRight: '8px' }}>
                                    {item?.startIcon}
                                </Box>


                                {item?.addTooltip ?
                                    <StyledTooltip placement="right" title={item?.title} arrow>
                                        <Typography component={"div"} sx={{ fontSize: '14px', fontFamily: 'Poppins', color: user?.themePreference === 'dark' ? '#d1dbe4' : 'rgb(51 65 77/ 1)', opacity: 0.8, lineHeight: '1' }} >
                                            {item?.title}
                                        </Typography>
                                    </StyledTooltip>
                                    :
                                    <Typography component={"div"} sx={{ fontSize: '14px', fontFamily: 'Poppins', color: user?.themePreference === 'dark' ? '#d1dbe4' : 'rgb(51 65 77/ 1)', opacity: 0.8, lineHeight: '1' }} >
                                        {item?.title}
                                    </Typography>
                                }

                            </Box>
                            <Box sx={{ paddingLeft: '8px' }}>
                                {item?.endIcon}
                            </Box>


                        </MenuItem>
                        <Divider className='header__dropdown__divider' sx={{ marginBottom: '0px', marginTop: '0px' }} component="li" />
                    </>
                )
            })}

        </Menu>
    )
}

export default HeaderDropdown
