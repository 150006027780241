import React, { useCallback, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { Box, ClickAwayListener, Popper } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getFirmwareTag } from 'store/gateway';
import { toast } from 'react-toastify';
import { MuiThemedCircularProgress } from '../MuiThemedComponents';


export default function AddButtomDropdown({tenantId, disabled, handleMenuOptionClick,menu, isAddMenu, text }) {
	const [openMenu, setOpenMenu] = useState(false);
	const [anchorEl, setAnchoeEl] = useState(null);

	const handleClick = (e) => {
		setOpenMenu(!openMenu)
		setAnchoeEl(e?.currentTarget)
	};

	return (
		<>
			<IconButton
				className={"button-outlined"}
				onClick={e => handleClick(e)}
				variant={"outlined"}
				sx={{display:"flex", alignItems:"center", justifyContent:"center !important" }}
				disabled = {disabled}
			>
				{text}
			</IconButton>

			{openMenu && <FirmwareTagPopper assignBtn={true} tenantId={tenantId} isAddMenu={isAddMenu} addMenuOptions={menu} handleMenuOptionClick={handleMenuOptionClick} handlePopperClose={handleClick} openMenu={openMenu} anchorEl={anchorEl} open={openMenu} />}
		</>
	)
}

export function capitalizeFirstLetter(string) {
	if (!string) return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const FirmwareTagPopper = ({ handlePopperClose, openMenu, anchorEl, open, handleMenuOptionClick, tenantId, isAddMenu, addMenuOptions, firmware_tag, assignBtn }) => {
	const user = useSelector(state => state?.user);
	const dispatch = useDispatch();
	const [isFetchingData, setIsFetchingData] = useState(false);
	const [menuOptions, setMenuOptions] = useState(isAddMenu ? addMenuOptions : []);

	const fetchFirmwareTagByTenantId = useCallback(async () => {
		setIsFetchingData(true);

		try {
			if (!tenantId) {
				toast.error("Something went wrong");
				return
			}
			let data = await dispatch(getFirmwareTag({ tenantId: tenantId })).unwrap();
			if (data && data?.length > 0) {
				let options = data?.map(item => {
					
					return {
						value: item?.tag,
						label: `${capitalizeFirstLetter(item?.tag)}`,
						id: item?.id
					}
				});
				if(assignBtn) {
					options.push({ value: '', label: 'Remove Tag' });
				}

				if(firmware_tag) {
					options= options?.filter(item => item?.value?.toLowerCase() !== firmware_tag?.toLowerCase());
					options.push({ value: '', label: 'Remove Tag' });
				} 			
				setMenuOptions(options)
			}
		} catch (error) {
			console.error("Error fetching firmware data:", error);
		} finally {
			setIsFetchingData(false);
		}
	}, [dispatch, firmware_tag, tenantId])

	useEffect(() => {
		if(isAddMenu) {
			setMenuOptions(addMenuOptions);
		} else {
			fetchFirmwareTagByTenantId();

		}
	}, [])

	return (
		<Popper
			key={new Date()}
			anchorEl={anchorEl}
			open={open}
			onClose={handlePopperClose}
			onClick={handlePopperClose}
			className={user?.themePreference === 'dark' ? "menu_wrap darkMode" : " menu_wrap"}
			PaperProps={{
				elevation: 0,
			}}
			transformOrigin={{ horizontal: "center", vertical: "top" }}
			anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
		>
			<ClickAwayListener onClickAway={() => {
				if (openMenu) {
					handlePopperClose();
				}
			}}>
				<div style={{ padding: "6px", minWidth:"100px", }}>
					{(isFetchingData && !isAddMenu) ?
						<Box sx={{minWidth:"120px", minHeight:"130px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<MuiThemedCircularProgress />
						</Box>
						:
						menuOptions?.length > 0 ?
						menuOptions?.map((item, idx) => {
							return (
								<MenuItem key={idx} className="list_item" onClick={(e) => handleMenuOptionClick(e, item)}>
									{item?.label}
								</MenuItem>
							)
						}) :
						<Box sx={{minWidth:"120px", minHeight:"130px", display: "flex", alignItems: "center", justifyContent: "center" }}>
							No Data
						</Box>
						}
				</div>
			</ClickAwayListener>
		</Popper>
	)
}
