import React, {useState, useEffect, Suspense, useCallback, useMemo} from 'react'
import Breadcrumbs from "components/common/Breadcrumps";
import Box from "@mui/material/Box";
import { MuiThemedBox} from "components/common/MuiThemedComponents";
import { useLocation } from "react-router";
import { useCanAccess } from "services/canACLAccess";
import ButtonLayout from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from "react-redux";
import AddTagPopup from './AddTagPopup';
import { getFirmwareTag, deleteFirmwareTag } from 'store/gateway';
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel';
import ActionComponent from 'components/common/ActionComponent';
import ActionMenu from 'components/common/ActionMenu';
import { toast } from 'react-toastify';
import DeleteConfirmPopup from "components/component/userManagement/DeleteConfirmPopup";
import { trimSpacesFromStartAndEnd } from 'utils/Utilities';
import SearchInputField from 'components/common/SearchInputField';
import TableComponent from 'components/common/TableComponent/TableComponent';
import { capitalizeFirstLetter } from 'components/common/AddButtonDropdown/AddButtomDropdown';

export default function GatewayTag() {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const canAccess = useCanAccess();
    const location = useLocation();
    const initData = location?.state?.[0];
	const {primaryText,  primaryTextPath} = initData || {};
    const tenants = useSelector((state) => state.devices?.tenants)?.filter(i => i.name !== 'Sangoma' && i.custom_fields?.gateway_enabled);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading]  = useState(false);
    const [tagPopupDetails, setTagPopupDetails] = useState({
		showPopup: false,
		data: null,
		anchorEl: null,
	});
    const [deletePopupDetails, setDeletePopupDetails] = useState(false)
    const [openMenuList, setopenMenuList] = useState({anchorEl: null, open: false});
    const [searchInputValue, setSearchInputValue] = useState();
    const [tableRefreshKey, setTableRefreshKey] = useState(null);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleActionMenuOpen = (e, row) => {
		setopenMenuList({anchorEl: e?.currentTarget, open: row, data: row, });
	}

    const handleActionMenuClose = () => {
		setopenMenuList({anchorEl: null, open: null,data: openMenuList?.data});
	}


    const columns = useMemo(() => {
        return [
            {
                name: "tenant",
                title: "Tenant",
                wrap: true,
                weight: 1,
                sortField: 'tenant.name',
                cell: (row) => <TooltipLabel text={row?.tenants?.label} />,
                isEnabled: user?.tenant?.name === "Sangoma",
                sortable: true,
            },
            {
                name: "CFG Group",
                title: "CFG Group",
                wrap: true,
                weight: 2,
                minWidth: 200,
                cell: (row) => <TooltipLabel text={`${capitalizeFirstLetter(row?.tag)}`} />,
                isEnabled: true,
            },
            {
                name: "CFG Link",
                title: "CFG Link",
                wrap: true,
                weight: 2,
                minWidth: 150,
                sortField: 'site.name',
                cell: (row) => <TooltipLabel text={row.url || '--'} />,
                isEnabled: true,
            },
            {
                name: "Description",
                title: "description",
                wrap: true,
                weight: 2,
                minWidth: 150,
                cell: (row) => <TooltipLabel text={row.description || '--'} />,
                isEnabled: true,
            },
            {
                name: "FW Version",
                title: "version",
                wrap: true,
                weight: 2,
                minWidth: 150,
                cell: (row) => <TooltipLabel text={row.version || '--'} />,
                isEnabled: true,
            },
            {
                name: "Action",
                title: "Action",
                wrap: true,
                weight: 2,
                minWidth: '50',
                maxWidth: '80px !important',
                cell: (row) => <ActionComponent disableEdit={!canAccess('update', 'gateways')}
                    onDropdownClick={handleActionMenuOpen} row={row} onEditClick={handleClick} />,

                isEnabled: true,
            },
        ]
    }, [canAccess, user?.tenant?.name]);

    useEffect(() => {
        fetchFirmwareTags(user?.tenant?.id);
      },[user?.tenant]);

    const handleClick = (row) => {
		setTagPopupDetails({ showPopup: true, anchorEl: null, data: row });
    }

    const closeTagPopup = (data , refresh) => {
		setTagPopupDetails({ showPopup: false, data: [], anchorEl: null });
        refresh && fetchFirmwareTags(user?.tenant?.id);
    }

    const onDelete = async() => {
        setIsDeleteLoading(true);
        await dispatch(deleteFirmwareTag({ id: openMenuList?.data?.id })).unwrap();
        setIsDeleteLoading(false);
        setDeletePopupDetails(false);
        toast.success('CFG Group Deleted successfully');
        fetchFirmwareTags(user?.tenant?.id);
    }

    const fetchFirmwareTags = useCallback(async (tenantId, searchString) => {
        setIsLoading(true);
        try {
            const searchTextRequest = { searchText: searchString === '' ? searchString : searchString ? searchString : searchInputValue?.length > 0 ? searchInputValue : null }
            const request = {
                tenantId: tenantId,
                ...searchTextRequest
            }

            let data = await dispatch(getFirmwareTag(request)).unwrap();
            data = data?.map(i => {
                return {
                    tenants: { value: i.tenant_id, label: tenants?.find(j => j.id === i?.tenant_id)?.name },
                    ...i
                }
            })
            setRows(data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching tags data:", error);
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, searchInputValue, tenants])

    const handleDelete = () => {
        setDeletePopupDetails(!deletePopupDetails);
    }

    const handleSearch = useCallback(async (e) => {
        const searchString = trimSpacesFromStartAndEnd(e?.target?.value)
        setSearchInputValue(searchString);
        setTableRefreshKey(new Date().getTime());
        fetchFirmwareTags(user?.tenant?.id, searchString);
    }, [fetchFirmwareTags, user?.tenant?.id])

  return (
    <MuiThemedBox className="main_inner_wrap chnagelog__wrap">
    <Breadcrumbs
        secondaryText={'CFG Group'}
        primaryTextPath={primaryTextPath}
        text={primaryText || 'Gateways'}
    />
    <Box className="filter_wrap">
        <SearchInputField handleClick={handleSearch} value={searchInputValue} />
        {canAccess('create', 'gateways') && (
            <ButtonLayout
                text={`CFG Group`}
                buttonStyleType={"outlined"}
                disabled={user?.tenant?.name === 'Sangoma' ? false : rows.length > 20}
                icon={
                    <FontAwesomeIcon
                        icon="fa-solid fa-plus"
                        className="iconPadding"
                    />
                }
                handleClick={handleClick}
            />
        )}
    </Box>
    <Box>
        <TableComponent
            className="rdt_table_wrap table_with_header"
            columns={user?.tenant?.id ? columns?.filter(i => i.name !== 'tenant') : columns}
            rows={rows}
            key={tableRefreshKey}
            isLoading={isLoading}
            pagination={true}
            totalRows={rows?.length}
        />
    </Box>
    <Suspense fallback={<div></div>}>
				{deletePopupDetails && canAccess( 'delete', 'gateways') &&
					<DeleteConfirmPopup
						open={deletePopupDetails}
						close={handleDelete}
						deleteUser={onDelete}
                        user={openMenuList?.data}
                        isLoading = {isDeleteLoading}	
						heading={`CFG Group : ${openMenuList.data?.tag} ?`}
						text={"Deleting this CFG Group will unassign it from all associated items. Do you wish to proceed?"}
					/>
				}
    {tagPopupDetails?.showPopup &&
            <AddTagPopup
                data={tagPopupDetails?.data}
                onClose={closeTagPopup}
                open={tagPopupDetails?.showPopup}
                tenantList = {tenants}
            />
    }
    </Suspense>

    <ActionMenu
				openMenuList={openMenuList?.anchorEl}
				open={openMenuList?.open}
				handleActionMenuClose={handleActionMenuClose}
				canDelete={canAccess( 'delete', 'gateways')}
				type="site"
				primaryText="Sites"
				handleDelete={handleDelete}
                canChnageLog={false}
	/>
</MuiThemedBox>
  )
}


