import { Typography } from '@mui/material'
import React from 'react'

const NetopsHeaderTitle = ({title}) => {
  return (
    <Typography className='netops__header__title' variant='h1'>
      {title}
    </Typography>
  )
}

export default NetopsHeaderTitle
