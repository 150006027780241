import React, {useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MuiThemedBox } from '../MuiThemedComponents';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';


const StyledTabs = styled((props) => (
    <Tabs
      {...props}
    />
  ))({
    minHeight: '0px',
  });

  const StyledTab = styled((props) => (
    <Tab
      {...props}
    />
  ))({
    minHeight: '0px',
    padding: '10px 16px',
  });

function TabsComponet({tabs, ...props}) {
    const location = useLocation();
    const navigate = useNavigate()
    const [value, setValue] = useState(location?.state?.activeTab ?  location?.state?.activeTab : tabs[0]?.value);

    const handleChange = (event) => {
        if(location?.state) {
            navigate(location.pathname, {state: null})
        }
        setValue(event.target.name);
    };

    return (
        <MuiThemedBox className="main_inner_tab_wrap main_inner_wrap flex-col " gap={"10px"}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', minHeight: '0px' }}>
                    <StyledTabs {...props} value={value} onChange={handleChange} styles={{minHeight: '0px' }} >
                        {tabs?.map((tab, index) => {
                            return (
                               <StyledTab disabled = {false || tab?.disabled} key = {tab?.id} value={tab.value} name={tab?.value} label={tab?.name}  id = {`nf-tab-${index}`}
                               aria-controls = {`nf-${index}`} />
                            )
                        })}
                        </StyledTabs>
                    </Box>
                    {tabs?.map((tab) => {
                        return (value === tab?.value && <div key = {tab?.value}>{tab.component}</div>)
                     })}
                </Box>
        </MuiThemedBox>
    );
}

export default TabsComponet;