import { Box, InputLabel, Typography } from "@mui/material";
import { ThemedReactSelect } from "components/common/MuiThemedComponents";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

const AssociateCircuitsPopup = ({ circuitList, handleSelectWanCircuits, handleSelectInternalPortCircuits, formValues, isWanLoading, isInternalLoading }) => {
    const user = useSelector((state) => state.user);
    const [circuitListData, setCircuitListData] = useState(circuitList);
    const dropDownList = [1, 2];

    // const [selectedWanPorts, setSelectedWanPorts] = useState([]);
    // const [selectedInternalPorts, setSelectedInternalPorts] = useState([]);
    useEffect(() => {
        const selectedValues = Object.values(formValues).flatMap((values) => [
            values?.wanCircuits?.value,
            values?.internalPortCircuits?.value,
        ]);

        const newFilteredCircuitList = circuitList.filter((option) => !selectedValues.includes(option.value));
        setCircuitListData(newFilteredCircuitList);
    }, [formValues, circuitList]);

    const getOptionsWithDisabledStatus = (port, portType) => {
        return circuitListData.map(data => {
            const isDisabled = formValues?.circuitlist?.some(item => item.port === parseInt(port) && item.port_type === portType);
            return { ...data, isDisabled };
        });
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? 'lightgray' : provided.backgroundColor,
            color: state.isDisabled ? 'darkgray' : provided.color,
            cursor: state.isDisabled ? 'not-allowed' : 'default',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? 'darkgray' : provided.color,
        }),
    };
    // useEffect(() => {
    //     if (devicePortData[0]?.wan_ports > 0 && selectedWanPorts.length === 0) {
    //         setSelectedWanPorts(["1"]);
    //     }
    //     if (devicePortData[0]?.internal_ports > 0 && selectedInternalPorts.length === 0) {
    //         setSelectedInternalPorts(["1"]);
    //     }
    // }, [devicePortData, selectedWanPorts, selectedInternalPorts]);

    // const handleWanPortChange = (event) => {
    //     const selectedPort = event.target.value;
    //     if (!selectedWanPorts.includes(selectedPort)) {
    //         setSelectedWanPorts([...selectedWanPorts, selectedPort]);
    //     }
    //     handleSelectWanCircuits(parseInt(event.target.value) - 1, "");
    // };

    // const handleInternalPortChange = (event) => {
    //     const selectedPort = event.target.value;
    //     if (!selectedInternalPorts.includes(selectedPort)) {
    //         setSelectedInternalPorts([...selectedInternalPorts, selectedPort]);
    //     }
    //     handleSelectInternalPortCircuits(parseInt(event.target.value) - 1, ""); 
    // };

    // const isWanPortDisabled = (port) => selectedWanPorts.includes(port.toString());
    // const isInternalPortDisabled = (port) => selectedInternalPorts.includes(port.toString());

    return (
        <div className="create__site__popup__wrap">
            <Box 
                sx={{
                    padding: '20px',
                    borderRadius: '8px',
                }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>WAN</Typography>
                    {/* <Select
                        displayEmpty
                        sx={{
                            width: '200px',
                            height: '40px', 
                          }}
                        onChange={handleWanPortChange}
                        value={selectedWanPorts.length > 0 ? selectedWanPorts[selectedWanPorts.length - 1].toString() : ""}
                    >
                        {[...Array(devicePortData[0]?.wan_ports)].map((_, index) => (
                            <MenuItem key={index} value={(index + 1).toString()} disabled={isWanPortDisabled(index + 1)}>{`Port ${index + 1}`}</MenuItem>
                        ))}
                    </Select> */}
                </Box>
                {dropDownList.map((port, index) => (
                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" width={'100%'} marginBottom={2}>
                        <Box width={'20%'}>
                            <InputLabel className="input_label">{`Port ${port}`}</InputLabel>
                        </Box>
                        <Box width={'75%'}>
                            <ThemedReactSelect
                                isClearable
                                onChange={(selectedOption) => handleSelectWanCircuits(port - 1, selectedOption)}
                                name={"associate_circuit"}
                                placeholder="Select Circuit"
                                options={getOptionsWithDisabledStatus(port, "WAN")}
                                value={formValues?.[port - 1]?.wanCircuits? formValues?.[port - 1]?.wanCircuits : formValues?.circuitlist?.find(item => item.port === parseInt(port) && item.port_type === "WAN")
                                }
                                classNamePrefix={user?.themePreference === 'dark' ? "" : "react-select"}
                                isDisabled={isWanLoading.port == port ? isWanLoading.loader:false}
                                className="react-select-container"
                                isLoading = {isWanLoading.port == port ? isWanLoading.loader:false}
                                customStyles={user?.themePreference !== 'dark' ? customStyles: null}
                            />
                        </Box>
                    </Box>
                ))}

                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} marginTop={2}>
                    <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Internal</Typography>
                    {/* <Select
                        displayEmpty
                        sx={{
                            width: '200px',
                            height: '40px', 
                          }}
                        onChange={handleInternalPortChange}
                        value={selectedInternalPorts.length > 0 ? selectedInternalPorts[selectedInternalPorts.length - 1].toString() : ""}
                    >
                        {[...Array(devicePortData[0]?.internal_ports)].map((_, index) => (
                            <MenuItem key={index} value={(index + 1).toString()} disabled={isInternalPortDisabled(index + 1)}>{`Port ${index + 1}`}</MenuItem>
                        ))}
                    </Select> */}
                </Box>
                {dropDownList.map((port, index) => (
                    <Box key={index} display="flex" alignItems="center" justifyContent="space-between" width={'100%'} marginBottom={2}>
                        <Box width={'20%'}>
                            <InputLabel className="input_label">{`Port ${port}`}</InputLabel>
                        </Box>
                        <Box width={'75%'}>
                            <ThemedReactSelect
                                isClearable
                                onChange={(selectedOption) => handleSelectInternalPortCircuits(port - 1, selectedOption)}
                                name={"associate_circuit"}
                                placeholder="Select Circuit"
                                options={getOptionsWithDisabledStatus(port, "Internal port")}
                                value={formValues?.[port - 1]?.internalPortCircuits? formValues?.[port - 1]?.internalPortCircuits : formValues?.circuitlist?.find(item => item.port === parseInt(port) && item.port_type === "Internal port") || null}
                                classNamePrefix={user?.themePreference === 'dark' ? "" : "react-select"}
                                isDisabled={isInternalLoading.port == port ? isInternalLoading.loader  : false}
                                className="react-select-container"
                                isLoading={isInternalLoading.port == port ? isInternalLoading.loader  : false}
                                customStyles={user?.themePreference !== 'dark' ? customStyles: null}
                            />
                        </Box>
                    </Box>
                ))}
            </Box>
        </div>
    );
};

export default AssociateCircuitsPopup;
