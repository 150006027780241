import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import TooltipLabel from '../TooltipLabel/TooltipLabel';

export default function CopyTextCell({text, showWithTooltip, containerClassName}) {
    const [copyText, setCopyText] = useState('Copy');
    const [showCopyText, setShowCopyText] = useState(false)
    const user = useSelector(state => state?.user)

    const onCopyClick =async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await navigator.clipboard.writeText(text);
        setCopyText('Copied');
    }

    const customStyle = {
        fontSize: '12px',
        fontWeight: '700',
        marginLeft: '5px',
        color:user?.themePreference === 'dark'? '#648cd2':' #244C91',
    }

    const containerStyle = {
        height: '25px',
        alignItems: 'center',
    }

    
  return (
      <div style={containerStyle} data-tag="allowRowEvents" className={`${containerClassName} flex`} id={text} onMouseOver={() => setShowCopyText(true)} onMouseLeave={() => { setShowCopyText(false); setCopyText('Copy'); }}>
          {showWithTooltip ?
              <div data-tag="allowRowEvents"><TooltipLabel text={text} /></div> :
              <div data-tag="allowRowEvents">{text}</div>
          }

          <div style={{ width: "40px" }}>
              {showCopyText && text !== '--' && text !== '-' && text !== ' ' &&
                  <div data-tag="allowRowEvents" onClick={(e) => { onCopyClick(e) }} style={customStyle}>
                      {copyText}
                  </div>
              }
          </div>

      </div>

  )
}
