import React from 'react'
import { useSelector } from 'react-redux';
import Select from "react-select";


const NetopsReactSelect = ({ isRequired, ...props }) => {
    const user = useSelector((state) => state?.user);
    const isDarkMode = user?.themePreference === 'dark';

    const getCustomStyles = (isDarkMode) => ({
        control: (provided) => ({
            ...provided,
            backgroundColor: isDarkMode ? 'transparent' : '#fff',
            outline: isDarkMode ? '#142B53' : '#E0E0E0',
            minWidth: "150px",
            maxWidth:"250px",
            display: 'flex',
            padding: '8px 12px',
            alignItems: 'center',
            gap: '6px',
            border: isDarkMode ? '1px solid #142B53' : '1px solid #E5E7EB',
            borderRadius: '4px',
            boxShadow: 'none',
            ':hover': {
                border: isDarkMode ? '1px solid #142B53' : '1px solid #E5E7EB',
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
            padding: '0px',
            color: isDarkMode ? '#E5E6E8' : '#fff',
            fontFamily: 'Open Sans',
            fontSize: "14px"
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            padding: '0px !important',
        }
        ),
        menu: (provided) => ({
            ...provided,
            backgroundColor: isDarkMode ? '#001221' : '#FFF',
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: 'Open Sans',
            fontSize: "14px",
            padding: '8px 12px',
            fontWeight: state.isSelected ? 600 : 400,
            backgroundColor: state.isSelected
                ? (isDarkMode ? '#112547' : '#D1DAEB')
                : 'transparent',
            color: isDarkMode ? '#E5E6E8' : state.isSelected ? '#142B53' : '#000',
            ':hover': {
                backgroundColor: state.isSelected
                    ? (isDarkMode ? '#112547' : '#D1DAEB')
                    : (isDarkMode ? '#112547' : '#F1F1F1'),
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: isDarkMode ? '#E5E6E8' : '#000',
            fontFamily: 'Open Sans',
            fontSize: "14px"
        }),
    });

    return (
        <Select
            classNamePrefix={`netops__react__select  ${user?.themePreference === 'dark' ? 'dark__mode' : ''} ${props.classNamePrefix || ''}${isRequired ? ' react__select__required' : ''}`}
            styles={getCustomStyles(isDarkMode)}
            {...props}
        />
    );
}

export default NetopsReactSelect
