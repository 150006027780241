
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ReactComponent as TenantIcon } from "assets/icons/tenant.svg";
import { ReactComponent as InventoryIcon } from "assets/icons/inventory.svg";
import { ReactComponent as MerakiIcon } from "assets/icons/meraki.svg";
import { ReactComponent as WUGIcon } from "assets/icons/wug.svg";
import { ReactComponent as CradlePointIcon } from "assets/icons/cradlepoint.svg";
import { ReactComponent as FortinetIcon } from "assets/icons/fortinet.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'linear-gradient(135deg, #244C91, #3678ea)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage: 'linear-gradient(135deg, #244C91, #3678ea)',
    }),
}));
const ColorlibStepIcon = (props) => {
    const { active, completed, className, showDeviceIcon, showCircuitIcon} = props;
    
    if (showDeviceIcon) {
        const icons = {
            1: <InventoryIcon className="stepper__icon" />,
            2: <MerakiIcon className="stepper__icon" />,
        };
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                   {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    if (showCircuitIcon) {
        const icons = {
            1: <MerakiIcon className="stepper__icon" />,
        };
        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                   {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }
    const icons = {
        1: <TenantIcon className="stepper__icon" />,
        2:<FontAwesomeIcon icon="fa-solid fa-ticket"  className='stepper__icon stepper__ticket__icon' style={{color:"inherit"}}/>,
        3: <InventoryIcon className="stepper__icon" />,
        4: <WUGIcon className="stepper__icon" />,
        5: <MerakiIcon className="stepper__icon" />,
        6: <CradlePointIcon className="stepper__icon" />,
        7: <FortinetIcon className="stepper__icon" />
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}
ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};
export default ColorlibStepIcon;