import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import {ReactComponent as NetopsBarIcon} from "../../../netopsStyles/assets/bar.svg"
import {ReactComponent as NetopsPieIcon} from "../../../netopsStyles/assets/pie.svg"

const NetopsDashboardChartPreference = ({ chartPreference, setChartPreference }) => {
    const user = useSelector(state => state?.user);

    const preferenceOptions = useMemo(() =>{
        return [
            {
                id: 'donut',
                title: 'Donut Chart',
                icon: <NetopsPieIcon className="icon pie" />,
                chartPreferenceId: 'donut',
            },
            {
                id: 'bar',
                title: 'Bar Chart',
                icon: <NetopsBarIcon  className="icon" />,
                chartPreferenceId: 'bar',
            }
        ]
    }, [])

    return (
        <Box className={`chart__preference__wrapper ${user?.themePreference === 'dark' ? 'dark__mode' : ''}`}>
            {preferenceOptions && preferenceOptions?.length > 0 && preferenceOptions?.map(item =>{
                const { chartPreferenceId, title, icon, id } = item
                return (
                    <Tooltip title={title} key={id}>
                        <IconButton sx={{borderRadius:"unset"}} disableRipple={true} className={`tab ${chartPreference === chartPreferenceId ? 'active' : ''}`} onClick={(e) => {
                            e?.stopPropagation()
                            setChartPreference(chartPreferenceId)
                        }}>
                            {icon}
                        </IconButton>
                    </Tooltip>
                )
            }) }
        </Box>
    )
}

export default NetopsDashboardChartPreference
