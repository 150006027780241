import Grid from "@mui/material/Grid";
import React from "react";
import MultiSelectDropDown from "components/common/MultiSelectDropDown";

export const ServiceFilterList = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "wug",
    label: "Circuits",
  },
  {
    value: "cradlepoint",
    label: "Cradlepoint",
  },
  {
    value: "fortinet",
    label: "Fortinet",
  },
  {
    value: "velocloud",
    label: "SDWAN",
  },
  {
    value: "meraki",
    label: "WiFi",
  },
];

const FilterStatusAndService = ({
	style,
	filterConfigurations,
	onApplyClick,
	totalFilterCount,
	fromDeviceScreen,
}) => {
	
	const renderFilter = ({ placeholder, options, handler, value, disableApply, containerWidth,defaultFilter, highlightFilter}) => (
		<Grid
			md={12/totalFilterCount}
			item
		>
			<MultiSelectDropDown
				controlShouldRenderValue={false}
				onChangeValue={(val, action) => handler(val, action)}
				value={value}
				defaultFilter={defaultFilter}
				options={options}
				placeholder={placeholder}
				styles={{ width: "94%" }}
				onApplyClick={onApplyClick}
				disableApply={disableApply}
				highlightFilter={highlightFilter && !fromDeviceScreen}
				containerWidth = {containerWidth}
			/>
		</Grid>
	);

	return (
			<Grid style={style} container spacing="10px" className="flex-start flex_wrap">
				{filterConfigurations?.map(
					(filterConfig) =>
						filterConfig.isEnabled && renderFilter({ ...filterConfig, totalFilterCount })
				)}
			</Grid>
	);
};

  export default FilterStatusAndService;
