import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { Box } from '@mui/material';
import { MuiThemedCircularProgress } from './MuiThemedComponents';
import { ChartNoData } from 'components/pages/dashboard/NetopsDashboardGraph';

export const highchartColors = {
    "maint": "#EAB308",
    "degraded": "#6B7280",
    "n/a": "#5492F7",
    "up": "#10B981",
    "down": "#EF4444",
    "devices":"#06B6D4",
    "sites":"#3B82F6",
    "tenants":"#EAB308",
    "links":"#047857",
    "active":"#047857",
    "offline":"#3B82F6",
    "planned":"#244C91",
    "staged":"#0EA5E9",
    "inventory":"#10B981",
    "decommissioning":"#BFDBFE",
    "reserved":"#D1FAE5",
    "deprecated":'#F43F5E',
    "sdwan":"#047857",
    "wifi":"#EAB308",
    "circuits":"#3B82F6",
    "cradlepoint":"#06B6D4",
    "fortinet":"#10B981",
    "sangoma":"#3B82F6",
    "yealink":"#078855",
    "polycom":"#FE3D16",
    "beta" : "rgb(255, 170, 113)",
    "testing" : "#fbbd00",
    "gateways": "#c9bd1c",
    "phones":  "#a129b5",
    "stable":  "#00a134",
    "others": "#4C5863",
    "no tag": "#4C5863",
}

export const highchartDarkColors ={
    "maint": "#FBBD00",
    "degraded": "#4C5863",
    "n/a": "#158FCF",
    "up": "#099F24",
    "down": "#C70816",
    "devices":"#06B6D4",
    "sites":"#3B82F6",
    "tenants":"#EAB308",
    "links":"#047857",
    "active":"#047857",
    "offline":"#3B82F6",
    "planned":"#244C91",
    "staged":"#0EA5E9",
    "inventory":"#10B981",
    "decommissioning":"#BFDBFE",
    "reserved":"#D1FAE5",
    "deprecated":'#F43F5E',
    "sdwan":"#047857",
    "wifi":"#EAB308",
    "circuits":"#3B82F6",
    "cradlepoint":"#06B6D4",
    "fortinet":"#10B981",
    "sangoma":"#3B82F6",
    "yealink":"#078855",
    "polycom":"#FE3D16",
   "beta" : "rgb(255, 170, 113)",
    "testing" : "#fbbd00",
    "gateways": "#c9bd1c",
    "stable":  "#00a134",
    "phones":  "#a129b5",
    "others": "#4C5863",
    "no tag": "#4C5863",

}

const NetopsHighCharts = ({ isLoading, options, noData }) => {
    const highchartsContainerRef = useRef(null);
    const user = useSelector(state => state?.user);
    const [refreshKey, setRefreshKey] = useState(0);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    const updateContainerSize = () => {
        if (highchartsContainerRef.current) {
            const { offsetWidth, offsetHeight } = highchartsContainerRef.current;
            setContainerSize({ width: offsetWidth, height: offsetHeight });
        }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            updateContainerSize();
            setRefreshKey(Math.random() + new Date()); 

        })        
        if (highchartsContainerRef?.current) {
            resizeObserver.observe(highchartsContainerRef?.current);
        }


        return () => {
            if (highchartsContainerRef?.current) {
                resizeObserver.unobserve(highchartsContainerRef?.current);
                updateContainerSize(); 

            }
        }
        
    }, []);

    // TODO REMINDER: on open and close of sidebar it should resize back for bar chart container size

    // useEffect(() =>{
    //     updateContainerSize();
    //     setRefreshKey(Math.random() + new Date()); 
    // },[user?.isSidebarOpen])

    const updatedOptions = useMemo(() => ({
        ...options,
        chart: {
            ...options.chart,
            width: options.chart?.width || containerSize.width,
            height: options.chart?.height || containerSize.height,
            animation: false, 
        },
        plotOptions: {
            ...options.plotOptions,
            series: {
                ...options.plotOptions?.series,
                animation: false, 
            },
        },
    }), [containerSize, options]);
    
    

    return (
        <Box ref={highchartsContainerRef} className={`${user?.themePreference === "dark" ? "dark__mode" : ""}  highcharts__graph__container`} >
            {isLoading ?
                <MuiThemedCircularProgress /> :
                noData
                    ? 
                    <ChartNoData /> :
                    <HighchartsReact className={`${user?.themePreference === "dark" ? "dark__mode" : ""} netops__highcharts`} key={refreshKey} highcharts={Highcharts} options={updatedOptions} />
            }
        </Box>
    )
}

export default NetopsHighCharts
