import Box from '@mui/material/Box';
import ProgressLoading from "components/common/ProgressLoading";
import FormPopup from "components/common/modal/FormPopup";
import Header from "components/common/modal/Header";
import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchLinkHistoryData, setDeviceDetails, setSitename } from "store/devices";
import SelectDropDown from "../../common/SelectDropdown.js";
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import MultiSelectDropDown from 'components/common/MultiSelectDropDown.js';
import  { StyledTooltip } from 'components/common/TooltipLabel/TooltipLabel.js';
import { MuiTypography, ReactDataTableStyles } from 'components/common/MuiThemedComponents.jsx';
import Timer from "components/component/link/Timer";
import StatusComponent from 'components/common/statusComponent.js';

const customStyles = {
	tableWrapper: {
		height: "auto",
		color: "#000",
	},
};

export const DurationFormat = d => {
	let year = ''; let month = ''; let day = ''; let hour = ''; let min = ''; let week = '';
	if (d.years > 0) year = `${d.years + (d.years > 1 ? ' years ' : ' year ')}`
	if (d.months > 0) month = `${d.months + (d.months > 1 ? ' months ' : ' month ')}`
	if (d.weeks > 0) week = `${d.weeks + (d.weeks > 1 ? ' weeks ' : ' week ')}`
	if (d.hours > 0) hour = `${d.hours + (d.hours > 1 ? ' hours ' : ' hour ')}`
	if (d.minutes > 0) min = `${d.minutes + (d.minutes > 1 ? ' minutes ' : ' minute ')}`
	if (d.days > 0) day = `${d.days + (d.days > 1 ? ' days ' : ' day ')}`
	if((year === '' && month === '' && day === '' && hour === '' && min === '' && d.seconds > 1)) return Math.floor(d.seconds) + ' seconds';
	if ((year === '' && month === '' && day === '' && hour === '' && min === '')) return 0 + ' minute'

	if (d.days === 0  && d.years === 0 && d.months === 0 && d.weeks === 0)   {
            
		return (hour + min)

	}

	return (year + month + week + day)
}

export const statusOptions = [
	{value: "all", label:  "All"},
	{value: "up", label:  "Up"},
	{value: "down", label: "Down"},
	{value: "maint", label:"Maintenance"}]

export const dateOptions = [
	{ value: 'all', label: 'All' }, { value: 'today', label: 'Today' }, { value: 'week', label: 'This Week' }, { value: 'month', label: 'This Month' }]

const columns = [
	{
		name: "Status",
		sortable: true,
		wrap: true,
		width: 130,
		selector: (row) => row?.status,
		cell: (row) => <StatusComponent status = {row.status}/>,
			
	},
	{
		selector: (row) => row?.created_at,
		cell: (row) => row?.created_at ? DateTime.fromMillis(parseInt(row?.created_at)).toFormat("yyyy-MM-dd h:mm a") : "--",
		name: "DATETIME (UTC)",
		id: "time",
		sortable: true,
		wrap: true,
		width: 130,
	},
	{
		cell: (row) =>  {
			let date = row?.created_at && row?.last_status_time !== null ? Interval.fromDateTimes(DateTime.fromMillis(parseInt(row?.created_at)), row?.last_status_time).toDuration(['years', 'months', 'weeks', 'days',
				'hours', 'minutes', 'seconds']).toObject()
				: "--";				
			let format = date === '--' ? <Timer targetDate={row.created_at} /> : DurationFormat(date);	
			return (
				<span>
					{format}
				</span>
			)
		},


		name: "duration",
		id: "up/down_duration",
		sortable: false,
		wrap: true,
		width: 130,
	},
];

function LinkHistory({ open, handleClosePopup, rowData }) {
	const navigate = useNavigate();
	const [tableColumns, setTableColumns] = useState([]);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [linkData, setlinkData] = useState([]);
	const [linkRefData, setLinkRefData] = useState([]);
	const [dateFilter, setDateFilter] = useState([]);
	const [statusFilter, setStatusFilter] = useState([]);
	const [wifiName, setWifiname] = useState('');
	const user = useSelector((state) => state.user);

	useEffect(() => {
		setTableColumns(columns);
	}, []);

	const getLinkHistoryById = async () => {
		await setIsLoading(true);
		let res = await dispatch(fetchLinkHistoryData({id: rowData?.vco_interface_id ? rowData?.vco_interface_id : (rowData?.serial ? rowData?.serial : (rowData?.device_id ? rowData?.device_id : "")), req:{siteId: rowData?.site_id, tenantId: rowData?.tenant_id, device_id : rowData?.device_id, service: rowData?.service}}), );
		if (res?.payload?.data) {
			setWifiname(res?.payload?.deviceName)
			let data = res?.payload?.data?.sort((a, b) => { return (b.created_at - a.created_at) })
			data = data?.map((item, index) => {
				let last_status_time = res?.payload?.data[index - 1]
				return (
					{
						...item,
						last_status_time: last_status_time && index !== 0 ? DateTime.fromMillis(parseInt(last_status_time?.created_at)) : null,
					})
			})
			setlinkData(data);
			setLinkRefData(data)
		}
		await setIsLoading(false)
	};

	useEffect(() => {

		getLinkHistoryById();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowData]);

	const onChangeDateFilter = (option) => {
		setDateFilter(option)
        let data = [...linkRefData];	
		let stFilter = statusFilter?.map(i => {return i.value.toLowerCase()})
		if(option?.value === 'today'){
		data = data.filter(item => {return DateTime.fromMillis(parseInt(item?.created_at)).hasSame(DateTime.now(), 'day')});
		}
		else if (option?.value === 'week') {
			data = data.filter(item => { return (DateTime.fromMillis(parseInt(item?.created_at)) > DateTime.now().startOf('week') && DateTime.fromMillis(parseInt(item?.created_at)) < DateTime.now().endOf('week')) })
		}
		else if (option?.value === 'month') {
			data = data.filter(item => { return (DateTime.fromMillis(parseInt(item?.created_at)) > DateTime.now().startOf('month') && DateTime.fromMillis(parseInt(item?.created_at)) < DateTime.now().endOf('month')) })
		}
		if(!stFilter.includes('all') && stFilter.length > 0){
			data = data.filter(item => stFilter.includes(item?.status?.toLowerCase()))
		}
		setlinkData(data);
	}

	const onChangeStatusFilter = (option) => {
		setStatusFilter(option)
		let stFilter = option?.map(i => {return i.value.toLowerCase()})
        let data = [...linkRefData];	
		if(!stFilter.includes('all') && stFilter.length > 0){
			data = data.filter(item => stFilter.includes(item?.status?.toLowerCase()))
		}
		if (dateFilter?.value === 'today') { data = data.filter(item => { return DateTime.fromMillis(parseInt(item?.created_at)).hasSame(DateTime.now(), 'day') && item?.status === option?.value }); }
		else if (dateFilter?.value === 'week') {
			data = data.filter(item => { return (DateTime.fromMillis(parseInt(item?.created_at)) > DateTime.now().startOf('week') && DateTime.fromMillis(parseInt(item?.created_at)) < DateTime.now().endOf('week')) && item?.status === option?.value })
		}
		else if (dateFilter?.value === 'month') {
			data = data.filter(item => { return (DateTime.fromMillis(parseInt(item?.created_at)) > DateTime.now().startOf('month') && DateTime.fromMillis(parseInt(item?.created_at)) < DateTime.now().endOf('month')) && item?.status === option?.value })
		}

		setlinkData(data);
	}



	createTheme('table__dark__theme',
		ReactDataTableStyles
	);

	return (
		<FormPopup open={open} size={"modal_size"} className={"link_wrapper flapping_history_popup"}>
			<Header text={rowData?.callingFrom === 'circuits' ? "Flapping History of Circuit" : "Flapping History of Device"} subText={"(LAST 1 MONTH)"} close={handleClosePopup} />

			{rowData?.site_id ?
				<Box className=" site_service_popup flex-start link_heading">
					<MuiTypography className="head">Site Display Name : </MuiTypography>	
					<Button className="text" onClick={() => {
						navigate('/nf-status/site', { state: { siteName: rowData?.site_id, siteDisplayName: rowData?.site_display_name ? rowData?.site_display_name : rowData?.site_name } });
						dispatch(setSitename(rowData?.site_id))
					}}>
						<StyledTooltip placement="right" title={`Site Name: ${rowData?.site_name}`} >
							<span className='label__loader'>
								{rowData?.site_display_name ? rowData?.site_display_name : rowData?.site_name}
							</span>
							</StyledTooltip>
						</Button>
				
				</Box> : null
			}

			<Box className="site_service_popup_name flex-start link_heading ">
				<MuiTypography className="head" >{rowData?.callingFrom === 'circuits' ? "Circuit Name : " : "Device Name : "}</MuiTypography>
				<Button className="text" onClick={() => {
					navigate('/nf-status/inventory', { state: { deviceName: rowData.netbox_device_data_id, serviceName: rowData?.service } });
					dispatch(setDeviceDetails({ deviceName: rowData.netbox_device_data_id, serviceName: rowData?.service }))
				}}>{rowData?.name}</Button>
			</Box>
			{
				rowData?.service == "meraki" ? <Box className="site_service_popup_name flex-start link_heading ">
					<MuiTypography className="head" >Network Name :</MuiTypography>
					{isLoading ? <div className="loader" style={{ marginLeft: '2%' }}></div> :
						<Typography className="text" style={{color:'#1976d2'}}>{wifiName ? wifiName : '--'}</Typography>
					}
				</Box> : null

			}
			<Box className="link_heading_wrap">
				<Box className="site_service_popup flex-start link_heading">
					<MuiTypography className="head">Count : </MuiTypography>
					<MuiTypography className="text">{linkData?.length}</MuiTypography>
				</Box>
				<Box className="site_service_popup flex-start link_heading">
					<div className='flapping_status_filter'>
					<MultiSelectDropDown options={statusOptions} containerWidth={'200px'} onChangeValue={onChangeStatusFilter} placeholder={"Status..."} value={statusFilter} 
					
					/></div>
					<SelectDropDown options={dateOptions} onChange={onChangeDateFilter} placeholder={"Date Range..."}  />
				</Box>
			</Box>
			<Box className="formWrapper" p={1}>
				<DataTable
					className={`sync__report rdt_table_wrap ${user?.themePreference === 'dark' ? 'rdt__table__darkMode' : ''} `}
					columns={tableColumns}
					data={linkData}
					fixedHeader
					customStyles={customStyles}
					progressPending={isLoading ? true : false}
					progressComponent={<ProgressLoading />}
					paginationPerPage={20}
					paginationRowsPerPageOptions={[20, 50, 100, 200, 300, 500]}
					theme={user?.themePreference === 'dark' ?"table__dark__theme": null}
				/>
			</Box>
		</FormPopup>
	);
}

export default LinkHistory;
