import Box from "@mui/material/Box";
import ProgressLoading from "components/common/ProgressLoading";
import FormPopup from "components/common/modal/FormPopup";
import Header from "components/common/modal/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, } from "react-redux";
import { useNavigate } from "react-router";
import { fetchSiteServiceCount, setDeviceDetails } from "store/devices";
import './sites.css';
import TooltipLabel, { StyledTooltip } from "components/common/TooltipLabel/TooltipLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLayout from "components/common/Button";
import { MuiTypography } from "components/common/MuiThemedComponents";
import { Popper, Typography } from "@mui/material";
import CopyTextCell from "components/common/CopyTextCell/CopyTextCell";
import { ClickAwayListener } from "@mui/material";

function ServicePopup({ handleClosePopup, open, rowData, callingFrom, renderPopper, anchorEl, gateWayEnable }) {
	const dispatch = useDispatch();
	const [siteServiceData, setSiteServiceData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const user = useSelector((state) => state.user);
	const phoneEnabled = useSelector((state) => state.devices?.tenants).some(i => i.id == rowData?.tenant?.id && i.custom_fields?.endpoint_monitoring_enabled);
	const mspEnabled = useSelector((state) => state.devices?.tenants).some(i => i.id == rowData?.tenant?.id && (i.custom_fields?.fortinet_adom_oid || i.custom_fields?.vco_enterprise_id ||  i.custom_fields?.wug_device_group_id));	
	const navigate = useNavigate();

	const getLinkHistoryById = async () => {	
		setIsLoading(true);
		try {
			let res = await dispatch(fetchSiteServiceCount({ id: rowData?.id, tenantId: user.tenant?.id, gatewayEnabled: gateWayEnable, mspEnabled: mspEnabled }));
			if (res?.payload) {
				setSiteServiceData(formatData(res?.payload));
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setIsLoading(false);
		}

	};

	const commonStatus = (upCount, downCount, degradedCount, maintCount, otherCount, service) =>{
		const statuses = [
			{ count: upCount, color: "green", icon: "fa-arrow-up", isEnabled: true },
			{ count: downCount, color: "red", icon: "fa-arrow-down", isEnabled: true},
			{ count: degradedCount, color: "#ff7917", icon: "fa-arrow-down", isEnabled: true && service !== "PHONE"},
			{ 
				count: maintCount, 
				color: "#fbbd00", 
				icon: "fa-arrow-down", 
				isEnabled: user?.tenant?.name === "Sangoma" && service !== "PHONE"
			},
			{ 
				count: otherCount, 
				color: "gray", 
				icon: "fa-arrow-down", 
				isEnabled: service === "FORTINET" || service === "CRADLEPOINT" || service === "GATEWAY" || service === "PHONE"
			},
		];
		return(
			<div className="statusCounts">
				{statuses?.map((status, index) =>
					(status?.isEnabled) && (
						<div key={index} className="statusPair">
							<span>{status.count}</span>
							<FontAwesomeIcon icon={`fa-solid ${status.icon}`} color={status.color} />
						</div>
					)
				)}

			</div>
		)
	}

	const formatData = (dataList) => {
		
		let rows = [
			{
				name: "Physical Address",
				value: rowData?.physical_address ?
					<div className="site__flex">
						<TooltipLabel text={rowData?.physical_address} />
						<a rel="noreferrer" href={`https://www.google.com/maps?q=${rowData?.physical_address}}`} style={{textDecoration: 'none'}}
							target="_blank">
							<ButtonLayout text={`Map`} buttonStyleType={"filledMap"} icon={<FontAwesomeIcon icon="fa-solid fa-location-dot"  style={{marginRight: '4px'}} className="iconPadding" />} />
						</a>
					</div>
				: '--',
				isEnabled: !(callingFrom === 'link-monitor')
			},
			{
				name: "Shipping Address",
				value: rowData?.shipping_address ? <TooltipLabel text = {rowData?.shipping_address}/> : '--',
				isEnabled: !(callingFrom === 'link-monitor')
			},
			{
				name: "GPS Coordinates",
				value: rowData?.latitude && rowData?.longitude ? 
					<div className="site__flex">
						<TooltipLabel text={rowData?.latitude + ', ' + rowData?.longitude} />
						<a rel="noreferrer" href={`https://www.google.com/maps?q=${rowData?.latitude},${rowData?.longitude}`} target="_blank" style={{textDecoration: 'none'}}>
							<ButtonLayout text={`Map`} buttonStyleType={"filledMap"} icon={<FontAwesomeIcon icon="fa-solid fa-location-dot" style={{marginRight: '4px'}} className="iconPadding" />} />
						</a>
					</div> : '--',
					isEnabled: !(callingFrom === 'link-monitor')
			},
			{
				name: "SDWAN",
				value: <div className="site__flex site_details_flex" >
					<div onClick={() => dataList?.velo_cloud?.velo_cloud > 0 ? handleSelectRow('SDWAN', dataList) : {}} style={{
						cursor: dataList?.velo_cloud?.velo_cloud > 0 ?'pointer':'default',
						textDecoration: dataList?.velo_cloud?.velo_cloud > 0 ? '' : 'none',
						}} className="site__serviceName">
						{dataList?.velo_cloud?.velo_cloud + (dataList?.velo_cloud?.velo_cloud <= 1 ? ' Device' : ' Devices')}
					</div>
					<div>
						{dataList?.velo_cloud?.velo_cloud > 0 ?
							commonStatus(dataList?.velo_cloud?.velo_cloudStatusCount?.upCount, dataList?.velo_cloud?.velo_cloudStatusCount?.downCount, dataList?.velo_cloud?.velo_cloudStatusCount?.degradedCount, dataList?.velo_cloud?.velo_cloudStatusCount?.maintCount)
							: null

						}
					</div>
				</div>,
				isEnabled: mspEnabled
			},
			{
				name: "WIFI",
				value: <div className="site__flex site_details_flex">
				<div onClick={() => dataList?.meraki?.meraki > 0 ? handleSelectRow('WIFI', dataList):{}} className="site__serviceName" style={{
					cursor: dataList?.meraki?.meraki > 0 ?'pointer':'default',
					textDecoration: dataList?.meraki?.meraki > 0 ?'':'none',
					}}>
					{dataList?.meraki?.meraki + (dataList?.meraki?.meraki <= 1 ? ' Device' : ' Devices')}
				</div>
				<div>
					{dataList?.meraki?.meraki > 0 ?
					commonStatus(dataList?.meraki?.merakiStatusCount?.upCount, dataList?.meraki?.merakiStatusCount?.downCount, dataList?.meraki?.merakiStatusCount?.degradedCount, dataList?.meraki?.merakiStatusCount?.maintCount)
						: null

					}
					</div>
				</div>,
				isEnabled: mspEnabled
			},
			{
				name: "CIRCUITS",
				value:<div className="site__flex site_details_flex">
				<div onClick={() => dataList?.wug?.wug > 0 ?  handleSelectRow('CIRCUITS', dataList): {}} className="site__serviceName" style={{
					cursor: dataList?.wug?.wug > 0 ?'pointer':'default',
					textDecoration: dataList?.wug?.wug > 0 ?'':'none',
					}}>
					{dataList?.wug?.wug + (dataList?.wug?.wug <= 1 ? ' Device' : ' Devices')}
				</div>
				<div>
					{dataList?.wug?.wug > 0 ?
					commonStatus(dataList?.wug?.wugDataStatusCount?.upCount, dataList?.wug?.wugDataStatusCount?.downCount, dataList?.wug?.wugDataStatusCount?.degradedCount, dataList?.wug?.wugDataStatusCount?.maintCount)
						: null

					}
					</div>
					</div>,
				isEnabled: mspEnabled
			},
			{
				name: "CRADLEPOINT",
				value: 
					<div className="site__flex site_details_flex_others">
					<div onClick={() => dataList?.cradlepoint?.cradlepoint > 0 ? handleSelectRow('CRADLEPOINT', dataList) : {}} className="site__serviceName"  style={{
						cursor: dataList?.cradlepoint?.cradlepoint > 0 ?'pointer':'default',
						textDecoration: dataList?.cradlepoint?.cradlepoint > 0 ? '' : 'none',
						}}>
						{dataList?.cradlepoint?.cradlepoint + (dataList?.cradlepoint?.cradlepoint <= 1 ? ' Device' : ' Devices')}
					</div>
					<div>
					{dataList?.cradlepoint?.cradlepoint > 0 ?
					commonStatus(dataList?.cradlepoint?.cradlepointStatusCount?.upCount, dataList?.cradlepoint?.cradlepointStatusCount?.downCount, dataList?.cradlepoint?.cradlepointStatusCount?.degradedCount, dataList?.cradlepoint?.cradlepointStatusCount?.maintCount, dataList?.cradlepoint?.cradlepointStatusCount?.otherCount, 'CRADLEPOINT')
						: null

					}
					</div>
				
					</div>,
				isEnabled: mspEnabled
			},
			{
				name: "FORTINET",
				value: <div className="site__flex site_details_flex_others">
					<div onClick={() =>dataList?.fortinet?.fortinet > 0 ? handleSelectRow('FORTINET', dataList): {}} className="site__serviceName" style={{
						cursor: dataList?.fortinet?.fortinet > 0 ?'pointer':'default',
						textDecoration: dataList?.fortinet?.fortinet > 0 ? '' : 'none',
						}}>
						{dataList?.fortinet?.fortinet + (dataList?.fortinet?.fortinet <= 1 ? ' Device' : ' Devices')}
					</div>
					<div>
					{dataList?.fortinet?.fortinet > 0 ?
					commonStatus(dataList?.fortinet?.fortinetStatusCount?.upCount, dataList?.fortinet?.fortinetStatusCount?.downCount, dataList?.fortinet?.fortinetStatusCount?.degradedCount, dataList?.fortinet?.fortinetStatusCount?.maintCount, dataList?.fortinet?.fortinetStatusCount?.otherCount, 'FORTINET')
						: null
					}
					</div>
				</div>,
				isEnabled: mspEnabled
			},
			{
				name: "SWITCH",
				value: <div className="site_details_flex">
					<div onClick={() =>dataList?.switch?.switchCount > 0 ? handleSelectRow('SWITCH', dataList): {}} className="site__serviceName" style={{
						cursor: dataList?.switch?.switchCount  > 0 ?'pointer':'default',
						textDecoration: dataList?.switch?.switchCount  > 0 ? '' : 'none',
						}}>
						{dataList?.switch?.switchCount + (dataList?.switch?.switchCount <= 1 ? ' Device' : ' Devices')}
					</div>
				</div>,
				isEnabled: mspEnabled
			},
			{
				name: "Endpoint",
				value: <div className="site__flex site_details_flex">
					<div onClick={() =>dataList?.phone?.phoneCount > 0 ? handleSelectRow('PHONE', dataList): {}} className="site__serviceName" style={{
						cursor: dataList?.phone?.phoneCount  > 0 ?'pointer':'default',
						textDecoration: dataList?.phone?.phoneCount  > 0 ? '' : 'none',
						}}>
						{dataList?.phone?.phoneCount + (dataList?.phone?.phoneCount <= 1 ? ' Endpoint' : ' Endpoints')}
					</div>
					<div>
					{dataList?.phone?.phoneCount > 0 ?
					commonStatus(dataList?.phone?.phoneStatusCount?.upCount, dataList?.phone?.phoneStatusCount?.downCount, '', '', dataList?.phone?.phoneStatusCount?.otherCount, 'PHONE')
						: null
					}
					</div>
				</div>,
				isEnabled: phoneEnabled
			},
			{
				name: "Gateway",
				value: 
					<div className="site__flex site_details_flex">
						<div onClick={() => dataList?.gateway?.gateway > 0 ? handleSelectRow('GATEWAY', dataList) : {}} className="site__serviceName" style={{
							whiteSpace:"nowrap",
							paddingRight:"10px",
							cursor: dataList?.gateway?.gateway > 0 ? 'pointer' : 'default',
							textDecoration: dataList?.gateway?.gateway > 0 ? '' : 'none',
						}}>
							{dataList?.gateway?.gateway + (dataList?.gateway?.gateway <= 1 ? ' Gateway' : ' Gateways')}
						</div>
						<div>
							{dataList?.gateway?.gateway > 0 ?
								commonStatus(dataList?.gateway?.gatewayStatusCount?.upCount, dataList?.gateway?.gatewayStatusCount?.downCount, dataList?.gateway?.gatewayStatusCount?.degradedCount, dataList?.gateway?.gatewayStatusCount?.maintCount, 
									dataList?.gateway?.gatewayStatusCount?.otherCount ,'GATEWAY')
								: null
							}
						</div>

					</div>,
				isEnabled: gateWayEnable
			}
		];

		return rows;
	};
	
	const handleSelectRow = (service_name, list) => {
		let filterdata = []
		let headerData = {};
		if (service_name == "SDWAN") {
			filterdata = list?.velo_cloud?.device;
			headerData = {up: list?.velo_cloud?.velo_cloudStatusCount?.upCount, down: list?.velo_cloud?.velo_cloudStatusCount?.downCount, 
				degraded: list?.velo_cloud?.velo_cloudStatusCount?.degradedCount, maint: list?.velo_cloud?.velo_cloudStatusCount?.maintCount}
		} else if (service_name == "WIFI") {
			filterdata = list?.meraki?.device;
			headerData = {up: list?.meraki?.merakiStatusCount?.upCount, down: list?.meraki?.merakiStatusCount?.downCount, 
				degraded: list?.meraki?.merakiStatusCount?.degradedCount, maint: list?.meraki?.merakiStatusCount?.maintCount}
		} else if (service_name == "CIRCUITS") {
			filterdata = list?.wug?.device;
			headerData = {up: list?.wug?.wugDataStatusCount?.upCount, down: list?.wug?.wugDataStatusCount?.downCount, 
				degraded: list?.wug?.wugDataStatusCount?.degradedCount, maint: list?.wug?.wugDataStatusCount?.maintCount}
		} else if (service_name == "CRADLEPOINT") {
			filterdata = list?.cradlepoint?.device;
			headerData = {up: list?.cradlepoint?.cradlepointStatusCount?.upCount, down: list?.cradlepoint?.cradlepointStatusCount?.downCount, 
				degraded: list?.cradlepoint?.cradlepointStatusCount?.degradedCount, maint: list?.cradlepoint?.cradlepointStatusCount?.maintCount}
		} else if (service_name == "FORTINET") {		
			filterdata = list?.fortinet?.device;
			headerData = {up: list?.fortinet?.fortinetStatusCount?.upCount, down: list?.fortinet?.fortinetStatusCount?.downCount, 
				degraded: list?.fortinet?.fortinetStatusCount?.degradedCount, maint: list?.fortinet?.fortinetStatusCount?.maintCount, other: list?.fortinet?.fortinetStatusCount?.otherCount}
		} else if( service_name == "SWITCH"){
			filterdata = list?.switch?.device;
			headerData = {up: list?.switch?.switchStatusCount?.upCount, down: list?.switch?.switchStatusCount?.downCount, 
				degraded: list?.switch?.switchStatusCount?.degradedCount}
		}else if( service_name == "PHONE"){
			filterdata = list?.phone?.device;
			headerData = {up: list?.phone?.phoneStatusCount?.upCount, down: list?.phone?.phoneStatusCount?.downCount, 
				other: list?.phone?.phoneStatusCount?.otherCount}
		}else if( service_name == "GATEWAY"){
			filterdata = list?.gateway?.device;
			const gatewayStatusCount = list?.gateway?.gatewayStatusCount;
			headerData = {up: gatewayStatusCount?.upCount, down: gatewayStatusCount?.downCount,
				degraded: gatewayStatusCount?.degradedCount, maint:gatewayStatusCount?.maintCount, totalCount: gatewayStatusCount?.upCount + gatewayStatusCount?.downCount+
			gatewayStatusCount?.otherCount+ gatewayStatusCount?.degradedCount}
		}
		const serviceNameMappings = {
			SDWAN:'velo_cloud',
			WIFI:'meraki',
			CIRCUITS:'wug',
			CRADLEPOINT:'cradlepoint',
			FORTINET:'fortinet',
			SWITCH:'switch',
			PHONE: 'phone',
			GATEWAY:'gateway'
		}
		
		if(service_name == "PHONE"){
			navigate('/nf-status/phones', { state: { 
				deviceData: filterdata, 
				site_name: rowData?.site_display_name ? rowData?.site_display_name : rowData?.name,
				siteId: rowData?.id,
				serviceName: serviceNameMappings[service_name],
				headerData
			} });
		}else if(service_name == "GATEWAY"){
			navigate('/nf-status/gateway', { state: { 
				deviceData: filterdata, 
				site_name: rowData?.site_display_name ? rowData?.site_display_name : rowData?.name,
				siteId: rowData?.id,
				callingFrom: 'sitePopup',
				serviceName: serviceNameMappings[service_name],
				headerData
			} });
		}else{
			navigate('/nf-status/inventory', { state: { 
				deviceData: filterdata, headerData: headerData, 
				site_name: rowData?.site_display_name ? rowData?.site_display_name : rowData?.name,
				siteId: rowData?.id,
				serviceName: serviceNameMappings[service_name],
			} });
		}
		dispatch(setDeviceDetails({ deviceData: filterdata }))
		handleClosePopup();
	}

	useEffect(() => {
		getLinkHistoryById();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowData?.id]);

	useEffect(() => {
        const tableWrap = document.querySelector('.rdt_table_wrap');

        const handleScroll = () => {
            if (open && renderPopper) {
                handleClosePopup();
            }
        };

        if (tableWrap) {
            tableWrap.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableWrap) {
                tableWrap.removeEventListener('scroll', handleScroll);
            }
        };
    }, [open, handleClosePopup, renderPopper]);

	const ServicePopupContent = () =>{
		return (
			<>
			<Header text={"Site Details"} close={handleClosePopup} /><Box className={'formWrapper'}>
				<Box className={'justify-between'}>
					<Box className="flex-start link_heading">
						<MuiTypography className="head">Site Display Name : </MuiTypography>
						<StyledTooltip placement="bottom" title={`Site Name: ${rowData?.name}`}>
							<Typography style={{color: '#244c91', marginLeft:'5px'}}>
								<CopyTextCell text={rowData?.site_display_name ? rowData?.site_display_name : rowData?.name} />
							</Typography>
						</StyledTooltip>
					</Box>

					{!(callingFrom === 'link-monitor') && mspEnabled &&
						<ButtonLayout handleClick={() => navigate('/nf-status/link-monitoring', {
							state: { callingFrom: 'sitePopup', siteId: rowData?.id, tenantId: rowData?.tenant?.id, siteName:  rowData?.site_display_name ? rowData?.site_display_name : rowData?.name}
						})} text={`Check Status`} buttonStyleType={"filledMap"} icon={<FontAwesomeIcon icon="fa-solid fa-table-list" className="siteIconPadding" />} />}
				</Box>

				<div className="site__table__wrap">
					<table className= {`site__table ${phoneEnabled ? 'site_table_height_phone' : 'site_table_height'}`}>
						<tbody>
							{isLoading && <ProgressLoading />}
							{!isLoading && siteServiceData?.map((i, key) => {
								if (i?.isEnabled) {
									return (<tr className="site__service__row" key={key}>
										<td className="site__service__td" style={{ width: '40%' }}>{i.name}</td>
										<td className="site__service__td" style={{ width: '60%', cursor: i.value === 0 ? 'default' : 'pointer' }}>{i.value}</td>
									</tr>);
								}

							})}
						</tbody>
					</table>
				</div>
			</Box></>
		)
	}

	return (
		renderPopper ? <Popper
			key={new Date()}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClosePopup}
			onClick={handleClosePopup}
			keepMounted={true}		
			PaperProps={{
				elevation: 0,			
			}}	
			placement="right-start"
			disablePortal={false}
			modifiers={{
				flip: {
					enabled: false
				},
				preventOverflow: {
					enabled: true,
					boundariesElement: "scrollParent"
				}
			}}
		>
			<ClickAwayListener onClickAway={() => {
				if (open) {
					handleClosePopup();
				}
			}}>
			<Box sx={{ minWidth: '500px', minHeight: '300px' , transform: 'none', position:'initial'}} 
			className={`modal  ${user?.themePreference === 'dark' ? "form__modal__darkMode " : ''} `}>
				<ServicePopupContent />			
			</Box>
			</ClickAwayListener>
		</Popper> :
		<FormPopup open={open} size={"modal_size"} className={"link_wrapper site__details__popup "} >
			<ServicePopupContent />
		</FormPopup>
	);
}

export default ServicePopup;
