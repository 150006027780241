import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@mui/material'
import ButtonLayout from 'components/common/Button'
import CopyTextCell from "components/common/CopyTextCell/CopyTextCell"
import { formatDate } from 'components/common/utils'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateTicketComments } from 'store/tickets'
import { ticketCardColors, typeMapper } from './ticketsConstants'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel'
import { toast } from 'react-toastify'


const removeCreatedByText = (text, ticketingSystem) => {
    if(!text)  {
        return null
    }
    const index = text?.indexOf('Added By:');
    if (index !== -1) {
        text = text?.substring(0, index);
    }
    return detectSystem(text, ticketingSystem, index);

};

const detectSystem = (text, ticketingSystem, index) => {


    let commentSystem;
    let searchTerm;
    const details = ticketCardColors[ticketingSystem];    
    searchTerm = details?.commentSearchTerm;

    if (searchTerm && text?.startsWith(searchTerm)) {
        commentSystem = details?.commentSearchSystem;  
        text = text.substring(searchTerm?.length).trim();
    } else if (index !== -1) {
          commentSystem= "netops"
    } else {
        commentSystem = details?.commentSystem;

    }

    return {
        commentValue: text?.trim(),
        commentSystem
    };
};


const getNameEmailFromComment = (comment, createdBy) => {
    if (comment && comment?.length > 0) {
        const addedByIndex = comment?.indexOf("Added By: ");
        const emailIndex = comment?.indexOf("Email: ");

        if (addedByIndex !== -1 && emailIndex !== -1) {
            const addedBySubstring = comment?.substring(addedByIndex + "Added By: ".length, emailIndex)?.trim();
            const emailSubstring = comment?.substring(emailIndex + "Email: ".length)?.trim();

            const parts = addedBySubstring?.split('(');
            const name = parts[0]?.trim();
            const role = parts[1]?.replace(')', '').trim();
            const email = emailSubstring;
            return { name, role, email };
        } else {
            return { name: createdBy, role: null, email: null };
        }
    }
};


const CommentComponent = ({ element, valueKey, redirectToLinkMonitoring, ticketingSystem, ticketId, ticketType, tenantId }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const createdByText = `\nAdded By: ${user?.name} (NetOps)\nEmail: ${user?.email}`;
    const originalComment = element?.comment;
    const createdBy = element?.createdBy
    const {commentValue = '', commentSystem = null} = removeCreatedByText(originalComment, ticketingSystem);
    
    const [value, setValue] = useState(commentValue);
    const [comment, setComment] = useState(commentValue)
    const [isEdit, setIsEdit] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const enableEdit = useMemo(() => {
        let commentedEmail = getNameEmailFromComment(originalComment, createdBy);
        const searchTerm = ticketCardColors[ticketingSystem]?.commentSearchTerm;
        if (searchTerm && originalComment?.startsWith(searchTerm)) {
            return false
        }
                
        return commentedEmail?.email === user?.email ;
    }, [originalComment, createdBy, ticketingSystem, user?.email]);

    const updateComment = useCallback(async () => {
        // if (canAccess('update', 'tickets')) {

            const updatePayload = {
                "id": element?.commentId,
                "comment": value + createdByText,
                type: typeMapper[valueKey],
                ticketingSystem: ticketingSystem,
                ticketId: ticketId,
                createdBy: element?.createdBy,
                createdDate: element?.createdDate,
                commentType: element?.commentType || 'external',
                tenantId: tenantId
            }
            setIsUpdating(true)
            try {
                const response = await dispatch(updateTicketComments(updatePayload));
                if (response?.payload?.updatedComment) {
                    let newComment = response?.payload?.updatedComment;
                    let commentWOCreatedBy = removeCreatedByText(newComment?.comment, ticketingSystem)?.commentValue;
                    setComment(commentWOCreatedBy);
                    setValue(commentWOCreatedBy)
                }
            } catch (error) {
                console.error(error)
            } finally {
                setIsUpdating(false)
                setIsEdit(false)
            }
        // }

    }, [element?.commentId, element?.createdBy, element?.createdDate, element?.commentType, value, createdByText, valueKey, ticketingSystem, ticketId, tenantId, dispatch])

    const parseCommentForTextEditor = (comment) => {
        if(comment) {
            const commentInfo = comment?.split("|");
            if (commentInfo && commentInfo.length === 1) {
                return commentInfo[0];
            } else if (commentInfo && commentInfo?.length > 1) {
                return commentInfo?.map(item => item?.trim()).join('\n');
            }
        }
    };

    const parseComment = useCallback((comment) => {
        const commentInfo = comment?.split("|");    
        if (commentInfo && commentInfo?.length == 1) {
            return [{ title: "Netops Comment", value: commentInfo?.[0] }]
        }
        else if (commentInfo && commentInfo?.length > 1) {
            const parsedCommentInfo = commentInfo && commentInfo?.length > 0 && commentInfo?.map(item => {
                // eslint-disable-next-line no-unsafe-optional-chaining
                let [title, value] = item?.split(/:(.+)/).map(item => item.trim());
                title = title?.trim();
                let newTitle = title?.toLowerCase() == "device" ? "Device Name" : title?.toLowerCase() === "ip" ? "IP Address" : title?.trim()
                return {
                    title: newTitle,
                    value: value ? value.trim()?.toLowerCase() == "down" ? "Down" : value?.trim() : ''
                };
            }
            )
            return parsedCommentInfo
        }
    }, [])

    const renderValue = (item) => {
        const value = item?.value || "--";
        let commentValue =comment && comment?.length > 0 && parseComment(comment);
        const ipAddress = commentValue?.find(i => i.title.toLowerCase() === "ip address");
       
        switch (item?.title?.toLowerCase()) {
            case "ip address":
                return <CopyTextCell text={value} />;
            case "device name":
                return (
                    <Typography
                        component="div"
                        className="description-value device_name"
                        onClick={(e) => {
                            e?.stopPropagation()
                            redirectToLinkMonitoring(value, ipAddress?.value)
                        }}
                    >
                        <TooltipLabel text={value} />
                    </Typography>
                );
            case "status":
                return (
                    <Typography component="div" className={`description-value ${value?.toLowerCase()}`}>
                        {value}
                    </Typography>
                );
            default:
                return <div className="comment__item__value">
                    <TooltipLabel text={value} />
                </div>;
        }
    };

    const titleInfo = useMemo(() =>
        getNameEmailFromComment(originalComment, createdBy),
        [originalComment, createdBy]);

    if (comment) {
        return (
            <Box sx={{borderLeft: `4px solid ${ticketCardColors?.[commentSystem?.toLowerCase()]?.commentBorderColor}`}} className='log__entry__wrapper'>
                <Box className='log__entry__wrapper-header'>
                    <Typography component={"p"} className='title'>
        
                        <span className='text'>
                        {titleInfo?.name}    
                            </span>                
                        {/* {titleInfo?.role && ` (${titleInfo?.role})`} */}
                        <span className='commenting__system__name'>
                            {` ${commentSystem?.toUpperCase()}`}
                            </span>
                    </Typography>

                    <Typography style={{ display: "flex", alignItems: "center" }}>
                        {enableEdit &&
                            <FontAwesomeIcon style={{ paddingRight: "10px", cursor: "pointer" }} icon="fa-solid fa-pen-to-square" onClick={(e) => { e?.stopPropagation(); setIsEdit(true); setValue(comment) }} />
                        }
                        <Typography className="log__entry__date">{ticketingSystem === "salesforce" ? formatDate(element?.lastModifiedDate) : formatDate(element?.createdDate)}</Typography>
                    </Typography>

                </Box>
                <Box className={`log__entry__message`} >
                    {!isEdit ?
                        <Typography className="comment">
                            {ticketType === "external" ? <Typography className="comment__item__value">
                                {comment && comment?.length > 0 && comment?.split('\n')?.map((line, index) => (
                                    <>
                                        {line}
                                        {index < comment?.split('\n').length - 1 && <br />}
                                    </>
                                ))}
                            </Typography> :
                             comment &&   parseComment(comment) && parseComment(comment)?.length > 0 &&
                                parseComment(comment)?.map((item, idx) => {
                                    if (item?.title === "Netops Comment") {
                                        return (
                                            <Typography key={idx} className="comment__item__value">{item?.value}</Typography>
                                        )
                                    } else {
                                        return (
                                            <Typography className="comment__item" key={idx}>
                                                <Typography className="comment__item__title">{item?.title}: &nbsp;</Typography>
                                                {renderValue(item)}
                                            </Typography>
                                        )
                                    }

                                })}
                        </Typography>
                        :
                        <>
                            <Box className='comment__edit__wrapper'>
                                <textarea
                                    onChange={(e) => {
                                        const title=valueKey == "work_notes" ? 'WorkNotes' : valueKey == "comments" ? "Comments" :''
                                        if(e?.target?.value?.length > 2000) {
                                            toast.error(`${title} length should be less than 2000 characters`)
                                            return
                                        }
                                        setValue(e?.target?.value) 
                                    }}
                                    value={parseCommentForTextEditor(value)}
                                    rows={3}
                                />
                            </Box>

                            <Box className='log__entry__wrapper-content-footer'>
                                <ButtonLayout
                                    disabled={value?.length === 0}
                                    handleClick={(e) => { e?.stopPropagation(); setIsEdit(false); setValue(commentValue) }}
                                    buttonStyleType={"outlined"} text={'Cancel'}>
                                </ButtonLayout>
                                <ButtonLayout buttonStyleType="filled"
                                    isLoading={isUpdating}
                                    handleClick={(e) => { e?.stopPropagation(); updateComment(); }}
                                    type="submit" text={"Update"}
                                    disabled={value?.length === 0}
                                >
                                </ButtonLayout>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        )

    }
}


export default CommentComponent
