/* eslint-disable no-undef */
import { Box, Checkbox, Grid } from '@mui/material'
import Breadcrumbs from 'components/common/Breadcrumps'
import CopyTextCell from 'components/common/CopyTextCell/CopyTextCell'
import { MuiThemedButton } from 'components/common/MuiThemedComponents'
import SearchInputField from 'components/common/SearchInputField'
import SelectDropDown from 'components/common/SelectDropdown'
import TableComponent from 'components/common/TableComponent/TableComponent'
import TooltipLabel from 'components/common/TooltipLabel/TooltipLabel'
import React, {  useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { createSiteBinding, fetchSitesData } from 'store/devices'
import { trimSpacesFromStartAndEnd } from 'utils/Utilities'

const PhoneMapping = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const tenants = useSelector((state) => state.devices?.tenants);
    const [gatewayUnmappedData, setGatewayUnmappedData] = useState([]);
    const [isGatewayLoading, setIsGatewayLoading] = useState(false);
    const [isGateWayMapping, setIsGatewayMapping] = useState(false);
    const [gatewayMappingData, setGatewayMappingData] = useState([]);
    const [searchInputValue, setSearchInputValue] = useState();
    const [paginationState, setPaginationState] = useState({
        offset: 1, limit: 20, sortField: 'serial_number', sortOrder: 'asc'
    });
    const [filterValues, setFilterValues] = useState({
        tenantFilter: [],
        siteFilter: [],
        filter: [],
        tenantOptionsList: [],
        siteOptionsList: [],
        isFetchingSites: false,
        isTenantRequired: false,
        isSiteRequired: false
    });

    const tableData = [
        {
            phone_company: 'Yealink',
            model : 'SIP-T57W',
            lines : '5',
            mac_address : '00:10:18:29:77:0',
            firmware_version : '4.1',
            ip_address : '192.168.1.2',
        },
        {
            phone_company: 'Yealink',
            model : 'SIP-T54W',
            lines : '4',
            mac_address : '00:10:18:39:89:0',
            firmware_version : '4.1',
            ip_address : '192.168.1.3',
        },
        {
            phone_company: 'Yealink',
            model : 'SIP-T57W',
            lines : '5',
            mac_address : '00:10:28:79:98:0',
            firmware_version : '4.2',
            ip_address : '192.168.1.8',
        },
        {
            phone_company: 'Yealink',
            model : ' SIP-T54W',
            lines : '4',
            mac_address : '00:11:18:39:34:1',
            firmware_version : '4.2',
            ip_address : '192.168.1.6',
        },
        {
            phone_company: 'Yealink',
            model : 'SIP-T54W',
            lines : '3',
            mac_address : ' 00:40:68:10:07:4',
            firmware_version : '4.1',
            ip_address : '192.168.1.2',

        },
        {
            phone_company: 'Yealink',
            model : 'SIP-T54W',
            lines : '3',
            mac_address : '00:40:68:10:66:5',
            firmware_version : '4.1',
            ip_address : '192.168.1.3',
        }
    ]

    const onChangeOfFilter = useCallback(async (key, val) => {
        setFilterValues((filter) => ({ ...filter, [key]: [val] }));
        if (key === "tenantFilter") {
            let siteList = [];
            setFilterValues((filter) => ({ ...filter, isFetchingSites: true }));
            try {
                const siteResponse = await dispatch(fetchSitesData({ tenantId: val?.value, offset: 1, limit: 2000, sortField: 'name', sortOrder: 'asc' })).unwrap();
                siteList = siteResponse?.data?.map(i => {
                    return { value: i.id, label: i.custom_fields?.display_name ? i?.custom_fields?.display_name : i.name };
                });
                setFilterValues((filter) => ({ ...filter, siteOptionsList: siteList }));
            } catch (error) {
                console.error("Error while fetching sites", error);
            } finally {
                setFilterValues((filter) => ({ ...filter, isFetchingSites: false }));
            }
        }
    }, [dispatch])

    const handleValidationError = useCallback((field, message) => {
        setFilterValues((filter) => ({ ...filter, [field]: true }));
        toast.error(message);
        setTimeout(() => {
            setFilterValues((filter) => ({ ...filter, [field]: false }));
        }, 4000);
    }, [])

    const formatData = useCallback((data) => {
        if (!data || data.length === 0) return [];

        const { tenantFilter, siteFilter } = filterValues;
        const siteDetails = siteFilter?.[0] || {};
        const tenantDetails = tenantFilter?.[0] || {};
        const updatedData = data.map((item) => {
            // eslint-disable-next-line no-unused-vars
            const { serial_number, model_family, model, host_name, up_time, firmware_version, licensed_channels, active_calls, port_state, ip_addresses, gateway_status, req_ip } = item;
            return {
                netbox_site: {
                    id: siteDetails.value,
                    tenant_id: tenantDetails.value,
                    tenant_name: tenantDetails.label,
                    is_vip: tenantDetails.custom_fields?.marked_vip || false,
                },
                remote_site: {
                    name: host_name,
                    model: model,
                    invStatus: "active",
                    gatewayStatus: gateway_status,
                    serial: serial_number,
                    type: "vega",
                    service: "gateway",
                    upTime: up_time,
                    firmwareVersion: firmware_version,
                    licensedChannels: licensed_channels,
                    activeCalls: active_calls,
                    gatewayEventTime: Date.now(),
                    portData: port_state,
                    ipAddresses: ip_addresses,
                    reqIp: req_ip
                },
            };
        });

        return updatedData;
    },[filterValues]);

    const resetData = useCallback(()=>{
        setFilterValues({
            tenantFilter: [],
            siteFilter: [],
            filter: [],
            tenantOptionsList: [],
            siteOptionsList: [],
            isFetchingSites: false,
            isTenantRequired: false,
            isSiteRequired: false
        });
        setGatewayMappingData([]);
    },[])

    const fetchGatewayUnMappingData = useCallback(async (pagination, searchstring, selectedSerial = []) => {
        setIsGatewayLoading(true);
        if (pagination) {
            setPaginationState({
                offset: pagination?.offset,
                limit: Number(pagination?.limit),
                sortField: pagination?.sortField,
                sortOrder: pagination?.sortOrder,
            });
        }

        // const paginationRequest = pagination ? { ...pagination } : { ...paginationState };
        const searchTextRequest = searchstring ? { searchText: searchstring } : searchInputValue ? { searchText: searchInputValue } : {};
        
        try {
            let unmappedDetails = await dispatch(fetchUnMappedGatewayDetails({  ...searchTextRequest})).unwrap();
            
            if(selectedSerial.length > 0){
                unmappedDetails = {
                    ...unmappedDetails,
                    data: unmappedDetails.data.filter(item => !selectedSerial.includes(item.serial_number))
                };
            }
            setGatewayUnmappedData(unmappedDetails);
        } catch (error) {
            console.error("Error while fetching unmapped details:", error)
        } finally {
            setIsGatewayLoading(false);
        }
    }, [dispatch, searchInputValue])

    const handleGatewayMapping = useCallback(async () => {
        try {
            setIsGatewayMapping(true);
            if (!filterValues?.tenantFilter?.length) {
                handleValidationError("isTenantRequired", "Please select tenant");
                return;
            } else if (!filterValues?.siteFilter?.length) {
                handleValidationError("isSiteRequired", "Please select site");
                return;
            } else if (gatewayMappingData?.length <=0) {
                toast.error("Please select gateways to map");
                return;
            }
            if (gatewayMappingData && gatewayMappingData?.length > 0) {
                const data = formatData(gatewayMappingData);
                if (data && data?.length) {
                    const response = await dispatch(createSiteBinding({data: data}))?.unwrap();
                    if(response ==="Added") {
                        toast.success("Gateway mapped successfully");
                        resetData();
                        let selectedSerial = data.map(item => item.remote_site?.serial);
                        fetchGatewayUnMappingData('', '', selectedSerial);
                    }
                }
            }
        } catch (error) {
            console.error("Error during gateway mapping:", error);
        } finally {
            setIsGatewayMapping(false);         
        }
    }, [dispatch, fetchGatewayUnMappingData, filterValues?.siteFilter?.length, filterValues?.tenantFilter?.length, formatData, gatewayMappingData, handleValidationError, resetData])

    const handleUnmappedGatewayCheckbox = useCallback((checked, data) => {
        let updatedData = [...gatewayMappingData] || [];
        const existingIndex = updatedData && updatedData?.length > 0 ? updatedData?.findIndex(
            item => item.serial_number === data?.serial_number
        ) : -1;
        if (existingIndex !== -1) {
            updatedData?.splice(existingIndex, 1);
        } else {
            updatedData?.push(data);
        }
        setGatewayMappingData(updatedData);
    }, [gatewayMappingData, setGatewayMappingData]);

    const handleSearch = useCallback(async (e) => {
        const searchString = trimSpacesFromStartAndEnd(e?.target?.value) 
        setSearchInputValue(searchString);
        await fetchGatewayUnMappingData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, searchString);
      }, [fetchGatewayUnMappingData, paginationState?.sortField, paginationState?.sortOrder])

    const unMappedGatewayColumns = useMemo(() => [
        {
            title: "Phone Company",
            name: "Phone Company",
            sortField: "Phone_Company",
            dataType: "String",
            isEnabled: true,
            minWidth: 100,
            sortable: true,
            cell: (row) => {
                return (
                    <Box sx={{ display: "flex", alignItems: "center !important", width: "100%" }}>
                        <Checkbox sx={{width:"40px"}} checked={gatewayMappingData?.find(item => item.serial_number === row?.serial_number)} onChange={(event) => handleUnmappedGatewayCheckbox(event?.target?.checked, row)} size='small' />
                        <CopyTextCell containerClassName="serial__number__wrapper"  customContainerStyle ={{width:"calc(100% - 40px)", justifyContent:"flex-start"}}showWithTooltip={true} text={row?.phone_company} />
                    </Box>
                )
            },
        },
        {
            title: "Phone Model",
            name: "Phone Model",
            sortField: "model",
            dataType: "String",
            isEnabled: true,
            minWidth: 100,
            sortable: true,
            cell: (row) => <TooltipLabel text={row?.model} />,
        },
        {
            title: "Phone Lines",
            name: "Phone Lines",
            sortField: "lines",
            dataType: "String",
            isEnabled: true,
            minWidth: 100,
            sortable: true,
            cell: (row) => <TooltipLabel text={row?.lines} />,
        },
        {
            title: "Mac Address",
            name: "Mac Address",
            sortField: "mac_address",
            dataType: "String",
            isEnabled: true,
            minWidth: 100,
            sortable: true,
            cell: (row) => <TooltipLabel text={row?.mac_address} />,
        },
        {
            title: "Firmware Version",
            name: "Firmware Version",
            sortField: "firmware_version",
            dataType: "String",
            isEnabled: true,
            minWidth: 100,
            sortable: true,
            cell: (row) => <TooltipLabel text={row?.firmware_version} />,
        },
        {
            title: "Ip Address",
            name: "Ip Address",
            dataType: "String",
            isEnabled: true,
            minWidth: 100,
            cell: (row) => {
             
                return (
                    <CopyTextCell text={row.ip_address ? row.ip_address : '--'} showWithTooltip={true} />
                )
                

            } 
        }
    ], [gatewayMappingData, handleUnmappedGatewayCheckbox])


    const filterOptionsList = useMemo(() => {
        return [
            {
                options: filterValues?.tenantOptionsList,
                value: filterValues?.tenantFilter,
                placeholder: 'Select Tenant...',
                onChange: (val) => {
                    onChangeOfFilter('tenantFilter', val)
                },
                isEnabled: user?.tenant?.name === 'Sangoma',
                mdValue: 3,
                isFilterRequired: filterValues?.isTenantRequired
            },
            {
                options: filterValues?.siteOptionsList,
                value: filterValues?.siteFilter,
                placeholder: 'Select Site...',
                onChange: (val) => {
                    onChangeOfFilter('siteFilter', val)
                },
                isEnabled: true,
                mdValue: 4,
                isLoading: filterValues?.isFetchingSites,
                isFilterRequired: filterValues?.isSiteRequired
            }
        ]
    }, [filterValues?.isFetchingSites, filterValues?.isSiteRequired, filterValues?.isTenantRequired, filterValues?.siteFilter, filterValues?.siteOptionsList, filterValues?.tenantFilter, filterValues?.tenantOptionsList, onChangeOfFilter, user?.tenant?.name])

    useEffect(() => {
        const tOptions = []
        if (tenants && tenants?.length) {
            tenants.forEach(element => {
                if (element?.custom_fields?.gateway_enabled && element?.name !== 'Sangoma') {
                    tOptions.push({
                        value: element?.id,
                        label: element?.name, custom_fields: element?.custom_fields
                    })
                }
            });
        }
        setFilterValues((prev) => ({ ...prev, tenantOptionsList: tOptions }))
    }, [tenants])

    useEffect(() => {
        fetchGatewayUnMappingData();
    }, [])

    return (
        <Box className="main_inner_wrap gateway__mapping__dashboard">
            <Breadcrumbs text={"phone Mapping"} />
            {/* Filter wrap */}
            <Grid className='filter__container' container sx={{ margin: '30px 0px 20px' }}>
                <Grid item md={3}>
                <SearchInputField handleClick={handleSearch} value={searchInputValue} />
                </Grid>
                <Grid item md={9} >
                <Grid container  sx={{ display: "flex", alignItems: "center", justifyContent:"flex-end" }}>
                    {filterOptionsList && filterOptionsList?.length > 0 && filterOptionsList?.map((item, index) => {
                        const { isEnabled, mdValue, onChange, options, placeholder, value, isLoading, isFilterRequired } = item;
                        
                        if (isEnabled) {
                            return (
                                <Grid key={placeholder + index} item md={mdValue} className='flex flex_wrap'>
                                    <SelectDropDown
                                        options={options}
                                        value={value}
                                        placeholder={placeholder}
                                        hideFilterIcon={true}
                                        onChange={onChange}
                                        isLoading={isLoading || false}
                                        required={isFilterRequired}
                                    />
                                </Grid>
                            )
                        }
                    })}
                </Grid>
                </Grid>

            </Grid>
            {/* Table wrap */}
            <Box className="gateway__table__wrap">
                <TableComponent
                    className="rdt_table_wrap table_with_header"
                    columns={unMappedGatewayColumns}
                    rows={tableData || []}
                    totalRows={gatewayUnmappedData?.count || 0}
                    isLoading={isGatewayLoading}
                    onPageChange={fetchGatewayUnMappingData}
                    defaultSortOrder={"asc"}
                    defaultSortField={"serial_number"}
                    pagination={false}
                />
            </Box>
            {/* Button wrap */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: "20px 0px" }}>
                <MuiThemedButton disable={!(tableData?.length > 0)} showLoader={isGateWayMapping} onClick={handleGatewayMapping} variant="outlined" type="submit" className={user?.themePreference === 'light' ? "button-outlined flex" : ""}>
                    MAP
                </MuiThemedButton>
            </Box>

        </Box>
    )
}

export default PhoneMapping
