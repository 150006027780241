import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "services/Axios";

let base_url = process.env.REACT_APP_BASE_URL;


export const fetchPhoneData = createAsyncThunk(
	"appReports/fetchDevicesData",
	async (data) => {
		const { tenantId, tenantName, ...rest } = data;
		let url = '';
		if (tenantName !== "Sangoma" && tenantId) {
			url = `${base_url}/phones/byTenantId/${tenantId}`;
		} else {
			url = `${base_url}/phones`;
		}
		const response = await axios.get(url,{params: {...rest} });
		return response.data;
	}
);

export const fetchPhoneFlappingLogsData = createAsyncThunk(
	"appReports/fetchPhoneFlappingLogsData",
	async (data) => {
		let url = `${base_url}/phones/flapping/${data?.extensionId}`;
		const response = await axios.get(url);
		return response.data;
	}
);

export const fetchVoiceScoreData = createAsyncThunk(
	"appReports/fetchVoiceScoreData",
	async (data) => {
		let url = `${base_url}/phones/voice_score`;
		const response = await axios.get(url, {params: data});
		return response.data;
	}
);