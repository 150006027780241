import CopyTextCell from "components/common/CopyTextCell/CopyTextCell";
import StatusComponent from "components/common/statusComponent";
import ColumnHeaderWithTooltip from "components/common/TableComponent/ColumnHeaderWithTooltip";
import TooltipLabel from "components/common/TooltipLabel/TooltipLabel";

export const TableTypes = [
	{
		category: "tenants",
		title: "Tenants",
		columns: [
			{
				selector: (row) => row.name,
				name: "name",
				title: "Name",
				dataType: "String",
				sortable: true,
				isEnabled:true,
				minWidth: window.innerWidth > 1280 ? 650: 400,
				grow: 0
			},
			{
				selector: (row) => row.description,
				name: "description",
				title: "Description",
				dataType: "String",
				sortable: true,
				isEnabled:true,
				minWidth: window.innerWidth > 1280 ? 650: 400,
				grow: 0
			},
			{
				name: "action",
				title: "Action",
				dataType: "String",
				minWidth:150, 
				isEnabled:true,
			},
		],
		parameters: [
			{
				title: "Name",
				identifier: "tenantName",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Slug",
				identifier: "slug",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
		],
	},

	{
		category: "Contact_Details",
		title: "ContactDetails",
		columns: [
			{
				selector: (row) => <TooltipLabel text = {row.name}/>,
				name: "name",
				title: "Name",
				dataType: "String",
				sortable: true,
				isEnabled:true,
				weight: 1,
			},
			{
				selector: (row) => <TooltipLabel text = {row.title}/>,
				name: "title",
				title: "Title",
				dataType: "String",
				sortable: false,
				isEnabled:true,
				weight: 1,
			},
			{
				selector: (row) => <CopyTextCell text = {row.mobile}/>,
				name: "mobile",
				title: "Mobile",
				dataType: "String",
				sortable: false,
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => <CopyTextCell text = {row.email}/>,
				name: "email",
				title: "Email",
				dataType: "String",
				sortable: false,
				weight: 1,
				isEnabled:true,
				cell:(row) => row.email.toLowerCase()
			},
			{
				selector: (row) => <TooltipLabel text = {row.address}/>,
				name: "address",
				title: "Address",
				dataType: "String",
				sortable: false,
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => <TooltipLabel text = {row.comments}/>,
				name: "comments",
				title: "Comments",
				dataType: "String",
				sortable: false,
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => row?.action,
				name: "action",
				title: "Action",
				dataType: "String",
				weight: 1,
				isEnabled:true,
				maxWidth: '200px',
			},
		],
		parameters: [
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 90,
			},
			{
				title: "Title",
				identifier: "title",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 90,
			},
			{
				title: "Mobile",
				identifier: "phone",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [""],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 10,
			},
			{
				title: "Email",
				identifier: "email",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 90,
			},
			{
				title: "Comments",
				identifier: "comments",
				parameterType: "Textarea",
				defaultValue: "",
				validationRules: [""],
				// widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 120,
			},
			{
				title: "Address",
				identifier: "address",
				parameterType: "Textarea",
				defaultValue: "",
				validationRules: [""],
				// widthMd: 6,
				widthsm: 12,
				widthxs: 12,
				maxChar: 120,
			},
		],
	},
	{
		category: "site",
		title: "Site",
		columns: [
			{
				cell: (row) => <TooltipLabel text = {row.tenantName}/>,
				name: "tenant",
				title: 'tenant',
				sortable: true,
				wrap: false,
				sortField: 'tenantName',
				isEnabled:true,
			},
			{
				cell: (row) => 
				<TooltipLabel  text = {row?.site_display_name ?row?.site_display_name :'--' }/>,
				name: 'Display Name',
				title: 'Display Name',
				sortField: 'site_display_name',
				sortable: true,
				wrap: false,
				isEnabled:true,
			},
			// {
			// 	name : 	<div><div></div>
			// 		<ColumnResizer className="columnResizer" /><div></div></div>,
			// 	isEnabled:true,
			// 	minWidth: '0px !important'
			// },
			{
				cell: (row) => <TooltipLabel text = {row.name}/>,
				name: "Site name",
				title: 'Site name',
				sortField: 'name',
				sortable: true,
				wrap: false,
				isEnabled:false,
			},
			{
				cell: (row) => <TooltipLabel text = {row?.description ? row?.description : "--"}/>,
				name: "description",
				title: 'description',
				wrap: false,
				isEnabled:true,
			},
			{
				selector: 'status',
				cell: row => <div className={
					row?.state?.toLowerCase() === "active" ||
						row?.state?.toLowerCase() === "up"
						? "status status_active"
						: row?.state?.toLowerCase() === "down"
							? "status status_down"
							: "status status_inactive"
				}><TooltipLabel text = {row.state}/></div>,
				name: <ColumnHeaderWithTooltip tooltipText={'Refers to the inventory conditions of a device within a system.'} columnName={'State'} />,
				title: 'state',
				sortField: 'state',
				wrap: true,
				isEnabled:true,
				sortable: true,
			},
			{
				selector: (row) => row?.siteStatus ? row?.siteStatus : "--",
				cell: row => <StatusComponent status = {row?.siteStatus}/>,
				title: 'status',
				name: <ColumnHeaderWithTooltip tooltipText={'Indicates the current operational condition of a device within a network.'} columnName={'Status'} />,
				sortable: false,
				wrap: true,
				sortField: 'siteStatus',
				isEnabled:true,
			},
			{
				cell: (row) => <TooltipLabel text = {row.latitude ? row.latitude : "--"}/>,
				name: "Latitude",
				title: 'Latitude',
				isEnabled:false,
			},
			{
				cell: (row) => <TooltipLabel text = {row.longitude ? row.longitude : "--"}/>,
				name: "Longitude",
				title: 'Longitude',
				isEnabled:false,
			},
			{
				cell: (row) => <TooltipLabel text = {row.physical_address ? row.physical_address : "--"}/>,
				name: "Address",
				title: 'Address',
				isEnabled:false,
			},
			{
				cell: (row) => <TooltipLabel text = {row?.action}/>,
				name: "action",
				title: 'action',
				wrap: true,
				width: "20px",
				isEnabled:true,
			},
		],
		parameters: [
			{
				title: "Name",
				identifier: "siteName",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Slug",
				identifier: "slug",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Status",
				identifier: "status",
				parameterType: "ListSingle",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Timezone",
				identifier: "timezone",
				parameterType: "ListSingle",
				defaultValue: "",
				validationRules: [],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Tenants",
				identifier: "tenant",
				parameterType: "ListSingle",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Physical Address",
				identifier: "physicalAddress",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Shipping Address",
				identifier: "shippingAddress",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Latitude",
				identifier: "Latitute",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Longitute",
				identifier: "longitute",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 6,
				widthsm: 12,
				widthxs: 12,
			},
		],
	},
	{
		category: "inventory",
		title: "Inventory",
		columns: [
			{
				name: "tenant",
				title: "Tenant",
				wrap: true,
				weight: 1,
				sortField: 'tenant.name',
				cell: (row) => <TooltipLabel text = {row.tenant}/>,
				isEnabled:true,
				sortable: true,
			},
			{
				name: "Site Display Name",
				title: "Site Display Name",
				wrap: true,
				weight: 2,
				minWidth: 200,
				sortable: true,
				sortField: 'site.display_name',
				cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.display_name ? row.display_name : "--"}/>,
				isEnabled:true,
			},

			{
				name: "Site Name",
				title: "Site Name",
				wrap: true,
				weight: 2,
				minWidth: 200,
				sortable: true,
				sortField: 'site.name',
				cell: (row) => <CopyTextCell showWithTooltip={true} text = {row.site}/>,
				isEnabled:false,
			},
			{
				sortField: `["name", "custom_fields.circuit_name"]`,
				name: "Device Name",
				title: "Device Name",
				dataType: "String",
				sortable: true,
				minWidth: 200,
				weight: 2,
				description: "",
				cell: (row) => 
					<CopyTextCell showWithTooltip={true} text = {row?.name}/>,
				isEnabled:true,
			},
			{
				name: "Serial No",
				title: " Serial No",
				wrap: true,
				weight: 1,
				sortField: 'serial',
				cell: (row) => <CopyTextCell text = {row.serial_no}/>,		
				isEnabled:true,
			},
			{
				name: "Service",
				title: "Service Name",
				wrap: true,
				weight: 1,
				sortField: 'service',
				cell: (row) => <TooltipLabel text = {row.service_name}/>,
				isEnabled:true,
			},

			{
				name: "role",
				title: "Role",
				wrap: true,
				weight: 1,
				sortField: 'device_role_name',
				cell: (row) => <TooltipLabel text = {row.role}/>,
				isEnabled:true,
			},

			{
				name: "ip",
				title: "IP Address",
				wrap: true,
				weight: 1,
				cell: (row) => {return <CopyTextCell text = {row?.ip}/>},
				isEnabled:true,
			},
			{
				name: "vendor",
				title: "Vendor",
				wrap: true,
				weight: 1,
				cell: (row) => <TooltipLabel text = {row.vendor}/>,
				isEnabled:true,
			},
			{
				name: <ColumnHeaderWithTooltip tooltipText={'Refers to the inventory conditions of a device within a system.'} columnName={'State'} />,
				title: "State",
				wrap: true,
				weight: 1,
				sortable: true,
				sortField:'status.label',
				cell: (row) =>  <span
				className={
						(row.inventory_status?.toLowerCase() === "decommissioning" || row.inventory_status?.toLowerCase() === "offline")
							? "status status_down"
							:(row.service !== 'cradlepoint' ? row?.totalDevices === 0 :true)&& row.inventory_status?.toLowerCase() === "active"
							? "status status_active"
							: row?.countActiveStatus === 0 && row?.service !=='wug'
								? "status status_down"
								: row?.countActiveStatus === row?.totalDevices
									? "status status_active"
									: row?.countActiveStatus > 0 && row?.countActiveStatus < row?.totalDevices
										? "status status_inactive"
										: "status status_inactive"
					}
			>
				<TooltipLabel text = {row.inventory_status}/>

			</span>,
			isEnabled:true,
			},
			{
				selector: (row) => row?.device_status ? row?.device_status : "--",
				cell: row => <div data-tag={"allowRowEvents"} className={
					row?.device_status?.toLowerCase() === "up"
						? "status status_active"
						: row?.device_status?.toLowerCase() === "down"
							? "status status_down"
							:row?.device_status?.toLowerCase() === "degraded"
							? "status status_degraded": row?.device_status?.toLowerCase() === "maint" ? "status status_inactive"  : row?.device_status?.toLowerCase() === "n/a" ? "status status_na" : ""
				}>{row?.device_status ? row?.device_status : "--"}</div>,
				name: <ColumnHeaderWithTooltip tooltipText={'Indicates the current operational condition of a device within a network.'} columnName={'Status'} />,
				title: 'Status',
				sortable: false,
				wrap: true,
				sortField: 'device_status',
				isEnabled:true,
			},
			{
				selector: (row) => row?.action,
				name: "action",
				title: "Action",
				wrap: true,
				maxWidth: "100px",
				isEnabled:true,
			},
		],
		parameters: [],
	},
	{
		category: "inventory_sync",
		title: "Sync Data",
		columns: [
			{
				name: "date",
				title: "Date",
				dataType: "String",
				sortable: true,
				weight: 2,
				description: "",
				isEnabled:true,
			},
			{
				name: "time",
				title: "Time",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				name: "remark",
				title: "Remark",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				name: "action",
				title: "Action",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
		],
		parameters: [
			{
				title: "Provider",
				identifier: "provider",
				parameterType: "listSingle",
				defaultValue: false,
				optionList: [
					{
						name: "VCO",
						value: "vco",
					},
					{
						name: "Meraki",
						value: "meraki",
					},
				],
				validationRules: [],
			},
			{
				title: "Server",
				identifier: "server",
				parameterType: "listSingle",
				defaultValue: false,
				validationRules: [],
				optionList: [
					{
						name: "VCO 0",
						value: "vco0",
					},
					{
						name: "VCO 4",
						value: "vco4",
					},
				],
			},
			{
				title: "Tenant ID",
				identifier: "tenantId",
				parameterType: "checkbox",
				defaultValue: false,
				validationRules: [],
			},
			{
				title: "Site ID",
				identifier: "siteId",
				parameterType: "checkbox",
				defaultValue: false,
				validationRules: [],
			},
			{
				title: "Edge ID",
				identifier: "edgeId",
				parameterType: "checkbox",
				defaultValue: false,
				validationRules: [],
			},
			{
				title: "Serial Number",
				identifier: "serial_number",
				parameterType: "checkbox",
				defaultValue: false,
				validationRules: [],
			},
		],
	},

	{
		category: "linkStatus",
		title: "Link Status",
		columns: [
			{
				name: "name",
				title: "Name",
				dataType: "String",
				sortable: true,
				weight: 2,
			},
			{
				name: "vendor",
				title: "Vendor",
				dataType: "String",
				sortable: true,
				weight: 1,
			},
			{
				name: "IP",
				title: "IP",
				dataType: "String",
				sortable: true,
				weight: 1,
			},
			{
				name: "status",
				title: "Status",
				dataType: "String",
				sortable: true,
				weight: 1,
			},
			{
				name: "since",
				title: "Since",
				dataType: "String",
				sortable: true,
				weight: 1,
			},
		],
		parameters: [],
	},
	{
		category: "syncReport",
		title: "Report",
		columns: [
			{
				id: "tenantName",
				name: "tenantName",
				title: "Tenant Name",
				dataType: "String",
				sortable: true,
				wrap: true,
				minWidth: 200,
				isEnabled:true,
			},
			{
				id: "site_display_name",
				name: "site_display_name",
				title: "Site Display Name",
				dataType: "String",
				// sortable: true,
				wrap: true,
				minWidth: 200,
				isEnabled:true,
			},
			{
				id: "siteName",
				name: "siteName",
				title: "Site Name",
				dataType: "String",
				sortable: true,
				wrap: true,
				minWidth: 200,
				isEnabled:false,
			},
			{
				id: "deviceName",
				name: "deviceName",
				title: "Device Name",
				dataType: "String",
				sortable: true,
				wrap: true,
				minWidth: 200,
				isEnabled:true,
			},
			{
				id: "type",
				name: "type",
				title: "Type",
				dataType: "String",
				sortable: true,
				wrap: true,
				minWidth: 200,
				isEnabled:true,
			},
			{
				id: "type",
				name: "serviceName",
				title: "service Name",
				dataType: "String",
				sortable: true,
				wrap: true,
				minWidth: 50,
				isEnabled:true,
			},
			{
				id: "sangoma",
				name: "sangoma",
				title: "Sangoma",
				dataType: "String",
				// sortable: true,
				wrap: true,
				minWidth: 50,
				isEnabled:true,
			},
			{
				id: "velo_cloud",
				name: "service",
				title: "service",
				dataType: "String",
				sortable: true,
				wrap: true,
				minWidth: 200,
				isEnabled:true,
			},
		],
		parameters: [],
	},
	{
		category: "service",
		title: "Services",
		columns: [
			{
				name: "name",
				title: "Service Name",
				dataType: "String",
				sortable: true,
				weight: 2,
				isEnabled:true,
				minWidth: window.innerWidth > 1280 ? 500: 500,
				grow: 0,
				// minWidth: "500px",
				maxWidth: "500px",
			},
			{
				name: "url",
				title: "URL",
				dataType: "String",
				sortable: true,
				weight: 1,
				isEnabled:true,
				minWidth: window.innerWidth > 1280 ? 500: 500,
				grow: 0,
				// minWidth: "500px",
				maxWidth: "500px",
			},
			{
				name: "description",
				title: "Description",
				dataType: "String",
				sortable: true,
				weight: 1,
				isEnabled:true,
				minWidth: window.innerWidth > 1280 ? 500: 450,
				maxWidth: "500px",
				grow: 0,
			},
			{
				name: "action",
				title: "Action",
				dataType: "String",
				maxWidth: '100px',
				weight: 1,
				isEnabled:true,
			},
		],
		parameters: [
			{
				title: "Name",
				identifier: "tenantName",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Slug",
				identifier: "slug",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
		],
	},
	{
		category: "changelog",
		title: "Changelog",
		columns: [
			{
				name: "datetime",
				title: "Date & Time",
				dataType: "String",
				sortable: true,
				weight: 1,
			},
			{
				name: "username",
				title: "UserName",
				dataType: "String",
				sortable: true,
				weight: 1,
			},
			{
				name: "activity_type",
				title: "Action",
				dataType: "String",
				weight: 1,
			},
			{
				name: "comments",
				title: "comments",
				dataType: "String",
				sortable: true,
				weight: 1,
			},
			{
				// selector: (row) => row.comments,
				name: "details",
				title: "Details",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			}
		],
		parameters: [],
	},
	{
		category: "roles",
		title: "Roles",
		columns: [
			{
				selector: (row) => row.name,
				name: "name",
				title: "Name",
				dataType: "String",
				sortable: true,
				isEnabled: true,
				minWidth: window.innerWidth > 1280 ? 650: 400,
				grow: "0 !important"
			},
			{
				selector: (row) => row.type,
				name: "type",
				title: "Type",
				dataType: "String",
				sortable: true,
				isEnabled: true,
				minWidth: window.innerWidth > 1280 ? 650: 400,
				grow: "0 !important",
			},
			{
				selector: (row) => row?.action,
				name: "action",
				title: "Action",
				wrap: true,
				isEnabled: true,
				minWidth: 100,
			},
		],
		parameters: [],
	},
	{
		category: "users",
		title: "Users",
		columns: [
			{
				selector: (row) => <TooltipLabel text = {row.name}/>,
				name: "name",
				title: "Name",
				dataType: "String",
				sortable: true,
				isEnabled:true,
				weight: 1,
				grow: "0 !important",
				minWidth: 250,
			},
			{
				selector: (row) => <CopyTextCell text = {row.email} showWithTooltip={true}/>,
				name: "email",
				title: "Email",
				dataType: "String",
				sortable: true,
				isEnabled:true,
				weight: 1,
				grow: "0 !important",
				minWidth: 250,

			},
			{
				selector: (row) => <TooltipLabel text = {row.role}/>,
				name: "role",
				title: "Role",
				dataType: "String",
				sortable: true,
				weight: 1,
				isEnabled:true,
				grow: "0 !important",
				minWidth: 200,

			},
			{
				selector: (row) => <TooltipLabel text = {row.type}/>,
				name: "type",
				title: "Type",
				dataType: "String",
				sortable: true,
				weight: 1,
				isEnabled:true,
				grow: "0 !important",
				minWidth: 200,

			},
			{
				selector: (row) => <TooltipLabel text = {row.tenants}/>,
				name: "Tenants",
				title: "Tenants",
				dataType: "String",
				weight: 1,
				isEnabled:true,
				grow: "0 !important",
				minWidth: 280,
			},
			{
				selector: (row) => <TooltipLabel text = {row.isenabled}/>,
				name: "Status",
				title: "Status",
				wrap: true,
				isEnabled:true,
				grow: "0 !important",
				minWidth: 220,
			},
			{
				selector: (row) => row.isLoggedIn,
				name: "Login Status",
				title: "Login Status",
				wrap: true,
				isEnabled:true,
				grow: "0 !important",
				minWidth: 260,
			},
			{
				selector: (row) => row?.action,
				title: "Action",
				name: "action",
				wrap: true,
				centre: true,
				minWidth: "150px",
				isEnabled:true,
			},
		],
		parameters: [],
	},
	{
		category: "last_activity_info",
		title: "LastActivity",
		columns: [
			{
				selector: (row) => <TooltipLabel text = {row.lastLogin}/>,
				name: "login",
				title: "Login",
				dataType: "String",
				sortable: false,
				isEnabled:true,
				weight: 1,
			},
			{
				selector: (row) => <TooltipLabel text = {row.browser}/>,
				name: "browser",
				title: "Browser",
				dataType: "String",
				sortable: false,
				isEnabled:true,
				weight: 1,
			},
			// {
			// 	selector: (row) => <TooltipLabel text = {row.ipaddress}/>,
			// 	name: "ipaddress",
			// 	title: "IpAddress",
			// 	dataType: "String",
			// 	sortable: false,
			// 	weight: 1,
			// 	isEnabled:true,
			// },
			{
				selector: (row) => <TooltipLabel text = {row.lastLogout}/>,
				name: "logout",
				title: "Logout",
				dataType: "String",
				sortable: false,
				weight: 1,
				isEnabled:true,
			},
		],
		parameters: [],
	},
	{
		category: "change-logs",
		title: "Change Logs",
		columns: [
			{
				selector: (row) => row.datetime,
				name: "datetime",
				title: "Date and Time",
				dataType: "String",
				sortable: true,
				sortField: "datetime",
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => row.type,
				name: "type",
				title: "Type",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => row.username,
				name: "username",
				title: "Username",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				name: "activity_type",
				title: "Action",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => <TooltipLabel text = {row.name}/>,
				name: "name",
				title: "Name",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => row.comments,
				name: "comments",
				title: "Comments",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				// selector: (row) => row.comments,
				name: "details",
				title: "Details",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			}

		],
		parameters: [],
	},
	{
		category: "usermanagement-change-logs",
		title: "Change Logs",
		columns: [
			{
				selector: (row) => row.datetime,
				name: "datetime",
				title: "Date and Time",
				dataType: "String",
				sortField: 'timestamp',
				isEnabled:true,
				sortable: true,
				weight: 1,
			},
			{
				selector: (row) => <TooltipLabel text = {row.type} />,
				name: "type",
				title: "Type",
				dataType: "String",
				weight: 1,
				isEnabled:true,
			},
			{
				selector: (row) => <TooltipLabel text = {row.username} />,
				name: "username",
				title: "Username",
				dataType: "String",
				sortable: true,
				isEnabled:true,
				weight: 1,
			},
			{
				selector: (row) => <TooltipLabel text = {row.comment} />,
				name: "comment",
				title: "Comment",
				dataType: "String",
				sortable: true,
				isEnabled:true,
				weight: 1,
				minWidth: window.innerWidth > 1280 ? 650: 400,
				grow: 0		
			}
		],
		parameters: [],
	},
	{
		category: "webmonitor",
		title: "WebMonitor",
		columns: [
			{
				name: "tenant",
				title: "Tenant",
				dataType: "String",
				weight: 2,
				isEnabled:true,
				selector: (row) => <TooltipLabel text= {row?.tenant|| '--'}></TooltipLabel>
			},
			{
				name: "name",
				title: "Name",
				dataType: "String",
				sortable: true,
				weight: 2,
				isEnabled:true,
				selector: (row) => <TooltipLabel text= {row?.name || '--'}></TooltipLabel>
			},
			{
				name: "url",
				title: "Url",
				dataType: "String",
				grow: 1,
				isEnabled:true,
				minWidth: '25% !important',
				selector: (row) => <TooltipLabel text= {row?.url || '--'} className="web-monitor-url"/>
			},
			{
				name: "description",
				title: "Description",
				dataType: "String",
				weight: 1,
				isEnabled:true,
				minWidth:'200px !important',
				selector: (row) => <TooltipLabel text= {row?.description || '--'}></TooltipLabel>
			},
			{
				name: "status",
				title: "Status",
				dataType: "String",
				weight: 1,
				isEnabled:true,
				center: true,
				selector: (row) => <div> {row?.status}</div>
			},
			{
				name: "action",
				title: "Action",
				dataType: "String",
				weight: 1,
				isEnabled:true,
				maxWidth: '50px !important',
				selector: (row) => <div>{row?.action}</div>
			},
		],
		parameters: [
			{
				title: "Name",
				identifier: "name",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Tenants",
				identifier: "tenant",
				parameterType: "Autocomplete",
				defaultValue: "",
				validationRules: ["required"],
				placeholder: "Select Tenant",
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Description",
				identifier: "description",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: [],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
			{
				title: "Url",
				identifier: "url",
				parameterType: "InputField",
				defaultValue: "",
				validationRules: ["required"],
				widthMd: 12,
				widthsm: 12,
				widthxs: 12,
			},
		],
	},


];
