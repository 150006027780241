import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import ActionMenu from "components/common/ActionMenu";
import Breadcrumbs from "components/common/Breadcrumps";
import ButtonLayout from "components/common/Button";
import FilterStatusAndService, { ServiceFilterList } from "components/common/FilterStatusAndService";
import { TableTypes } from "constant/TableColTypes";
import { Suspense, lazy, useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { deleteDevice, fetchDevicesData, fetchDevicesDataForFlapping, setDeviceDetails } from "store/devices";
import TableComponent from 'components/common/TableComponent/TableComponent';
import { MuiThemedBox } from "components/common/MuiThemedComponents";
import SearchInputField from "components/common/SearchInputField";
import FormPopup from "components/common/modal/FormPopup";
import CreateDevice from "components/component/CreateDevice/CreateDevice";
import GlobalExportButton from "components/common/GlobalExportButton";
import DeleteConfirmPopup from "components/component/userManagement/DeleteConfirmPopup";
import { toast } from "react-toastify";
import TableHeader from "components/common/TableHeader/TableHeader";
import { statusTooltipText } from "../link/LinkMonitoring";
import ActionComponent from "components/common/ActionComponent";
import { useCanAccess } from "services/canACLAccess";

const ExpandablePopup = lazy(() => import('components/component/CreateDevice/ExpandablePopup'));

const customStyles = {
	tableWrapper: {
		height: "389px",
		color: "#000",
	},
};


export const filterStatusList = [
	{
		value: "all",
		label :  "All",
	},
	{
		value: "active",
		label : "Active",
	},
	{
		value: "offline",
		label : "Offline",
	},
	{
		value: "planned",
		label : "Planned",
	},
	{
		value: "staged",
		label : "Staged",
	},
	{
		value: "inventory",
		label : "Inventory",
	},
	{
		value: "decommissioning",
		label : "Decommissioning",
	},
];

export const StatusFilterList = [
	{
		value: "all",
		label:"All",
	},
	{
		value: "up",
		label:  "Up",
	},
	{
		value: "down",
		label: "Down",
	},
	{
		value: 'degraded',
		label: 'Degraded'
	},
	{
		value: "maint",
		label:  "Maintenance",
	},
	{
		value: "others",
		label:  "Others",
	},
];

const DevicesList = () => {
	const [tableColumns, setTableColumns] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const user = useSelector((state) => state.user);
	const devices = useSelector((state) => state.devices)
	const dispatch = useDispatch();
	const [editData, seteditData] = useState();
	const [openActionMenu, setopenActionMenu] = useState(null);
	const [tenantOptionsList, setTenantOptionsList] = useState([]);
	const open = Boolean(openActionMenu);
	const defaultStatusFilter = [
		{ value: 'active', label: 'Active' },
		{ value: 'offline', label: 'Offline' },
	]
	const [statusFilter, setstatusFilter] = useState(defaultStatusFilter);
	const [serviceFilter, setserviceFilter] = useState([]);
	const [tenantFilter, setTenantFilter] = useState([]);
	const [roleValue, setRoleValue] = useState([])
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [openDevice, setopenDevice] = useState(false);
	const [totalCount, setTotalCount] = useState(0)
    const [searchTextState, setSearchTextState] = useState(null);
	const [selectedRowData, setselectedRowData] = useState([]);
	const [openServicePopup, setOpenServicePopup] = useState(false);
	const [filter, setFilter] = useState([]);
	const [deviceStatusFilterValue, setdeviceStatusFilterValue]=useState([]);
	const [openDeleteConfimation, setOpenDeleteConfimation] = useState(false)
    const [deviceStatus, setDeviceStatus] = useState({});
	const [shouldHeaderLoader, setShouldHeaderLoader] = useState(true);
	const [filterText, setFilterText] = useState(null);
	const [highlightFilter, setHighLightFilter] = useState(false);
	const [tableRefreshKey, setTableRefreshKey] = useState(null);
	const [paginationState, setPaginationState] = useState({
		offset: 1, limit: 20, sortField: null, sortOrder: null
	});
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const canAccess = useCanAccess();
	let filterStatusListData = user?.tenant?.name !== "Sangoma"? filterStatusList.filter(data => data?.value !== 'decommissioning'): filterStatusList;


	const setPaginationData = (newState) => {
		setPaginationState(prevState => ({ ...prevState, ...newState }))
	}

	useEffect(() => {
		const defaultFilterValue = (location?.state?.service == '' || location?.state?.service || location?.state?.inv_status) ? [] : [{"value":["active","offline"],"key":"status_value"}];
		setTenantFilter([]);
		if (!location?.state?.inv_status)
			setFilter(defaultFilterValue)
		setdeviceStatusFilterValue([]);
		setserviceFilter([])
		setstatusFilter([
			{ value: 'active', label: 'Active' },
			{ value: 'offline', label: 'Offline' },
		])
		setSearchTextState('')
		setFilterText(null);

		if (!user.tenant) {
			return;
		} else {
			dispatch(setDeviceDetails({ deviceName: undefined, serviceName: undefined, deviceData: undefined }));
		}
		
		const { state } = location || {};
		if (state && Object?.keys(state)?.length && state?.deviceData == undefined) {
			const { inv_status, service, device_status } = state;
			
			let locationFilters = []

			if (inv_status) {
				locationFilters = [
					...locationFilters,
					{
						value: [inv_status?.toLowerCase()],
						key: "status_value"
					},

				]

				setstatusFilter([{ value: inv_status?.toLowerCase(), label: inv_status }])
			}

			if(device_status) {
				const selectedStatusFilter = StatusFilterList?.find((item) => item?.value?.toLowerCase() === (device_status?.toLowerCase() || 'all'));
				locationFilters = [
					...locationFilters,
					{
						value: [device_status?.toLowerCase()],
						key: "device_status"
					},

				]

				setdeviceStatusFilterValue([selectedStatusFilter])

			}
			if (service) {
				const selectedServiceFilter = ServiceFilterList?.find((item) => item?.value?.toLowerCase() === (service?.toLowerCase() || 'all'));
				if(selectedServiceFilter) {
					locationFilters = [
						...locationFilters,
						{
							value: [selectedServiceFilter?.value],
							key: "service"
						}
					]
					setserviceFilter([selectedServiceFilter]);
				}

			}
			if (locationFilters && locationFilters?.length > 0) {

				setFilter((val) => {
					let filterValue = [...val, ...locationFilters]
					return filterValue
				})
			}
			getDevicesData(null, null, { filter: JSON.stringify(locationFilters) })
		} else {
			getDevicesData(null, '', { filter: JSON.stringify(defaultFilterValue) });
		}
		
		return () => {
			// if(location?.state?.deviceData) {
			// 	navigate(location.pathname, {state: null});
	
			// }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.tenant, location, user?.tenant?.id]);

	useEffect(() => {
		if (TableTypes?.length) {
			let tableCols = TableTypes?.find((obj) => obj?.category === "inventory");
			let filterCols = tableCols?.columns?.filter((obj) => user?.tenant?.name !== "Sangoma" ? obj?.name !== "tenant" : obj);
			setTableRefreshKey(new Date().getTime())
			setTableColumns(filterCols);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user?.tenant]);

	useEffect(() => {
		let tenantsList = devices?.tenants?.length > 0 && devices?.tenants?.filter(i => (i.custom_fields?.meraki_org_id ||
			i.custom_fields?.vco_enterprise_id || i.custom_fields?.cradlepoint_id ||i.custom_fields?.fortinet_adom_oid||
			i.custom_fields?.wug_device_group_id))?.map((tenant) => {
				return {
					value: tenant.id,
					label: tenant.name,
				}	
		})
		tenantsList = tenantsList.length > 0 && tenantsList.filter(item => item?.label?.toLowerCase() !== 'sangoma')
		let allTenantOption = {
			label: 'All' , value: 'all'
		};
		if(tenantsList && tenantsList?.length > 0) {
			const updatedTenantArray = [allTenantOption, ...tenantsList];
			setTenantOptionsList(updatedTenantArray);
		}

		return() =>{
			handleActionMenuClose()
		}

	}, [devices])


	const openEditForm = (row) => {
		setopenDevice(true);
		seteditData(row)
	};

	const handleActionMenuOpen = (e, row) => {
		setopenActionMenu(e.currentTarget);
		seteditData(row);
	}

	const handleActionMenuClose = () => {
		setopenActionMenu(null)
	}

	const getFilterRequest = (fltr, pagination, searchText) => {	
		let filterReq = 
		fltr ? fltr :searchTextState  ? [] : filter?.length > 0 ? { filter: searchText && filter[0]?.value[0].includes("active") ? '' : JSON.stringify(filter) } : null;
		let searchTextValue = searchText == '' ? searchText : searchText ? searchText.trim() : searchTextState?.trim() || '';
		let req = pagination ? { ...pagination, 
			searchText: searchTextValue, ...filterReq } :
			{ ...paginationState, searchText: searchTextValue, ...filterReq}

		let request = { tenantId: user?.tenant?.id, tenantName: user.tenant?.name, ...req }

		return request || {}
	}


	const getDevicesData = useCallback( async (pagination, searchText, fltr, pageChange) => {
		setShouldHeaderLoader(!pageChange);
		setIsLoading(true);
		if (pagination) {
			setPaginationData({
				offset: pagination?.offset, limit: pagination?.limit, sortField: pagination?.sortField,
				sortOrder: pagination?.sortOrder
			})
		}

		let request = getFilterRequest(fltr, pagination, searchText)

		let deviceData = [];
		let data = [];
		try{
		if (devices?.deviceDetail?.deviceData == undefined && devices?.deviceDetail?.deviceName == undefined) {
			deviceData = await dispatch(fetchDevicesData(request)).unwrap();
			data = await deviceData?.data;
		}
		setTotalCount(deviceData?.count);
		!pageChange && setDeviceStatus(deviceData?.headerData || {});

		if (devices?.deviceDetail?.deviceName !== undefined) {
	
			let resData = await dispatch(fetchDevicesDataForFlapping({id: devices?.deviceDetail?.deviceName, service: devices?.deviceDetail?.serviceName})).unwrap();
			const text = resData?.data?.length > 0 ? (resData?.data[0].display || resData?.data[0].name) : null;
			setFilteredData(formatData(resData?.data));
			setDeviceStatus(resData?.headerData || {})
			setTotalCount(resData?.data?.length);
			setFilterText(text);
		} else if (devices?.deviceDetail?.deviceData !== undefined) {
			setFilteredData(formatData(devices?.deviceDetail?.deviceData));
			setTotalCount(devices?.deviceDetail?.deviceData?.length);
			setDeviceStatus(location?.state?.headerData || {});
			setFilterText(location?.state?.site_name);
		} else {
			let format_data = formatData(data);
			let filtered_data = handleFilteredData(format_data)
			setFilteredData(filtered_data);
			setFilterText(null);
		}
	} catch (error) {
		toast.error("Oops! Something went wrong");
		console.error("Error fetching tenants data:", error);
	} finally {
		setIsLoading(false); 
	}
	}, [devices, user, filter, searchTextState, getFilterRequest]);

	const handleFilteredData = (data) => {
		let status = statusFilter?.value;
		let service = serviceFilter?.value;
		let role = roleValue?.value;
		// TODO: Clear the filters in location state that are applied on redirection when the filters are changed
		if(location.state){
			history.replaceState(null,"", location.pathname);
		}
		let filterUpData = data?.filter((item) => {
			const ServiceMatch = service ? item.service_name?.toLowerCase().replace(' ', '') === service || service === "all" : true;
			const StatusMatch = status ? item.inventory_status?.toLowerCase() === status || status === "all" : true;
			const roleMatch = role ? role === "customer_devices" ? item?.role === "Customer Owned" : item?.role !== "Customer Owned" || role === "all" : true;
			return ServiceMatch && StatusMatch && roleMatch;
		});
		return filterUpData
	}

	const formatData = (dataList) => {
		let rows = [];
		dataList?.map((row) => {
			let deviceDetails = [];
			row?.deviceDetails?.map((device) => {
				deviceDetails?.push({
					name: row?.name,
					serial_no: row?.serial?.replace(/^\s+|\s+$|\s+(?=\s)/g, ""),
					service_name: getServiceName(row),
					service: getService(row),
					inventory_status: device?.status?.label,
					linkName: device?.assigned_object?.name,
					role: row?.device_role?.name,
					site: row?.site?.name,
					tenant: row?.tenant?.name,
					ip: device?.address,
					vendor: device?.isp,
				})
				return true;
			})

			rows?.push({
				id: row?.id,
				name: row?.name || row?.custom_fields?.circuit_name,
				device_status: row?.device_status,
				serial_no: row?.serial ? row?.serial?.replace(/^\s+|\s+$|\s+(?=\s)/g, "") : "-",
				service_name: getServiceName(row),
				service: getService(row),
				inventory_status: row?.status?.label,
				linkName: '-',
				deviceDetails: deviceDetails,
				countActiveStatus: countActiveStatus(deviceDetails),
				custom_fields: row?.custom_fields,
				totalDevices: deviceDetails?.length,
				role: row?.device_role?.name || getServiceName(row),
				site: row?.site?.name || row?.termination_z?.site?.name,
				display_name: row?.site?.display_name || row?.termination_z?.site?.name ||row?.site?.name,
				tenant: row?.tenant?.name,
				tenantId: row?.tenant?.id,
				ip: row?.custom_fields?.ip_address || row?.custom_fields?.meraki_public_ip || 
				row?.custom_fields?.wug_ip || 
				row?.custom_fields?.cradlepoint_ipv4_address || 
				row?.custom_fields?.fortinet_public_ip || 
				row?.custom_fields?.switch_ip_address ||
				'-',
				vendor: row?.provider?.name || (row?.custom_fields?.meraki_public_ip || row?.custom_fields?.fortinet_public_ip ? "Sangoma" : (row?.custom_fields?.wug_vendor ? row?.custom_fields?.wug_vendor : (row?.custom_fields?.cradlepoint_vendor ? row?.custom_fields?.cradlepoint_vendor : "-"))) ,
				action: <ActionComponent onDropdownClick={handleActionMenuOpen} row={row} onEditClick = {openEditForm} disableEdit={!(row?.custom_fields?.is_custom || (row?.custom_fields?.cradlepoint_router_id && row.status_value == 'active'))? canAccess( 'update', 'devices') : false}/>
			})
			return 0;
		});
		return rows;
	};

	const countActiveStatus = (deviceData) => {
		return deviceData.reduce((count, item) => {
			if (item.inventory_status === 'Active') {
				return count + 1;
			}
			return count;
		}, 0);
	}


	const getServiceName = (row) => {
		let serviceName = row?.device_role?.display
		if (Object?.keys(row?.custom_fields)?.length) {
			if (row?.custom_fields?.cmeraki_network_id) {
				serviceName = "WiFi";
			} else if (row?.custom_fields?.vco_edge_id) {
				serviceName = "SDWAN"
			} else if (row?.custom_fields?.wug_ndevice_id || row?.custom_fields?.circuit_name) {
				serviceName = "Circuits"
			} else if (row?.custom_fields?.cradlepoint_router_id) {
				serviceName = "Cradlepoint"
			} else if (row?.custom_fields?.fortinet_device_oid || row?.device_role_name == "Firewall") {
				serviceName = "Fortinet"
			}else if(row?.custom_fields?.switch_ip_address || row?.device_role_name == "Switch"){
				serviceName = "Switch"
			}
		}
		return serviceName
	}

	const getService = (row) => {
		let service;
		if (Object?.keys(row?.custom_fields)?.length) {
			if (row?.custom_fields?.cmeraki_network_id) {
				service = "meraki";
			} else if (row?.custom_fields?.vco_edge_id) {
				service = "velocloud"
			} else if (row?.custom_fields?.wug_ndevice_id || row?.custom_fields?.circuit_name) {
				service = "wug"
			} else if (row?.custom_fields?.cradlepoint_router_id) {
				service = "cradlepoint"
			} else if (row?.custom_fields?.fortinet_device_oid) {
				service = "fortinet"
			}
		}
		return service;
	}

	const handleSearch = async (e) => {
		setTableRefreshKey(new Date().getTime())
		if (e.target.value) {
			setSearchTextState(e.target.value);	
		}
		else{
			setSearchTextState(null)
		}
		await getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder} , e.target.value, null, true);
	};

	const handleStatusFilter = async (e, action) => {
		setstatusFilter(e);
		if(action === 'clear'){
			onApplyFilter('State...', e?.map(i =>  i.value.toLowerCase()));
		}
	};


	const handleDeviceStatusFilter = async (e, action) => {
		setHighLightFilter(false)
		setdeviceStatusFilterValue(e);
		if(action === 'clear'){
			onApplyFilter('Device Status...', e);
		}
	};

	const handleServiceFilter = async (e, action) => {
		setserviceFilter(e);
		if(action === 'clear'){
			onApplyFilter('Service...', e)
		}
	};

	const handleClick = () => {
		setopenDevice(true);
		seteditData({});
	};

	
	const handleDeviceClose = () => {
		setopenDevice(false);
		seteditData({});
	};

	// eslint-disable-next-line no-unused-vars
	const handleRoleFilter = async (e) => {
		setRoleValue(e);
	};

	const handleSelectRow = (row) =>{
		
		if(openActionMenu) {
			handleActionMenuClose()
		}
	if(row?.service_name !== "Circuits" && row?.service_name !== "Switch" ) {
		setOpenServicePopup(true);
	}
		setselectedRowData(row);
	}

	const handleClosePopup = () => {
		setOpenServicePopup(false);
	};

	const handleTenantFilter = async (e, action) => {
		setTenantFilter(e);
		if(action === 'clear'){
			onApplyFilter('Tenant...', e);
		}
	};

	const handleDelete = async () => {
		setIsDeleteLoading(true);
		try{
			let res= await dispatch(deleteDevice({id:editData?.id, role: editData?.device_role_name}))
			if(res?.payload){
				toast.success("Device Deleted Successfully")
				getDevicesData();
			}
		} catch(err){
			toast.error(err)
		}
		handleDeleteConfirm();
		setIsDeleteLoading(false);
	}

	const handleDeleteConfirm = () => {
		setOpenDeleteConfimation(!openDeleteConfimation ? true : false)
	}
	const onApplyFilter = (val, fltrValue) => {

		if(searchTextState) {		
			setSearchTextState(null)
		}
		setTableRefreshKey(new Date().getTime())
		let cloneFilter = [...filter]
		switch(val){
			case 'Role':  {
				const rlfilter = roleValue?.map(i => { return i.value });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'device_role_name');
				cloneFilter.push({value: rlfilter , key: 'device_role_name'});
				break;}
			case 'State...':  {
				let stfilter = fltrValue ? fltrValue : statusFilter?.map(i => { return i.value.toLowerCase() }) ; 
				cloneFilter = cloneFilter?.filter(i => i.key !== 'status_value');
				stfilter?.length > 0 && cloneFilter.push({value: stfilter , key: 'status_value'});break;}
			case 'Service...': {
				let serfilter = fltrValue ? fltrValue : serviceFilter?.map(i => { return i.value.toLowerCase() });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'service');
				serfilter?.length > 0 && cloneFilter.push({ value: serfilter, key: 'service' });
				break;
			}
			case 'Tenant...':{
				let tenantDatafilter = fltrValue ? fltrValue : tenantFilter?.map(i => { return i.value });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'tenant');
				tenantDatafilter?.length > 0 && cloneFilter.push({ value: tenantDatafilter, key: 'tenant' });
				break;
			}
			case 'Device Status...': {
				let deviceStatusDatafilter = fltrValue ? fltrValue : deviceStatusFilterValue?.map(i => { return i.value });
				cloneFilter = cloneFilter?.filter(i => i.key !== 'device_status');
				deviceStatusDatafilter?.length > 0 && cloneFilter.push({ value: deviceStatusDatafilter, key: 'device_status' });
				break;
			}
			default:
				break;
		
	}
		setFilter(cloneFilter);
		let filterReq = cloneFilter?.length > 0 ? {filter: JSON.stringify(cloneFilter)} :  {filter: null}
		getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, '', filterReq);
	}

	const conditionalRowStyles = [
		{
			when: row => row?.service_name == "Circuits" || row?.service_name == "Switch",
			style: {
				cursor: 'default'
			},
		},
	];

	const handleCardClick = (e,item) => {
		if(searchTextState) {
			setSearchTextState(null)
		}
		setHighLightFilter(true)
		setTableRefreshKey(new Date().getTime())
		setdeviceStatusFilterValue([{ value: item?.id, label: item?.text }]);
					let filterVar = [...filter];
					filterVar = filterVar.filter(i => i.key !== 'device_status');
					let locationFilters = [
						...filterVar,
						{ key: 'device_status', value: [item?.id] }
					];
					setFilter(locationFilters)

					getDevicesData({offset: 1, limit: 20, sortField: paginationState?.sortField, sortOrder: paginationState?.sortOrder}, '',{ filter : locationFilters ? JSON.stringify(locationFilters) : null});
	}
	
	
	
	const deviceDetails = useMemo(() => {
		if (Object.keys(deviceStatus).length == 0) {
			setIsLoading(true);
			setShouldHeaderLoader(true);
		} else {
			const getdetail = (id) => { return deviceStatus[id] };
			const getPercentage = (id) => { return deviceStatus?.totalCount > 0 && deviceStatus[id] ? (deviceStatus[id] * 100 / deviceStatus?.totalCount).toFixed(2) : 0 }
			const other = totalCount > 0 && deviceStatus ? totalCount - (deviceStatus['up'] + deviceStatus['down'] + deviceStatus['maint'] + deviceStatus['degraded']) : 0;
			const otherPercentage = deviceStatus?.totalCount > 0 && other > 0 ? ((other * 100) / deviceStatus?.totalCount).toFixed(2) : 0;
			const isEnabled = (val) => {
				return deviceStatusFilterValue?.length === 0 || deviceStatusFilterValue?.length >= 0 && deviceStatusFilterValue?.filter(i => i.value === val).length > 0 || deviceStatusFilterValue?.value === val
			}
			setIsLoading(false);
			setShouldHeaderLoader(false);
			return [
				{
					id: 'up',
					text: 'Up',
					count: getdetail('up') || 0,
					percentage: getPercentage('up') || 0,
					className: 'link__monitor__card__up',
					isEnabled: isEnabled('up'),
					disableClick: location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
					tooltipText: statusTooltipText['up']


				},
				{
					id: 'down',
					text: 'Down',
					count: getdetail('down') || 0,
					percentage: getPercentage('down') || 0,
					className: 'link__monitor__card__down',
					isEnabled: isEnabled('down'),
					disableClick: location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
					tooltipText: statusTooltipText['down']

				},
				{
					id: 'degraded',
					text: 'Degraded',
					count: getdetail('degraded') || 0,
					percentage: getPercentage('degraded') || 0,
					className: 'link__monitor__card__degraded',
					isEnabled: isEnabled('degraded'),
					disableClick: location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
					tooltipText: statusTooltipText['degraded']


				},
				{
					id: 'maint',
					text: 'Maint',
					count: getdetail('maint') || 0,
					percentage: getPercentage('maint') || 0,
					className: 'link__monitor__card__maintenance',
					isEnabled: user?.tenant?.name === 'Sangoma' && isEnabled('maint') ? true : false && isEnabled('maint'),
					disableClick: location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
					tooltipText: statusTooltipText['maint']

				},
				{
					id: 'others',
					text: 'Others',
					count: other > 0 ? other : 0,
					percentage: otherPercentage,
					className: 'link__monitor__card__na',
					isEnabled: isEnabled('others'),
					disableClick: location?.state?.deviceName !== undefined || location?.state?.deviceData !== undefined,
					tooltipText: statusTooltipText['N/A']
				},
			]
		}
	}, [user?.tenant, deviceStatus]);

	const filterConfigurations = useMemo(() => [
		{
			key: "statusFilter",
			isEnabled: true,
			placeholder: "State...",
			options: filterStatusListData,
			value: statusFilter,
			handler: handleStatusFilter,
			disableApply: (location?.state == null || location?.state?.service == '' || location?.state?.service ) && !isLoading && statusFilter.length > 0 ? false : true,
			defaultFilter: defaultStatusFilter,
		},
		{
			key: "serviceFilter",
			isEnabled: true,
			placeholder: "Service...",
			options: [...ServiceFilterList, { value: "switch", label: "switch" }],
			value: serviceFilter,
			handler: handleServiceFilter,
			disableApply: (location?.state == null || location?.state?.service == '' || location?.state?.service) && !isLoading && serviceFilter.length > 0 ? false : true,
		},
		{
			key: "tenantFilter",
			isEnabled: user?.tenant?.name === "Sangoma",
			placeholder: "Tenant...",
			options: tenantOptionsList,
			value: tenantFilter,
			handler: handleTenantFilter,
			disableApply: (location?.state == null || location?.state?.service == ''|| location?.state?.service) && !isLoading && tenantFilter.length > 0 ? false : true,
		},
		{
			key: "deviceStatusFilter",
			isEnabled: true,
			placeholder: "Device Status...",
			options: user?.tenant?.name !== "Sangoma"
			? StatusFilterList?.filter((item) => item.value !== "maint")
			: StatusFilterList,
			value: deviceStatusFilterValue,
			handler: handleDeviceStatusFilter,
			highlightFilter : highlightFilter,
			disableApply: (location?.state == null || location?.state?.service == ''|| location?.state?.service) && !isLoading && deviceStatusFilterValue.length > 0 ? false : true,
		},
		
	], [
		user?.tenant?.name,
		filterStatusListData,
		ServiceFilterList,
		tenantOptionsList,
		location?.state,
		isLoading,
		statusFilter,
		serviceFilter,
		deviceStatusFilterValue,
		tenantFilter,
	]);

	const onPageChange = (pagination) => {
		getDevicesData(pagination, null, null, true);
	}
	return (
		<MuiThemedBox className="main_inner_wrap">

			<Box className="justify-between">
				<Breadcrumbs text={`Devices (${totalCount ?  totalCount : 0})`} filterValue = {filterText} onRemoveFilter={() => setFilterText(null)}/>
			</Box>

			<Grid container className="flex_wrap justify-between">
				<Grid item md={3} className="filter_wrap">
					<SearchInputField debounceProp={user?.tenant?.id} handleClick={handleSearch} userKey={'specialChar'} key={location?.state} value={searchTextState} />
				</Grid>
				<Grid item md={9} className="flex flex_Wrap" gap={"9px"} >
					<Box sx={{display: 'flex', width:"100%", alignItems:"center", justifyContent: 'flex-end', }}>
						<FilterStatusAndService
							style={{ width: "86%", justifyContent: "right", marginRight: "16px" }}
							filterConfigurations={filterConfigurations}
							onApplyClick={onApplyFilter}
							totalFilterCount={user?.tenant?.name === "Sangoma" ? 4 : 3}
							// highlightFilter={highlightFilter}
							// fromDeviceScreen={true}
						/>

					<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
					{canAccess( 'create', 'devices') ? (
						<ButtonLayout
							text={`device`}
							buttonStyleType={"outlined"}
							icon={
								<FontAwesomeIcon
									icon="fa-solid fa-plus"
									className="iconPadding"
								/>
							}
							handleClick={handleClick}
						/>
					) : null}
					<GlobalExportButton
						enableFilteredDataExportButton={filter?.length > 0 || searchTextState?.length> 0 || location?.state?.deviceName || location?.state?.serviceName || location?.state?.siteId}						
						exportApiRequest={{
							tenantId: user.tenant?.name !== "Sangoma" ? user.tenant?.id : null,
							filetype: 'csv',
							dataOf: 'devices',
							deviceId:location?.state?.deviceName,
							service: location?.state?.serviceName,
							siteId: location?.state?.siteId,
							...getFilterRequest(null, {offset: 1, limit: totalCount},null)
						}}
						heading={`Devices`}
						text={"Are you sure you want to download devices list ?"}
						fileName={"devices_data"}

					/>
					</Box>
					</Box>



				</Grid>
			</Grid>
			<TableHeader tableHeaderCardDetails={deviceDetails} isLoading={isLoading && shouldHeaderLoader} md={ user?.tenant?.name === 'Sangoma' ? 2.4 : 3} lg={ user?.tenant?.name === 'Sangoma' ? 2.4 : 3} handleCardClick={handleCardClick}/>

			<Box>
				<TableComponent
					className={"device_table rdt_table_wrap hover_table table_with_header" }
					columns={tableColumns}
					rows={filteredData}
					key={tableRefreshKey}
					customStyles={customStyles}
					conditionalRowStyles={conditionalRowStyles}
					isLoading={isLoading}
					onRowClicked={/* location?.state?.deviceData ? handleRowClicked : */ handleSelectRow}
					onPageChange = {onPageChange}
					totalRows = {totalCount}
					defaultSortField = {' '}			

				/>
			</Box>
			{openActionMenu &&
				<ActionMenu
					openMenuList={openActionMenu}
					open={open}
					canDelete={editData?.custom_fields?.is_custom ? canAccess( 'delete', 'devices') :false}
					editData={editData}
					openEditForm={openEditForm}
					handleActionMenuClose={handleActionMenuClose}
					handleDelete={handleDeleteConfirm}
					navURL={"/nf-status/inventory/changelog"}
					type="inventory"
					primaryText="Devices"
				/>
			}

			<Suspense fallback={<div></div>}>
				{openDevice &&
					<FormPopup open={openDevice} size={"deviceModal "} className={user?.themePreference === 'dark' ? "site__modal form__modal__darkMode" : "stepper__modal"}>
						<CreateDevice isCreateDevice={true} isUpdateList={true} editData={editData} updateData={getDevicesData} handleClose={handleDeviceClose} />
					</FormPopup>}
			</Suspense>

			<Suspense fallback={<div></div>}>
				{openServicePopup &&
					<ExpandablePopup
						rowData={selectedRowData}
						handleClosePopup={handleClosePopup}
						open={openServicePopup}
					/>
				}
			</Suspense>

			<Suspense fallback={<div></div>}>
				{openDeleteConfimation && canAccess( 'delete', 'devices') &&
					<DeleteConfirmPopup
						open={openDeleteConfimation}
						close={handleDeleteConfirm}
						deleteUser={handleDelete}
						user={editData}
						isLoading = {isDeleteLoading}				
						heading={`Device : ${editData?.name} ?`}
						text={"Are you sure you want to delete this Device ?"}
					/>
				}
			</Suspense>
		</MuiThemedBox>
	);
}

export default DevicesList;
