import { Button } from '@mui/material'
import React from 'react'
import { MuiThemedCircularProgress } from './MuiThemedComponents'
import { BlockUI } from 'ns-react-block-ui'

const NetopsButton = ({ children, variant, showLoader, ...rest }) => {
  return (
    <BlockUI blocking={showLoader} loader={<MuiThemedCircularProgress />} >
      <Button
        variant={variant}
        sx={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "16px",
          letterSpacing: "1.25px",
          textTransform: "uppercase",
          height: '36px',
          padding: '0px 12px 0px 8px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          border: '0px',
          borderRadius:'4px',

          '&:hover': {
            border: '0px',
          },

          color: (theme) => {
            if (variant === 'outlined') {
              if (theme?.palette?.mode == 'dark') {
                return '#158FCF'
              } else {
                return '#244C91'
              }

            }
          }
        }} {...rest}>{children}</Button>

    </BlockUI>

  )
}

export default NetopsButton
